//////////////////////////////
// *** CAROUSEL STYLES  *** //
//////////////////////////////

@use 'abstracts/variables' as v;
@use 'abstracts/mixins' as m;
@use 'abstracts/mediaQueries' as mq;

.carousel {
  position: relative;
  z-index: 9998;

  .carousel-dots {
    bottom: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;

    .carousel-dot {
      background-color: v.$brand-bright-grey !important;
      border: 2px solid transparent !important;
      height: 0.66rem !important;
      width: 0.66rem !important;
      margin: 0.5rem 0.15rem !important;

      &.carousel-dot-active {
        background: v.$brand-black !important;
      }
    }
  }
  .carousel-arrows {
    position: absolute;
    width: 100%;
    top: calc(50% + 8px);

    @include mq.desktop {
      top: 50%;
    }

    .carousel-arrow {
      background-image: none !important;
      background-color: rgba(v.$white, 0.4) !important;
      cursor: pointer;

      @include mq.desktop {
        box-shadow: none !important;
        background-color: transparent !important;
        width: 60px !important;
        height: 60px !important;

        &:hover {
          &.carousel-arrow-prev::after,
          &.carousel-arrow-next::after {
            background-color: v.$brand-dark-grey !important;
          }
        }
      }

      &:hover {
        background-color: rgba(v.$white, 0.8) !important;
        &:after {
          background-color: v.$brand-dark-grey !important;
        }
      }

      &.carousel-arrow-prev {
        left: 12px !important;

        &:after {
          position: absolute;
          content: '';
          top: 15%;
          right: 17%;
          width: 72%;
          height: 72%;
          @include m.colored-bg-svg(v.$brand-black, 'basic/arrow_left_first_single.svg');
        }

        @include mq.desktop {
          left: -60px !important;
          &:after {
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            @include m.colored-bg-svg(v.$brand-black, 'basic/slider_arrow_left.svg');
          }
        }
      }

      &.carousel-arrow-next {
        right: 12px !important;

        &:after {
          position: absolute;
          content: '';
          top: 15%;
          left: 17%;
          width: 72%;
          height: 72%;
          @include m.colored-bg-svg(v.$brand-black, 'basic/arrow_right_first_single.svg');
        }

        @include mq.desktop {
          right: -60px !important;
          &:after {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include m.colored-bg-svg(v.$brand-black, 'basic/slider_arrow_right.svg');
          }
        }
      }

      &.carousel-arrow-disabled {
        display: none;
        opacity: 0;
      }
    }
  }
}

.keenCarousel {
  position: relative;
  border-radius: v.$teaser-border-radius;

  &.halfSized {
    max-width: 800px;
    margin: 0 auto;
  }

  .keen-slider {
    z-index: 10;

    &.small {
      .imageItem {
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .imageItem {
    border-radius: v.$teaser-border-radius;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;

    > img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: v.$teaser-border-radius;
    }
  }

  .navArrow {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    background-color: rgba(v.$white, 0.4);
    cursor: pointer;
    z-index: 20;
    border-radius: 50%;

    @include mq.desktop {
      background-color: transparent;
      margin-top: -70px;
      border-radius: 0%;
    }

    .arrowIcon {
      width: 30px;
      height: 30px;

      @include mq.desktop {
        width: 60px;
        height: 60px;
      }
    }

    &.left {
      left: 12px;
      right: auto;
      padding: 5px 8px 5px 2px;

      @include mq.desktop {
        left: 0px;
        margin-left: -65px;
        padding: 0px;
      }

      .arrowIcon {
        @include m.colored-bg-svg($color: v.$brand-black, $icon: 'basic/arrow_left_first_single.svg');

        @include mq.desktop {
          @include m.colored-bg-svg($color: v.$brand-dark-grey, $icon: 'basic/slider_arrow_left.svg');
        }
      }
    }

    &.right {
      right: 12px;
      left: auto;
      padding: 5px 2px 5px 8px;

      @include mq.desktop {
        right: 0px;
        margin-right: -65px;
        padding: 0px;
      }

      .arrowIcon {
        @include m.colored-bg-svg($color: v.$brand-black, $icon: 'basic/arrow_right_first_single.svg');

        @include mq.desktop {
          @include m.colored-bg-svg($color: v.$brand-dark-grey, $icon: 'basic/slider_arrow_right.svg');
        }
      }
    }
    &.disabled {
      display: none;
    }
  }

  .sliderNavigation.dots {
    display: flex;
    height: 50px;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;

    .navDot {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 0 3px;
      cursor: pointer;
      background-color: v.$brand-mid-grey;

      &.active {
        background-color: v.$brand-dark-grey;
      }
    }
  }

  &.expandable {
    .imageItem {
      cursor: pointer;

      .imageOverlay {
        width: 100%;
        height: 100%;
        border-radius: v.$teaser-border-radius;
        position: absolute;
        z-index: 30;
        transition: all 500ms ease;

        .icon {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          height: 2rem;
          width: 2rem;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          z-index: 50;

          &.icon-enlarge {
            transition: all 200ms ease;

            @include m.colored-bg-svg($color: v.$brand-dark-grey, $icon: 'basic/enlarge.svg');
          }
        }

        .ctaButton {
          pointer-events: none;
          visibility: hidden;
          opacity: 0;
          transition: all 500ms ease;
          position: absolute;
          top: 50%;
          left: 50%;
          width: auto;
          padding: 0.25rem 1rem;
          transform: translateX(-50%) translateY(25%);
          border: 2px solid v.$white;
          background: rgba(0, 0, 0, 0.2);
          border-radius: v.$button-border-radius;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 50;

          p {
            font-family: v.$bold-font;
            color: v.$white;
          }
        }
      }

      > img {
        transition: all 500ms ease;
      }
    }

    .innerWrap {
      .imageItem {
        overflow: hidden;
      }

      &:hover {
        .imageItem {
          @include mq.tablet {
            .imageOverlay {
              background: v.$teaser-image-overlay;

              .ctaButton {
                visibility: visible;
                opacity: 1;
                transform: translateX(-50%) translateY(-50%);
              }

              .icon.icon-enlarge {
                background-color: v.$white;
              }
            }

            > img {
              transform: scale(1.02);
            }
          }
        }
      }
    }
  }
}

.myProjectsCarousel {
  .carousel-cells {
    padding-left: 1rem !important;
  }
  .carousel-cell {
    overflow: visible;
    padding-right: 1rem;
    padding-bottom: 5rem;
  }
}

.genericImageCarousel {
  min-height: min(65vw, 960px);
  .carousel-dots {
    bottom: -25px !important;
    @include mq.tablet {
      bottom: -30px !important;
      @include mq.desktop {
        bottom: -35px !important;
        @include mq.largeDesktop {
          bottom: -45px !important;
        }
      }
    }
  }
  &.halfSized {
    min-height: min(64vw, 535px);
    .carousel-dots {
      bottom: -30px !important;
      @include mq.tablet {
        bottom: -40px !important;
      }
    }
    .carousel-arrows .carousel-arrow {
      &.carousel-arrow-prev {
        @include mq.desktop {
          left: -60px !important;
          background-color: transparent !important;
        }
      }
      &.carousel-arrow-next {
        @include mq.desktop {
          right: -60px !important;
          background-color: transparent !important;
        }
      }
    }
  }
}

.customTeaserCarousel {
  .carousel-arrow {
    top: calc(50% - 10px) !important;
  }
  &.small {
    min-height: 285px;
    @include mq.tablet {
      min-height: 295px;
    }
    .carousel-arrow {
      top: -65px !important;
    }
  }

  &.medium {
    min-height: 343px;
    @include mq.tablet {
      min-height: 388px;
    }
  }
  &.large {
    min-height: 381px;
    @include mq.tablet {
      min-height: 438px;
    }
  }
  .carousel-dots {
    bottom: -40px !important;
  }
}

.conceptNavigationCarousel {
  .carousel-container {
    overflow: visible !important;
    display: flex;
    justify-content: center;

    .carousel-cells {
      padding: 0;
      @include mq.tablet {
        padding: 0 25px;
      }
    }
  }
  .carousel-arrows .carousel-arrow {
    top: unset !important;
    bottom: 0.875rem;
    opacity: 0.8;
    height: 100% !important;
    width: 50px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    background-color: v.$brand-pale-grey !important;

    top: unset !important;
    bottom: 0.875rem;

    &:hover {
      opacity: 0.9;
      &:after {
        background-color: v.$brand-black !important;
      }
    }

    &.carousel-arrow-prev {
      left: 0 !important;
      background-image: none !important;

      &:after {
        position: absolute;
        content: '';
        top: 19%;
        right: 24%;
        width: 70%;
        height: 70%;
        @include m.colored-bg-svg(v.$brand-black, 'basic/slider_arrow_left.svg');
      }
    }
    &.carousel-arrow-next {
      right: 0 !important;
      background-image: none !important;

      &:after {
        position: absolute;
        content: '';
        top: 19%;
        left: 23%;
        width: 70%;
        height: 70%;
        @include m.colored-bg-svg(v.$brand-black, 'basic/slider_arrow_right.svg');
      }
    }
    &.carousel-arrow-disabled {
      display: none;
    }
  }
}

.contentNavigationCarousel {
  .carousel-arrows {
    top: 90px !important;
  }
}
.catalogueCarousel {
  .carousel-arrows {
    top: 70px !important;
  }
}
