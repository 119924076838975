/////////////////////////////////////////////////
// *** POP UPs / MODALS / DIALOGS / TOASTS *** //
/////////////////////////////////////////////////

@use 'abstracts/variables' as v;
@use 'abstracts/mixins' as m;
@use 'abstracts/mediaQueries' as mq;

/** MAT DIALOG */
.doehlerMatDialog {
  width: 100%;
  height: 100%;
  max-width: 100vw !important;

  @include mq.tablet {
    height: auto;
    max-width: v.$bp-small !important;
  }

  &.small {
    @include mq.tablet {
      max-width: 560px !important;
    }
  }

  &.medium {
    @include mq.tablet {
      max-width: v.$bp-regular !important;
    }
  }

  &.large {
    @include mq.tablet {
      max-width: v.$bp-regular !important;

      @include mq.largeDesktop {
        max-width: v.$bp-large !important;
      }
    }
  }

  &.full {
    height: 100%;
    max-width: 100% !important;
    background-color: v.$brand-standard-blue;
    padding-top: const(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);

    .mat-dialog-container {
      background-color: v.$brand-dark-grey;
      border-radius: v.$teaser-border-radius v.$teaser-border-radius 0 0;
      padding: 0;
      max-height: unset;
    }
  }

  &.fullScreen {
    height: 100%;
    max-width: 100% !important;

    .mat-dialog-container {
      max-height: 100%;
      padding-top: 0;
      padding-left: 0;
      padding-bottom: 0;
      padding-right: 0;
      border-radius: 0;
    }
    .close-button {
      position: unset;
      top: unset;
      left: unset;
    }

    .pageTeaser .teaserInner .headlineArea {
      margin-left: 2.5rem !important;
    }
  }

  &.wide {
    @include mq.tablet {
      max-width: min(v.$bp-large, calc(100vw - 2rem)) !important;
    }
  }

  .close-button {
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 50%;
    background: v.$brand-black;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;

    &:hover {
      background: v.$brand-dark-grey;
    }

    .icon.cross {
      width: 1.5rem;
      height: 1.5rem;
      top: 0;
      left: 0;
      @include m.colored-bg-svg($color: v.$white, $icon: 'basic/close_single.svg');
    }
  }

  .mat-dialog-container {
    width: 100vw;
    background-color: v.$white;
    overflow-y: unset;
    position: relative;
    padding: 1.5rem 1rem 1.25rem 1rem;
    padding-top: calc(24px + var(--safe-area-inset-top));
    padding-bottom: calc(20px + var(--safe-area-inset-bottom));
    border-radius: 0;
    max-height: 100vh;
    height: 100%;

    .isLoading {
      max-height: calc(100vh - 3rem);
      overflow: hidden;

      @include mq.tablet {
        max-height: calc(90vh - 5rem);
      }
    }

    > * {
      @include mq.tablet {
        width: 100%;
      }
    }

    > *:first-child > div:first-child {
      height: 100%;

      @include mq.tablet {
        height: auto;
      }
    }

    > *:nth-last-child(2) {
      margin-bottom: auto;
    }

    > * > * {
      position: relative;
    }

    @include mq.tablet {
      max-height: 90vh;
      border-radius: v.$card-border-radius;
      padding: 2.5rem 2.5rem 2rem 2.5rem;
      padding-top: calc(40px + var(--safe-area-inset-top));
      padding-bottom: calc(32px + var(--safe-area-inset-bottom));
    }

    .form-content {
      height: 100%;
      padding: 0;
      position: relative;
      display: flex;
      flex-direction: column;

      @include mq.tablet {
        height: auto;
      }

      > *:not(.form-header):not(.modal-header):not(app-loading-content):not(.close-button) {
        height: 100%;

        @include mq.tablet {
          height: auto;
        }
      }

      .form-header,
      .modal-header {
        position: relative;

        h1,
        h2 {
          padding-bottom: 1rem;
          padding-right: 1.75rem;

          &.doublePadding {
            padding-bottom: 2rem;
          }
        }

        p {
          padding-bottom: 0.5rem;
          &.copy1 {
            padding-top: 0.5rem;
            padding-bottom: 1rem;
          }
        }
      }
    }

    .specificationModalInner {
      width: 100%;
      height: 100%;
      @include m.flex-box($direction: column, $justify-content: flex-start, $align-items: flex-start, $wrap: nowrap);

      .modalContent {
        flex-grow: 1;
        width: 100%;

        .topPadding {
          padding-top: 1rem;
        }

        h1,
        h2 {
          padding-bottom: 1rem;
          padding-right: 2rem;

          &.doublePadding {
            padding-bottom: 2rem;
          }
        }

        h3 {
          padding-bottom: 0.75rem;
        }
      }
    }

    .reload-page-modal-inner {
      h1 {
        padding-bottom: 1.5rem;
      }
    }

    /** Special behaviour when button container is placed within a jw modal */
    .button-container:not(.noMobileStretch) {
      flex-wrap: nowrap;
      flex-direction: column-reverse;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-top: 1rem;

      button {
        width: calc(100% - 1rem);
        min-height: 3rem;
        @include mq.tablet {
          width: auto;
        }
      }

      @include mq.tablet {
        border: none;
        flex-direction: row;
        margin: -0.5rem;
        padding: 0.5rem 0 0 0;
        width: calc(100% + 1rem) !important;
      }

      &.addTopPadding {
        @include mq.tablet {
          padding: 2rem 0 0 0;
        }
      }

      &.morePadding {
        @include mq.tablet {
          padding: 3.5rem 0 0 0;
        }
      }

      &.noPadding {
        padding: 0;
        width: calc(100% + 1rem);
      }

      &.withSpacing {
        padding-top: 0.5rem;
        margin-top: auto;

        @include mq.tablet {
          flex-direction: row;
          margin: 0 -0.5rem;
          padding: 0;
          width: calc(100% + 1rem) !important;
        }
      }
    }

    .button-container:not(.centered) {
      > button:nth-last-child(2) {
        @include mq.tablet {
          margin-left: auto;
        }
      }
    }
  }

  &.disablePadding {
    .close-button {
      position: fixed;
      top: 1.5rem;
      right: 1rem;
    }
    .mat-dialog-container {
      padding: 0;
    }
    .button-container {
      height: 5.25rem;
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 0.3rem 1rem 0 1rem;
      margin: 0;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      background-color: v.$white;
      box-shadow: 0px 20px 75px rgba(0, 0, 0, 0.1);

      button {
        margin: 0 1.25rem 0 0;
        white-space: nowrap;

        &.text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  &.dynamicIframeModal {
    .mat-dialog-container {
      .ng-star-inserted {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  &.foodCertificatesFilter {
    background-color: v.$white;
  }

  /** Track */
  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: v.$white;
  }

  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 10px;
    background-color: v.$white;
  }

  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: v.$brand-bright-grey;
    border: 2px solid v.$white;
  }

  /** Handle on hover */
  &::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:hover {
    background: v.$brand-bright-grey;
  }

  &.user-admin-confirmation-dialog {
    height: auto;
    align-self: end;

    @include mq.desktop {
      align-self: unset;
    }

    .mat-dialog-container {
      border-radius: v.$teaser-border-radius v.$teaser-border-radius 0 0;
      padding: 2rem;

      @include mq.desktop {
        border-radius: v.$teaser-border-radius;
      }
    }
  }
}

/** TOAST MESSAGES */
.toast-wrapper {
  @include m.flex-box($direction: row, $align-items: center, $wrap: nowrap);

  .toast-message {
    @include m.flex-box($direction: row, $align-items: center, $wrap: nowrap);

    .toast-icon {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;

      @include mq.tablet {
        width: 2.5rem;
        min-width: 2.5rem;
        height: 2.5rem;
      }

      &.icon-info {
        @include m.colored-bg-svg($color: v.$brand-standard-blue, $icon: 'basic/info_circle.svg');
      }

      &.icon-success {
        @include m.colored-bg-svg($color: v.$signal-green, $icon: 'basic/check_circle_plain.svg');
      }

      &.icon-error {
        @include m.colored-bg-svg($color: v.$signal-magenta, $icon: 'basic/exclamation_mark_circle_plain.svg');
      }
    }

    .toast-label {
      @include m.word-wrap;

      color: v.$brand-standard-blue;
      padding: 0 0.75rem;
    }
  }

  .toast-close {
    float: right;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;

    @include m.colored-bg-svg($color: v.$brand-bright-grey, $icon: 'basic/close_single.svg');

    &:hover {
      background-color: v.$secondary-highlight-blue;
    }
  }
}

.mat-snack-bar-container {
  background-color: v.$white;
  min-width: 550px;
  padding: 1rem;
  border-radius: 0.25rem;

  @include mq.tablet {
    max-width: 650px;
    padding: 1.25rem 1rem;
  }
}

.cdk-overlay-container {
  z-index: 999999 !important;
}

.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing {
  background: v.$dark-background;
}
/** Drawer, Sidenav, FlyIn */
.mat-drawer-container {
  background: transparent !important;
}

.mat-drawer-container,
.mat-sidenav-container {
  position: initial !important;
  height: auto;

  .mat-drawer-backdrop {
    z-index: 99999 !important;
  }

  .mat-drawer,
  .mat-sidenav {
    z-index: 100000 !important;

    .drawerInnerContainer {
      height: 100%;
      overflow-x: hidden;
      min-width: 100vw;

      @include mq.tablet {
        min-width: 480px;
        max-width: min(66vw, 900px);
        @include mq.largeDesktop {
          max-width: min(66vw, 1150px);
        }
      }

      .closeDrawerIcon {
        position: absolute;
        height: 2.5rem;
        width: 2.5rem;
        top: calc(1rem + var(--safe-area-inset-top));
        right: 1.1rem;
        padding: 0;
        margin: 0;
        background: rgba(v.$white, 0.6);
        border-radius: 50%;
        border: none;
        outline: none;
        z-index: 98;
        background-color: v.$brand-pale-grey;

        @include mq.tablet {
          right: 1.5rem;
        }

        &:hover {
          background-color: v.$special-pale-blue;
          .close-icon {
            background-color: v.$secondary-highlight-blue;
          }
        }

        .close-icon {
          z-index: 99;
          position: absolute;
          top: 0.075rem;
          right: 0.075rem;
          cursor: pointer;
          width: 2.25rem;
          height: 2.25rem;

          @include m.colored-bg-svg($color: v.$secondary-water-blue, $icon: 'basic/close_single.svg');
        }
      }
    }
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.user-detail-dialog {
  animation: 300ms ease-out 0s 1 slideInFromBottom;
}
