@use 'abstracts/variables' as v;
@use 'abstracts/mixins' as m;
@use 'abstracts/mediaQueries' as mq;

// otc/empties component
.mat-datepicker-popup {
  height: auto;
}

.mat-tab-header-pagination-chevron {
  color: v.$secondary-highlight-blue;
}

.mat-content {
  align-items: center;
}

.mat-menu {
  background-color: v.$white;
}

.mat-menu-content {
  background-color: v.$white;
  a {
    @include m.flex-box($direction: row, $align-items: center, $justify-content: flex-start);
    padding: 0.5rem 2rem 0.5rem 1rem;
    cursor: pointer;
    text-decoration: none;
    .copy1 {
      color: v.$secondary-highlight-blue;
      font-family: v.$bold-font;
    }

    .icon {
      margin-right: 0.75rem;
    }

    &:hover {
      background-color: v.$special-pale-blue;
      .copy1 {
        color: darken(v.$secondary-highlight-blue, 10%);
      }
      .icon {
        background-color: darken(v.$secondary-highlight-blue, 10%);
      }
    }
  }
}

#video-player-modal .videoWrapper {
  padding: 3rem 0 0 0;
  width: 100%;
  height: 100%;

  @include mq.tablet {
    padding: 2rem;
  }

  iframe {
    width: 100%;
    height: 100%;
    max-height: 360px;
    box-shadow: v.$basic-box-shadow;

    @include mq.tablet {
      height: 45vw;
      max-height: 600px;
    }
  }
}

.dynamicAccordionWrapper h2 {
  padding-bottom: 0.5rem;
}
