@use 'abstracts/variables' as v;
@use 'abstracts/mediaQueries' as mq;
@use 'abstracts/mixins' as m;

.mat-tab-group {
  .mat-tab-header-pagination {
    background: transparent;
    box-shadow: none;
    padding: 0;
    margin: 0;

    &:hover {
      background: transparent;
      .mat-tab-header-pagination-chevron {
        border-color: v.$brand-dark-grey;
      }
    }

    &.mat-tab-header-pagination-disabled {
      .mat-tab-header-pagination-chevron {
        border-color: v.$brand-light-grey;
      }
    }

    .mat-tab-header-pagination-chevron {
      border-color: v.$brand-mid-grey;
    }
  }
}
