////////////////////////
// *** ANIMATIONS *** //
////////////////////////

/* Scrolling background animation */
@keyframes scrollBackground {
  from {
    background-position: 0 100%;
  }
  to {
    background-position: 0 0%;
  }
}

.scrollBackground {
  animation-name: scrollBackground;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 30s;
}

/* Simple fade-in animation */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fade-in;
  animation-iteration-count: 1;
  animation-duration: 600ms;
}

.slow-fade-in {
  animation-name: fade-in;
  animation-iteration-count: 1;
  animation-duration: 1200ms;
}

/* Simple fade-out animation */
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fade-out;
  animation-iteration-count: 1;
  animation-duration: 600ms;
}

/* Simple fly-in animation (MORE MENU) */
@keyframes fly-in {
  0% {
    left: -40rem;
  }
  100% {
    left: 0;
  }
}

.fly-in {
  animation-name: fly-in;
  animation-iteration-count: 1;
  animation-duration: 360ms;
}

/* Simple fly-out animation (MORE MENU) */
@keyframes fly-out {
  0% {
    left: 0;
  }
  100% {
    left: -40rem;
  }
}

.fly-out {
  animation-name: fly-out;
  animation-iteration-count: 1;
  animation-duration: 360ms;
}

/* Pulse animation */
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
