////////////////////
// *** HEADER *** //
////////////////////

@use 'abstracts/variables' as *;
@use 'abstracts/mixins' as *;
@use 'abstracts/mediaQueries' as *;
@use 'layout/grid' as *;

.mat-autocomplete-panel {
  position: absolute;
  top: -19px;

  &.search-dropdown {
    background: transparent !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: 0px 5px 4px -1px rgba(255, 255, 255, 0.14), -1px 5px 5px 0px rgba(255, 255, 255, 0.1), 0px 10px 10px 0px rgba(255, 255, 255, 0.08) !important;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;

    &.flat-corner {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      top: 0;
      box-shadow: 0px 5px 4px -1px rgba(255, 255, 255, 0.14), -1px 5px 5px 0px rgba(255, 255, 255, 0.1), 0px 5px 10px 0px rgba(255, 255, 255, 0.08) !important;
    }

    &.hide {
      display: none;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .spacing {
    height: 20px;
    background-color: transparent;
  }

  .matOptionsWrapper {
    max-height: 220px;
    overflow-y: auto;
    background: $brand-standard-blue;

    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: $white;
    }

    &::-webkit-scrollbar,
    ::-webkit-scrollbar {
      width: 10px;
      background-color: $white;
    }

    &::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $brand-bright-grey;
      border: 2px solid $white;
    }

    .mat-option {
      background-color: $brand-standard-blue;
      color: $white;
      animation: fadeIn 0.5s ease;
    }
    .mat-option:hover:not(.mat-option-disabled),
    .mat-option:focus:not(.mat-option-disabled) {
      background: rgba($interaction-hover, 0.08);
    }
  }
}
header {
  .firstHeaderRow,
  .mobileNavigation {
    .search-ui {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .search-container {
        width: 100%;
        margin: 0 1rem;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @include tablet {
          width: 84%;
        }

        mat-form-field.mat-form-field {
          width: 100%;
          border: 1px solid $white;
          border-radius: 30px;
          height: 40px;

          .mat-form-field-wrapper {
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;

            .mat-form-field-flex {
              width: 100%;
              height: 100%;
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;

              .mat-form-field-infix {
                width: 100%;
                height: 100%;
                border: none;
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;

                input.mat-input-element {
                  color: $white !important;
                  caret-color: $white !important;
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
                }
                .mat-form-field-label-wrapper {
                  top: 0;
                  padding: 0;
                }
              }
            }
            .mat-form-field-subscript-wrapper,
            .mat-form-field-underline {
              display: none;
            }
          }
        }
        .mat-form-field-prefix {
          margin-left: 0.5rem;
          margin-right: 0.25rem;
          #submitIcon {
            position: relative;
            cursor: pointer;
            width: 2rem;
            height: 2rem;
            @include colored-bg-svg($color: $white, $icon: 'basic/search.svg', $size: 80%);
          }
        }

        .mat-form-field-suffix {
          margin-right: 0.5rem;
          #clearIcon {
            position: relative;
            cursor: pointer;
            width: 2rem;
            height: 30px;
            @include colored-bg-svg($color: $white, $icon: 'basic/close_single.svg', $size: 80%);
          }
        }

        .layer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.75);
          border: 1px solid $grey;
          border-radius: 22px;
          align-items: center;
          justify-content: center;
          display: none;
          font-family: $plain-font;
          cursor: not-allowed;
        }

        ::-webkit-input-placeholder {
          opacity: 0.75;
          color: $white;
        }

        :-ms-input-placeholder {
          opacity: 0.75;
          color: $white;
        }

        ::placeholder {
          opacity: 0.75;
          color: $white;
        }
      }
    }
  }
}
