//////////////////////////////////////
// *** FLOCKLER SPECIFIC STYLES *** //
//////////////////////////////////////

@use 'abstracts/variables' as v;

/* INTEGRATION FROM SOCIAL MEDIA NEWS VIA FLOCKLER - CUSTOM STYLING */
.socialMediaNewsContainer .flockler-grid-item__profile__name {
  color: v.$brand-standard-blue;
  font-size: v.$font-size;
}

.socialMediaNewsContainer .flockler-grid-item__profile__username {
  color: v.$brand-dark-grey;
}
