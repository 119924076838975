///////////////////////////////
// *** ACCORDION STYLES  *** //
///////////////////////////////

@use 'abstracts/variables' as v;
@use 'abstracts/mediaQueries' as mq;
@use 'abstracts/mixins' as m;

.dynamicAccordionWrapper {
  h3 {
    padding-bottom: 0.75rem;
  }
}
/* Applicable only for FAQ Accordion - at the moment */
.faqAccordion,
.customAccordion {
  .mat-expansion-panel {
    box-shadow: none !important;
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: 1px solid v.$brand-bright-grey;

    &.mat-expanded {
      margin: 0;
      height: auto;
    }

    .mat-expansion-panel-header {
      padding-left: 0.25rem !important;
      padding-right: 0 !important;
    }

    .itemHeader {
      box-shadow: none;
      padding: 0.75rem 0.25rem;
      height: auto;

      &.open {
        border-bottom: none;

        .mat-expansion-panel-header-title .title p {
          font-family: v.$bold-font !important;
        }
      }

      &:hover {
        background-color: transparent !important;
        .mat-expansion-panel-header-title .title p {
          color: v.$interaction-hover;
        }
        .toggleIcon {
          background-color: v.$interaction-hover;
        }
      }

      .mat-expansion-panel-header-title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .title {
          @include mq.tablet {
            padding-right: 1rem;
          }
          p {
            padding: 0;
            margin: 0;
          }
        }
      }

      .toggleIcon {
        width: 1.5rem;
        height: 1.5rem;
        min-width: 1.5rem;
        background-color: v.$brand-standard-blue;
        mask-image: url(/shared-assets/icons/basic/arrow_down_first_single.svg);
        mask-size: 80%;
        mask-repeat: no-repeat;
        mask-position: center;
        transition: transform 0.4s ease-in-out;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 0.25rem 0.25rem 1.5rem 0.25rem !important;

      .detailsArea {
        display: flex;
        flex-wrap: wrap;

        @include mq.tablet {
          flex-wrap: nowrap;
        }

        .imageArea {
          flex-basis: 100%;
          width: 100%;
          margin: 0;

          @include mq.tablet {
            flex-basis: 340px;
            width: 340px;
            margin-right: 2rem;
          }

          img {
            display: block;
            width: 100%;
            height: auto;
            max-width: 340px;
            min-width: 264px;
            margin: 0.5rem auto;
          }
        }

        .textArea {
          width: 100%;
          min-width: 264px;

          .button-container {
            padding-top: 1rem;
          }
        }
      }
    }
  }
}

.customAccordion {
  .mat-expansion-panel {
    .itemHeader {
      padding: 1rem 0.25rem;
      @include mq.tablet {
        padding: 1.25rem 0.25rem;
      }
    }
    &:first-child {
      border-top: 1px solid v.$brand-bright-grey;
    }
  }
}
