//////////////////////////////
// *** GLOBAL VARIABLES *** //
//////////////////////////////

$bp-tiny: 20rem; // 320px
$bp-mobile: 23.4375rem; // 375px
$bp-small: 48rem; // 768px
$bp-regular: 64rem; // 1024px
$bp-large: 90rem; // 1440px
$bp-very-large: 100rem; // 1600px
$bp-widescreen: 128rem; // 2048px

$smallMobileBreakpoint: '(min-width: #{$bp-tiny})';
$mobileBreakpoint: '(min-width: #{$bp-mobile})';
$tabletBreakpoint: '(min-width: #{$bp-small})';
$desktopBreakpoint: '(min-width: #{$bp-regular})';
$largeDesktopBreakpoint: '(min-width: #{$bp-large})';
$contentBoxBreakpoint: '(min-width: calc(#{$bp-large} + 7.5rem))';
$largeContentBoxBreakpoint: '(min-width: calc(#{$bp-large} + 6rem))';
$veryLargeDesktopBreakpoint: '(min-width: #{$bp-very-large})';

/////////////////////////////////////
// *** Font Specific VARIABLES *** //
/////////////////////////////////////

@font-face {
  font-family: TheSans;
  src: url('/shared-assets/fonts/TheSansPlain.eot') format('eot'), url('/shared-assets/fonts/TheSansPlain.woff') format('woff'),
    url('/shared-assets/fonts/TheSansPlain.woff2') format('woff2'), url('/shared-assets/fonts/TheSansPlain.ttf') format('truetype');
}

@font-face {
  font-family: TheSansBold;
  src: url('/shared-assets/fonts/TheSansBold.eot') format('eot'), url('/shared-assets/fonts/TheSansBold.woff') format('woff'),
    url('/shared-assets/fonts/TheSansBold.woff2') format('woff2'), url('/shared-assets/fonts/TheSansBold.ttf') format('truetype');
}

@font-face {
  font-family: TheSansLight;
  src: url('/shared-assets/fonts/TheSansLight.eot') format('eot'), url('/shared-assets/fonts/TheSansLight.woff') format('woff'),
    url('/shared-assets/fonts/TheSansLight.woff2') format('woff2'), url('/shared-assets/fonts/TheSansLight.ttf') format('truetype');
}

$plain-font: 'TheSans', Arial, sans-serif;
$bold-font: 'TheSansBold', Arial, sans-serif;
$light-font: 'TheSansLight', Arial, sans-serif;

/* NEW FONT SIZES */
$font-size-xxl: 5rem; // 80px
$font-size-xxl2: 4.5rem; // 72px
$font-size-xl: 3rem; // 48px
$font-size-l: 2rem; // 32px
$font-size-l2: 1.75rem; // 28px
$font-size-m: 1.5rem; // 24px
$font-size-m2: 1.375rem; // 22px
$font-size-s: 1.25rem; // 20px
$font-size-xs: 1.125rem; // 18px
$font-size-xxs: 1rem; // 16px
$font-size-xxs2: 0.875rem; // 14px
$font-size-xxs3: 0.75rem; // 12px

$line-height-special: 100%;
$line-height-headlines: 130%;
$line-height-paragraphs: 150%;

$font-size: $font-size-xxs;
$line-height: $line-height-paragraphs;

/* BASIC DISTANCES / SPACINGS */
$xxxxs: 4px;
$xxxs: 8px;
$xxs: 12px;
$xs: 16px;
$s: 24px;
$m: 32px;
$m1: 40px;
$m2: 48px;
$m3: 56px;
$l: 64px;
$xl: 72px;
$xxl: 80px;
$xxxl: 96px;

//////////////////////////////////////
// *** Color Specific VARIABLES *** //
//////////////////////////////////////

$white: #ffffff;
$grey: #666666;
$black: #000000;

/* MAIN BRAND COLOURS */
$brand-bright-blue: #09478d;
$brand-mid-blue: #003466;
$brand-standard-blue: #00233e;
$brand-dark-blue: #015cb9;
$brand-black: #1d1d1f;
$brand-dark-grey: #4a4c50;
$brand-darker-grey: #272833;
$brand-mid-grey: #9598a1;
$brand-bright-grey: #c8cbd4;
$brand-light-grey: #e3e5eb;
$brand-lighter-grey: #e7e7ed;
$brand-pale-grey: #f9fafc;
$brand-paler-grey: #f7f7f7;
/* before instant change:  
$brand-pale-grey: #f5f5f5;
*/

/* SECONDARY COLOURS*/
$secondary-highlight-blue: #0074de;
$secondary-water-blue: #457ddb;
$secondary-azure-blue: #2997ff;
$secondary-light-blue: #b1d6ff;
$secondary-turquoise-blue: #007fb2;
$secondary-caribbean-blue: #4de1f0;

/* INTERACTION COLOURS - light background */
$interaction-default: $secondary-highlight-blue;
$interaction-active: $secondary-highlight-blue;
$interaction-hover: #007ae7;
$interaction-disabled: $brand-bright-grey;
$interaction-disabled-bg: #f5f5f5;

/* INTERACTION SECONDARY COLOURS - light background */
$interaction-secondary-default: #0068c6;
$interaction-secondary-disabled-border: #f5f5f5;

/* INTERACTION COLOURS - dark background */
$interaction-dark-default: $secondary-highlight-blue;
$interaction-dark-active: $secondary-highlight-blue;
$interaction-dark-hover: #007ae7;
$interaction-dark-disabled: $brand-bright-grey;
$interaction-dark-disabled-bg: #f5f5f5;

/* ICON COLORS */
$primary-icon: $secondary-highlight-blue;
$secondary-icon: $brand-dark-grey;
$disabled-icon: $brand-bright-grey;

/* SIGNAL COLOURS | SYSTEM UI */
$signal-yellow: #ffdf27;
$signal-magenta: #ff256f;
$signal-magenta-background: rgba(253, 45, 132, 0.09);
$signal-green: #79e884;

/* ASSIGNMENT OF CHART COLOURS */
$chart-color-1: $brand-standard-blue;
$chart-color-2: $brand-mid-blue;
$chart-color-3: $brand-bright-blue;
$chart-color-4: $secondary-highlight-blue;
$chart-color-5: $secondary-azure-blue;
$chart-color-6: $secondary-water-blue;
$chart-color-7: $brand-bright-grey;
$chart-color-8: $brand-mid-grey;
$chart-color-9: $brand-dark-grey;
$chart-color-10: $brand-black;
/* ------------------------------------------------------------------- */

/* SPECIAL COLOURS - not part of the palette */
$special-pale-blue: rgba($brand-standard-blue, 0.06);

/* SPECTRUM COLOURS - usable for gradients */
$pink: #e94d8b;
$pink-light: #f684bf;
$fuchsia: #9e1e71;
$fuchsia-light: #cd40aa;
$violet: #5c2a6c;
$violet-light: #9554a5;
$purple: #42358c;
$purple-light: #7765c0;
$lavender: #5567ae;
$lavender-light: #8ea0d7;
$pale-blue: #a5c2e7;
$pale-blue-light: #d1e3f5;
$bright-blue: #83d0f5;
$bright-blue-light: #b9eafb;
$cyan: #52bcc2;
$cyan-light: #8adfe3;
$pale-green: #81c288;
$pale-green-light: #b7e3bd;
$green: #6cb010;
$green-light: #a5d825;
$bright-yellow: #fbe842;
$bright-yellow-light: #fdf577;
$yellow: #fbce43;
$yellow-light: #fde978;
$faded-yellow: #f8b03f;
$faded-yellow-light: #fcd873;
$pale-orange: #ef7e45;
$pale-orange-light: #f8b57b;
$orange: #e64c2d;
$orange-light: #f48359;
$red: #c21c33;
$red-light: #e33c62;

/* BACKGROUND COLOURS */
$bright-background: $brand-pale-grey;
$dark-background: rgba($brand-black, 0.5);

/////////////////////////////////////////
// *** ADDITIONAL COMMON VARIABLES *** //
/////////////////////////////////////////

/* BOX OVERLAYS */
$teaser-image-overlay: rgba($brand-black, 0.3);
$teaser-image-overlay-gradient: linear-gradient(270deg, rgba($brand-black, 0), rgba($brand-black, 1));
$teaser-image-overlay-top-gradient: linear-gradient(360deg, rgba($brand-black, 0), rgba($brand-black, 1));
$teaser-image-overlay-bottom-gradient: linear-gradient(180deg, rgba($brand-black, 0), rgba($brand-black, 1));
// also apply opacity: 0.25

/* BOX SHADOWS */
$basic-box-shadow: 0px 4px 12px rgba($brand-black, 0.1);
$header-shadow: 0px 5px 15px -5px rgba($brand-black, 0.15);
$category-options-shadow: 0 10px 15px 0 rgba($brand-black, 0.1);
$tile-shadow: 0 5px 20px 0 rgba($brand-black, 0.1);
$massive-shadow: 0 20px 75px 0 rgba(0, 0, 0, 0.1);

/* BORDER RADIUS */
$button-border-radius-full: 99rem; // fully rounded
$button-border-radius: 0.5rem; // (8px)
$teaser-border-radius: 1.25rem; // 20px
$teaser-card-border-radius: 1rem; // 16px
$card-border-radius: 0.875rem; // 14px
$tile-border-radius: 0.5rem; // 8px
$small-border-radius: 0.25rem; // 4px
$very-small-border-radius: 0.125rem; // 2px
