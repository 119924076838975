/* Scrolling background animation */
@keyframes scrollBackground {
  from {
    background-position: 0 100%;
  }
  to {
    background-position: 0 0%;
  }
}
.scrollBackground {
  animation-name: scrollBackground;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 30s;
}

/* Simple fade-in animation */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fade-in {
  animation-name: fade-in;
  animation-iteration-count: 1;
  animation-duration: 600ms;
}

.slow-fade-in {
  animation-name: fade-in;
  animation-iteration-count: 1;
  animation-duration: 1200ms;
}

/* Simple fade-out animation */
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade-out {
  animation-name: fade-out;
  animation-iteration-count: 1;
  animation-duration: 600ms;
}

/* Simple fly-in animation (MORE MENU) */
@keyframes fly-in {
  0% {
    left: -40rem;
  }
  100% {
    left: 0;
  }
}
.fly-in {
  animation-name: fly-in;
  animation-iteration-count: 1;
  animation-duration: 360ms;
}

/* Simple fly-out animation (MORE MENU) */
@keyframes fly-out {
  0% {
    left: 0;
  }
  100% {
    left: -40rem;
  }
}
.fly-out {
  animation-name: fly-out;
  animation-iteration-count: 1;
  animation-duration: 360ms;
}

/* Pulse animation */
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: TheSans;
  src: url("/shared-assets/fonts/TheSansPlain.eot") format("eot"), url("/shared-assets/fonts/TheSansPlain.woff") format("woff"), url("/shared-assets/fonts/TheSansPlain.woff2") format("woff2"), url("/shared-assets/fonts/TheSansPlain.ttf") format("truetype");
}
@font-face {
  font-family: TheSansBold;
  src: url("/shared-assets/fonts/TheSansBold.eot") format("eot"), url("/shared-assets/fonts/TheSansBold.woff") format("woff"), url("/shared-assets/fonts/TheSansBold.woff2") format("woff2"), url("/shared-assets/fonts/TheSansBold.ttf") format("truetype");
}
@font-face {
  font-family: TheSansLight;
  src: url("/shared-assets/fonts/TheSansLight.eot") format("eot"), url("/shared-assets/fonts/TheSansLight.woff") format("woff"), url("/shared-assets/fonts/TheSansLight.woff2") format("woff2"), url("/shared-assets/fonts/TheSansLight.ttf") format("truetype");
}
/* NEW FONT SIZES */
/* BASIC DISTANCES / SPACINGS */
/* MAIN BRAND COLOURS */
/* before instant change:  
$brand-pale-grey: #f5f5f5;
*/
/* SECONDARY COLOURS*/
/* INTERACTION COLOURS - light background */
/* INTERACTION SECONDARY COLOURS - light background */
/* INTERACTION COLOURS - dark background */
/* ICON COLORS */
/* SIGNAL COLOURS | SYSTEM UI */
/* ASSIGNMENT OF CHART COLOURS */
/* ------------------------------------------------------------------- */
/* SPECIAL COLOURS - not part of the palette */
/* SPECTRUM COLOURS - usable for gradients */
/* BACKGROUND COLOURS */
/* BOX OVERLAYS */
/* BOX SHADOWS */
/* BORDER RADIUS */
/* FUNCTION TO DETERMINE DARK OR LIGHT COLOR AND APPLY BEST CONTRAST COLOR */
/* Defaults for most elements */
html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
  scroll-behavior: smooth;
}

@-moz-document url-prefix() {
  html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
menu,
nav,
section,
time,
mark,
audio,
video,
details,
summary {
    scroll-behavior: auto;
  }
}
main,
article,
aside,
figure,
footer,
header,
nav,
section,
details,
summary {
  display: block;
}

/* Handle box-sizing while better addressing child elements */
/* https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* Responsive images and other embedded objects */
img,
object,
embed {
  max-width: 100%;
}

/* force a vertical scrollbar to prevent a jumpy page */
html {
  overflow-y: scroll;
}

/* we use a lot of ULs that aren't bulleted. You'll have to restore the bullets within content,	which is fine because they're probably customized anyway */
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  cursor: pointer;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

th {
  font-weight: bold;
  vertical-align: bottom;
}

td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #c8cbd4;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

pre {
  white-space: pre;
  /* CSS2 */
  white-space: pre-wrap;
  /* CSS 2.1 */
  white-space: pre-line;
  /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word;
  /* IE */
}

input[type=radio] {
  vertical-align: text-bottom;
}

input[type=checkbox] {
  vertical-align: bottom;
}

.ie7 input[type=checkbox] {
  vertical-align: baseline;
}

.ie6 input {
  vertical-align: text-bottom;
}

select,
input,
textarea {
  font: 99% sans-serif;
}

table {
  font-size: inherit;
  font: 100%;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

td,
td img {
  vertical-align: top;
}

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
label,
input[type=button],
input[type=submit],
input[type=file],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button,
input,
select,
textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button,
input[type=button] {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* let's clear some floats */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

@font-face {
  font-family: TheSans;
  src: url("/shared-assets/fonts/TheSansPlain.eot") format("eot"), url("/shared-assets/fonts/TheSansPlain.woff") format("woff"), url("/shared-assets/fonts/TheSansPlain.woff2") format("woff2"), url("/shared-assets/fonts/TheSansPlain.ttf") format("truetype");
}
@font-face {
  font-family: TheSansBold;
  src: url("/shared-assets/fonts/TheSansBold.eot") format("eot"), url("/shared-assets/fonts/TheSansBold.woff") format("woff"), url("/shared-assets/fonts/TheSansBold.woff2") format("woff2"), url("/shared-assets/fonts/TheSansBold.ttf") format("truetype");
}
@font-face {
  font-family: TheSansLight;
  src: url("/shared-assets/fonts/TheSansLight.eot") format("eot"), url("/shared-assets/fonts/TheSansLight.woff") format("woff"), url("/shared-assets/fonts/TheSansLight.woff2") format("woff2"), url("/shared-assets/fonts/TheSansLight.ttf") format("truetype");
}
/* NEW FONT SIZES */
/* BASIC DISTANCES / SPACINGS */
/* MAIN BRAND COLOURS */
/* before instant change:  
$brand-pale-grey: #f5f5f5;
*/
/* SECONDARY COLOURS*/
/* INTERACTION COLOURS - light background */
/* INTERACTION SECONDARY COLOURS - light background */
/* INTERACTION COLOURS - dark background */
/* ICON COLORS */
/* SIGNAL COLOURS | SYSTEM UI */
/* ASSIGNMENT OF CHART COLOURS */
/* ------------------------------------------------------------------- */
/* SPECIAL COLOURS - not part of the palette */
/* SPECTRUM COLOURS - usable for gradients */
/* BACKGROUND COLOURS */
/* BOX OVERLAYS */
/* BOX SHADOWS */
/* BORDER RADIUS */
/* MASTER GRID AS A MIXIN */
:root {
  --safe-area-inset-top: const(safe-area-inset-top);
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: const(safe-area-inset-bottom);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --app-height: 100%;
}

.withBottomMarginTablet {
  margin-bottom: 55px;
}

.withBottomMargin {
  margin-bottom: 130px;
}

.withoutBottomMargin {
  margin-bottom: 0;
}

html.noScrolling {
  min-height: auto;
  max-height: auto;
  overflow: hidden;
  height: 100vh;
}

body {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 1rem;
  line-height: 150%;
  color: #00233e;
  scroll-behavior: smooth;
  width: 100%;
  -webkit-text-size-adjust: none;
  /** [DP-331] Fix for 'scrollPositionRestoration: "top"' issue for firefox browser */
}

@-moz-document url-prefix() {
  body {
    scroll-behavior: auto;
  }
}
body .root {
  background-color: #ffffff;
}

body .mainContentArea {
  min-height: calc(100vh - 458px);
}

@media (min-width: 48rem) {
  body .mainContentArea {
    min-height: calc(100vh - 372px);
  }
}
body.anonymous .mainContentArea {
  min-height: calc(100vh - 274px);
}

body.freeze {
  overflow-y: hidden;
  position: fixed;
  left: 0;
  right: 0;
}

.content-wrapper {
  width: 100%;
  position: relative;
}

@media (min-width: 48rem) {
  .content-wrapper {
    min-height: calc(100vh - 274px);
  }
}
.content-wrapper.anonymous {
  min-height: calc(100vh - 274px);
}

.content-wrapper.anonymous section {
  margin: 2rem 0;
}

@media (min-width: 48rem) {
  .content-wrapper.anonymous section {
    margin: 4rem 0;
  }
}
.inner {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (min-width: 64rem) {
  .inner {
    max-width: calc(1440px + 7.5rem);
    padding: 0 3.75rem;
  }
}
@media (min-width: 64rem) {
  .inner.veryNarrow {
    max-width: calc(1024px + 7.5rem);
  }
}
/* SOME GENERAL GLOBAL HELPER CLASSES */
hr {
  border: none;
  height: 1px;
  background: rgba(0, 35, 62, 0.06);
  margin: 0.75rem 0;
}

.hide {
  display: none;
}

.veryTinySpacer {
  height: 0.5rem;
}

.tinySpacer {
  height: 1rem;
}

.spacer {
  height: 2rem;
}

.bigSpacer {
  height: 4rem;
}

.massiveSpacer {
  height: 10rem;
}

.cmsPreview .sectionContainer > *:not(.inner),
.cmsPreview .sectionContainer > .inner > * {
  margin: 1.5rem auto 4rem auto;
  display: block;
}

.contentComposition > div.mainContent > *[class^=contentArea].contentArea1Container app-dynamic-page-teaser,
.contentComposition > div.mainContent > *[class^=contentArea].contentArea1Container .no-top-spacing {
  margin-top: 0 !important;
}

.contentComposition > div.mainContent > *[class^=contentArea] > * {
  display: block;
  margin: 2rem 0;
}

@media (min-width: 48rem) {
  .contentComposition > div.mainContent > *[class^=contentArea] > * {
    margin: 4rem 0;
  }
}
.contentComposition > div.mainContent > *[class^=contentArea] > app-headline-helper {
  margin-bottom: -0.75rem !important;
}

@media (min-width: 48rem) {
  .contentComposition > div.mainContent > *[class^=contentArea] > app-headline-helper {
    margin-bottom: -2.5rem !important;
  }
}
.contentComposition > div.mainContent > *[class^=contentArea] > app-dynamic-content-teaser {
  margin: 2rem 0;
}

@media (min-width: 48rem) {
  .contentComposition > div.mainContent > *[class^=contentArea] > app-dynamic-content-teaser {
    margin: 4rem 0;
  }
}
.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea p {
  padding: 0 0 1.5rem 0;
}

.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea p.lineClamped {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  min-height: 3rem;
  padding: 0;
  margin: 0 0 1.5rem 0;
}

.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea p:last-child {
  padding: 0;
}

.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea ul {
  padding-bottom: 1.5rem;
}

.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea ul li > p {
  padding: 0.25rem;
}

.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea h1,
.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea h2,
.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea h3 {
  padding: 0 0 1rem 0;
}

.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea h1.lineClamped,
.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea h2.lineClamped,
.contentComposition > div.mainContent > *[class^=contentArea] .mainTextArea h3.lineClamped {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.cmsTable {
  min-width: 820px;
}

.cmsTable tr th {
  padding: 0.5rem 1.5rem 0.2rem 0;
  border-bottom: 2px solid #c8cbd4;
}

.cmsTable tr th p {
  font-family: "TheSansBold", Arial, sans-serif;
  text-align: left;
  text-transform: uppercase;
  color: #9598a1;
  font-size: 1rem;
  line-height: 130%;
  padding-bottom: 0.25rem;
}

.cmsTable tr th.firstCell p {
  color: #00233e;
}

.cmsTable tr td {
  padding: 0.5rem 1.5rem 0.5rem 0;
  border-bottom: 1px solid #c8cbd4;
}

.cmsTable tr td p {
  color: #9598a1;
}

.cmsTable tr td.firstCell p {
  font-family: "TheSansBold", Arial, sans-serif;
  color: #00233e;
}

.dynamicImageTextCombination .textContentArea .paragraphsSection,
.dynamicImageTextCombination .onlyImageArea .textContainer .paragraphsSection {
  padding-top: 0.25rem;
}

.dynamicImageTextCombination .textContentArea .paragraphsSection p,
.dynamicImageTextCombination .onlyImageArea .textContainer .paragraphsSection p {
  padding: 1.5rem 0 0 0;
}

.dynamicImageTextCombination .textContainer .bodyCopySection h2,
.dynamicImageTextCombination .textContainer .bodyCopySection h3 {
  padding: 0 0 1.25rem 0;
}

.dynamicImageTextCombination .textContainer .bodyCopySection > p {
  padding: 0 0 1.25rem 0;
}

.dynamicImageTextCombination .textContainer .bodyCopySection ul {
  /* margin: -1rem 0 1.5rem 2rem; */
  margin: 0 0 1.5rem 2rem;
}

.mashUpWrapper .imageCardContainer.layout_twoColumns app-dynamic-image-card:nth-child(odd) > .imageCardWrapper, .mashUpWrapper .imageCardContainer.layout_2-columns app-dynamic-image-card:nth-child(odd) > .imageCardWrapper {
  float: right;
}

.mashUpWrapper .imageCardContainer.layout_twoColumns app-dynamic-image-card:nth-child(even) > .imageCardWrapper, .mashUpWrapper .imageCardContainer.layout_2-columns app-dynamic-image-card:nth-child(even) > .imageCardWrapper {
  float: left;
}

#xm-survey-videos .scrollable {
  height: 68px !important;
}

.json-container {
  font-family: menlo, consolas, monospace;
  font-style: normal;
  font-weight: bold;
  line-height: 1.4em;
  font-size: 0.9rem;
  transition: background-color 400ms;
}

a.json-link {
  text-decoration: none;
  border-bottom: 1px solid;
  outline: none;
}

a.json-link:hover {
  background-color: transparent;
  outline: none;
}

ol.json-lines {
  white-space: normal;
  padding-inline-start: 3em;
  margin: 0px;
}

ol.json-lines > li {
  white-space: pre;
  text-indent: 0.7em;
  line-height: 1.5em;
  padding: 0px;
}

ol.json-lines > li::marker {
  font-family: system-ui, sans-serif;
  font-weight: normal;
}

.json-key,
.json-string,
.json-number,
.json-boolean,
.json-null,
.json-mark,
a.json-link,
ol.json-lines > li {
  transition: all 400ms;
}

.json-container {
  background-color: white;
}

.json-key {
  color: brown;
}

.json-string {
  color: olive;
  white-space: break-spaces;
}

.json-number {
  color: navy;
}

.json-boolean {
  color: teal;
}

.json-null {
  color: dimgray;
}

.json-mark {
  color: black;
}

a.json-link {
  color: purple;
}

a.json-link:visited {
  color: slategray;
}

a.json-link:hover {
  color: blueviolet;
}

a.json-link:active {
  color: slategray;
}

ol.json-lines > li::marker {
  color: white;
}

ol.json-lines > li:nth-child(odd) {
  background-color: transparent;
}

ol.json-lines > li:nth-child(even) {
  background-color: transparent;
}

ol.json-lines > li:hover {
  background-color: transparent;
}

/* GENRAL TAGS */
strong {
  font-family: "TheSansBold", Arial, sans-serif;
  font-weight: 400;
}

input {
  font-family: "TheSans", Arial, sans-serif;
}

/* PARAGRAPHS AND COPY TEXT */
/* copy2 is going to be default. copy1 is larger, copy3 is smaller */
.foreignHTML .hideInDPortal {
  display: none;
}

p {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 1rem;
  line-height: 150%;
  color: #4a4c50;
  margin: 0;
  padding: 0;
}

p.grey {
  color: #9598a1;
}

p.error {
  padding: 1rem 1.5rem;
  margin: 1rem auto;
  color: #ff256f;
  background-color: rgba(255, 37, 111, 0.1);
  border: 1px solid #ff256f;
  border-radius: 1.25rem;
  display: inline-block;
}

p.addPadding {
  padding-bottom: 24px;
}

p.copy1 {
  font-size: 1.125rem;
}

@media (min-width: 48rem) {
  p.copy1 {
    font-size: 1.25rem;
  }
}
p.copy2 {
  font-size: 1rem;
}

p.copy3 {
  font-size: 0.875rem;
}

p.copy4 {
  font-size: 0.75rem;
}

p.label {
  font-family: "TheSansBold", Arial, sans-serif;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #4a4c50;
}

p.label span {
  display: inline-block;
  padding: 0 0.15rem;
}

p.label.black {
  color: #1d1d1f;
}

p.label.inTile {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 0.875rem;
  text-transform: initial;
}

/* LINKS */
a {
  color: #0074de;
  text-decoration: underline;
}

a.dark {
  color: #00233e;
}

a.dark:hover, a.dark:visited {
  text-decoration: none;
  color: #003466;
}

a:hover, a.hovered {
  text-decoration: none;
  color: #007ae7;
}

a.disabled {
  color: #c8cbd4;
  cursor: not-allowed;
}

a.disabled:hover, a.disabled.hovered {
  text-decoration: underline;
}

/* NEW ELEMENT: Overline */
p.overline,
span.overline,
p.subline,
span.subline {
  font-family: "TheSansLight", Arial, sans-serif;
  font-size: 1.25rem;
  color: #00233e;
}

@media (min-width: 48rem) {
  p.overline,
span.overline,
p.subline,
span.subline {
    font-size: 1.5rem;
  }
}
p.overline-small,
span.overline-small,
p.subline-small,
span.subline-small {
  font-family: "TheSansLight", Arial, sans-serif;
  font-size: 1rem;
  color: #00233e;
}

/* HEADLINES & SUBHEADLINES */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "TheSansBold", Arial, sans-serif;
  font-weight: 400;
  line-height: 130%;
  margin: 0;
  padding: 0;
  color: #00233e;
}

h1.addPadding,
h2.addPadding,
h3.addPadding,
h4.addPadding,
h5.addPadding,
h6.addPadding {
  padding-bottom: 16px;
}

h1 span.unBold,
h2 span.unBold,
h3 span.unBold,
h4 span.unBold,
h5 span.unBold,
h6 span.unBold {
  font-family: "TheSansLight", Arial, sans-serif !important;
}

h1 {
  font-size: 2rem;
}

@media (min-width: 48rem) {
  h1 {
    font-size: 3rem;
  }
}
h1.special {
  font-size: 3rem;
  line-height: 100%;
  padding-bottom: 0.33rem;
}

@media (min-width: 48rem) {
  h1.special {
    font-size: 5rem;
    padding-bottom: 0.4rem;
  }
}
h2 {
  font-size: 1.5rem;
  color: #1d1d1f;
}

@media (min-width: 48rem) {
  h2 {
    font-size: 2rem;
  }
}
h3 {
  font-size: 1.25rem;
  color: #1d1d1f;
}

@media (min-width: 48rem) {
  h3 {
    font-size: 1.5rem;
  }
}
/* H5 and H6 will never be used */
h4,
h5,
h6 {
  color: #1d1d1f;
  font-size: 1.125rem;
}

/* ON DARK OR IMAGE BACKGROUNDS = inverted mode */
.inverted h1,
.inverted h2,
.inverted h3,
.inverted h4,
.inverted h5,
.inverted h6,
.inverted p,
.inverted a,
.inverted span.overline,
.inverted span.overline-small,
.inverted span.subline,
.inverted span.subline-small {
  color: #ffffff;
}

.inverted a.disabled {
  color: #c8cbd4;
}

/* SPECIAL HEADLINES (Headlines with "Show more link" on the rigth) */
.headlineWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 1.25rem 0;
}

@media (min-width: 48rem) {
  .headlineWrapper {
    flex-wrap: nowrap;
    padding: 0 0 2rem 0;
  }
}
.headlineWrapper .showAllLink {
  width: 100%;
}

@media (min-width: 48rem) {
  .headlineWrapper .showAllLink {
    width: auto;
  }
}
.headlineWrapper .showAllLink button.text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  max-width: 100%;
}

.headlineWrapper.noPadding {
  padding: 0;
}

.textArea h2,
.textArea h3 {
  padding: 1.5rem 0 0.5rem;
}

.textArea p {
  padding: 0.25rem 0 1rem 0;
}

@media (min-width: 48rem) {
  .textArea p {
    padding: 0.25rem 2rem 1rem 0;
  }
}
ol,
.textArea ol {
  margin-left: 1rem;
}

ol li p,
.textArea ol li p {
  margin: 0;
  padding: 0.25rem;
}

ul:not(.checkmarkList):not(.benefitsList):not(.hideBulletPoints),
.textArea ul:not(.checkmarkList):not(.benefitsList):not(.hideBulletPoints) {
  list-style-type: disc;
  margin-left: 1.25rem;
}

ul:not(.checkmarkList):not(.benefitsList):not(.hideBulletPoints) li p,
.textArea ul:not(.checkmarkList):not(.benefitsList):not(.hideBulletPoints) li p {
  margin: 0;
  padding: 0.25rem;
}

ul.checkmarkList,
.textArea ul.checkmarkList {
  margin-left: 2.1rem;
}

ul.checkmarkList li,
.textArea ul.checkmarkList li {
  position: relative;
}

ul.checkmarkList li p,
.textArea ul.checkmarkList li p {
  padding: 0.25rem;
}

ul.checkmarkList li:before,
.textArea ul.checkmarkList li:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: -2rem;
  height: 2rem;
  width: 2rem;
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/check-mark.svg);
  mask-size: 60%;
  mask-repeat: no-repeat;
  mask-position: center;
}

.inverted .textContainer ul.checkmarkList li::before {
  background-color: #ffffff;
}

div.expandableTextWrapper {
  position: relative;
}

div.expandableTextWrapper p {
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 1.75rem;
  white-space: pre-wrap;
}

div.expandableTextWrapper p.expanded {
  display: block;
  height: 100% !important;
}

@font-face {
  font-family: TheSans;
  src: url("/shared-assets/fonts/TheSansPlain.eot") format("eot"), url("/shared-assets/fonts/TheSansPlain.woff") format("woff"), url("/shared-assets/fonts/TheSansPlain.woff2") format("woff2"), url("/shared-assets/fonts/TheSansPlain.ttf") format("truetype");
}
@font-face {
  font-family: TheSansBold;
  src: url("/shared-assets/fonts/TheSansBold.eot") format("eot"), url("/shared-assets/fonts/TheSansBold.woff") format("woff"), url("/shared-assets/fonts/TheSansBold.woff2") format("woff2"), url("/shared-assets/fonts/TheSansBold.ttf") format("truetype");
}
@font-face {
  font-family: TheSansLight;
  src: url("/shared-assets/fonts/TheSansLight.eot") format("eot"), url("/shared-assets/fonts/TheSansLight.woff") format("woff"), url("/shared-assets/fonts/TheSansLight.woff2") format("woff2"), url("/shared-assets/fonts/TheSansLight.ttf") format("truetype");
}
/* NEW FONT SIZES */
/* BASIC DISTANCES / SPACINGS */
/* MAIN BRAND COLOURS */
/* before instant change:  
$brand-pale-grey: #f5f5f5;
*/
/* SECONDARY COLOURS*/
/* INTERACTION COLOURS - light background */
/* INTERACTION SECONDARY COLOURS - light background */
/* INTERACTION COLOURS - dark background */
/* ICON COLORS */
/* SIGNAL COLOURS | SYSTEM UI */
/* ASSIGNMENT OF CHART COLOURS */
/* ------------------------------------------------------------------- */
/* SPECIAL COLOURS - not part of the palette */
/* SPECTRUM COLOURS - usable for gradients */
/* BACKGROUND COLOURS */
/* BOX OVERLAYS */
/* BOX SHADOWS */
/* BORDER RADIUS */
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid rgba(72, 72, 72, 0.2);
}

.flatpickr-calendar.hasTime .flatpickr-innerContainer {
  border-bottom: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border: 1px solid rgba(72, 72, 72, 0.2);
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: rgba(72, 72, 72, 0.2);
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #00233e;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: rgba(72, 72, 72, 0.2);
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #00233e;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  border-radius: 5px 5px 0 0;
  background: #00233e;
  color: #ffffff;
  fill: #ffffff;
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: #ffffff;
  fill: #ffffff;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #bbb;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(72, 72, 72, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(72, 72, 72, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(72, 72, 72, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(255, 255, 255, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #fff;
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(255, 255, 255, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: #00233e;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #00233e;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: #00233e;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: #00233e;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
  border-left: 1px solid rgba(72, 72, 72, 0.2);
  border-right: 1px solid rgba(72, 72, 72, 0.2);
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 rgba(72, 72, 72, 0.2);
  box-shadow: -1px 0 0 rgba(72, 72, 72, 0.2);
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #484848;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e2e2e2;
  border-color: #e2e2e2;
}

.flatpickr-day.today {
  border-color: #bbb;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #bbb;
  background: #bbb;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #00233e;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #00233e;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #00233e;
  box-shadow: -10px 0 0 #00233e;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
  box-shadow: -5px 0 0 #e2e2e2, 5px 0 0 #e2e2e2;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(72, 72, 72, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #00233e, 5px 0 0 #00233e;
  box-shadow: -5px 0 0 #00233e, 5px 0 0 #00233e;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  border-left: 1px solid rgba(72, 72, 72, 0.2);
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(72, 72, 72, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid rgba(72, 72, 72, 0.2);
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  border-radius: 0 0 5px 5px;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #484848;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #484848;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eaeaea;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@font-face {
  font-family: TheSans;
  src: url("/shared-assets/fonts/TheSansPlain.eot") format("eot"), url("/shared-assets/fonts/TheSansPlain.woff") format("woff"), url("/shared-assets/fonts/TheSansPlain.woff2") format("woff2"), url("/shared-assets/fonts/TheSansPlain.ttf") format("truetype");
}
@font-face {
  font-family: TheSansBold;
  src: url("/shared-assets/fonts/TheSansBold.eot") format("eot"), url("/shared-assets/fonts/TheSansBold.woff") format("woff"), url("/shared-assets/fonts/TheSansBold.woff2") format("woff2"), url("/shared-assets/fonts/TheSansBold.ttf") format("truetype");
}
@font-face {
  font-family: TheSansLight;
  src: url("/shared-assets/fonts/TheSansLight.eot") format("eot"), url("/shared-assets/fonts/TheSansLight.woff") format("woff"), url("/shared-assets/fonts/TheSansLight.woff2") format("woff2"), url("/shared-assets/fonts/TheSansLight.ttf") format("truetype");
}
/* NEW FONT SIZES */
/* BASIC DISTANCES / SPACINGS */
/* MAIN BRAND COLOURS */
/* before instant change:  
$brand-pale-grey: #f5f5f5;
*/
/* SECONDARY COLOURS*/
/* INTERACTION COLOURS - light background */
/* INTERACTION SECONDARY COLOURS - light background */
/* INTERACTION COLOURS - dark background */
/* ICON COLORS */
/* SIGNAL COLOURS | SYSTEM UI */
/* ASSIGNMENT OF CHART COLOURS */
/* ------------------------------------------------------------------- */
/* SPECIAL COLOURS - not part of the palette */
/* SPECTRUM COLOURS - usable for gradients */
/* BACKGROUND COLOURS */
/* BOX OVERLAYS */
/* BOX SHADOWS */
/* BORDER RADIUS */
footer {
  bottom: 0;
  width: 100%;
}

footer .inner {
  padding-bottom: 1rem;
}

footer .inner .footer-image-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
}

@media (min-width: 48rem) {
  footer .inner .footer-image-row {
    flex-wrap: wrap;
    margin-bottom: 3.5rem;
  }
}
footer .inner .footer-image-row .footer-logo {
  cursor: pointer;
  display: block;
  width: 9rem;
  height: auto;
  margin: 0 1rem 0 0;
}

@media (min-width: 48rem) {
  footer .inner .footer-image-row .footer-logo {
    width: 268px;
  }
}
footer .inner .footer-image-row .claim {
  width: 11rem;
  height: auto;
  margin: 10px 0 0 auto;
}

@media (min-width: 48rem) {
  footer .inner .footer-image-row .claim {
    width: 268px;
    margin-top: 12px;
  }
}
footer .inner .footer-image-row .claim.left-aligned {
  margin: 10px auto 0 0;
}

footer .inner .footer-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 48rem) {
  footer .inner .footer-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }
}
footer .inner .footer-content:before {
  border-top: 5px solid #00233e;
  content: "";
  display: block;
  width: 100%;
}

footer .inner .footer-content .footer-copyright {
  min-height: 48px;
  display: flex;
  align-items: center;
}

footer .inner .footer-content .footer-copyright a,
footer .inner .footer-content .footer-copyright span {
  font-size: 1rem;
}

footer .inner .footer-content .footer-copyright .icon {
  padding: 0 0.25rem;
  width: 1.75rem;
  height: 1.25rem;
  display: inline-block;
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/helper/copyright.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

footer .inner .footer-content .footer-copyright a {
  color: #00233e;
  text-decoration: none;
  padding-left: 0.5rem;
}

@media (min-width: 64rem) {
  footer .inner .footer-content .footer-copyright a {
    padding-left: 1.25rem;
  }
}
footer .inner .footer-content .footer-copyright a:hover {
  text-decoration: none;
  color: #0074de;
}

footer .inner .footer-content .footer-links {
  font-size: 1rem;
  padding: 0 0 1rem 0;
  text-align: center;
  min-height: 48px;
  display: flex;
  align-items: center;
}

@media (min-width: 48rem) {
  footer .inner .footer-content .footer-links {
    margin-left: auto;
    padding: 0;
  }
}
footer .inner .footer-content .footer-links a {
  color: #00233e;
  text-decoration: none;
  display: inline-block;
}

footer .inner .footer-content .footer-links a:after {
  content: "";
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media (min-width: 64rem) {
  footer .inner .footer-content .footer-links a:after {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
footer .inner .footer-content .footer-links a:hover {
  color: #0074de;
}

footer .inner .footer-content .footer-links a:last-child:after {
  margin: 0;
  display: none;
}

footer .inner .footer-socials {
  display: flex;
}

footer .inner .footer-socials a {
  display: inline-block;
  padding: 0 0.5rem;
  color: #00233e;
}

@media (min-width: 64rem) {
  footer .inner .footer-socials a {
    padding: 0 0.75rem;
  }
}
footer .inner .footer-socials a:last-child {
  padding: 0 0 0 0.5rem;
}

@media (min-width: 64rem) {
  footer .inner .footer-socials a:last-child {
    padding: 0 0 0 0.75rem;
  }
}
footer .inner .footer-socials a .icon {
  width: 1.25rem;
  height: 1.25rem;
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/favourites.svg);
  mask-size: 80%;
  mask-repeat: no-repeat;
  mask-position: center;
}

@media (min-width: 64rem) {
  footer .inner .footer-socials a .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
footer .inner .footer-socials a .icon.linkedin {
  mask-image: url(/shared-assets/icons/helper/linkedin.svg);
}

footer .inner .footer-socials a .icon.facebook {
  mask-image: url(/shared-assets/icons/helper/facebook.svg);
}

footer .inner .footer-socials a .icon.twitter {
  mask-image: url(/shared-assets/icons/helper/twitter.svg);
}

footer .inner .footer-socials a .icon:hover {
  background-color: #0074de;
}

/* INPUT FIELDS - Default Option */
.defaultFormField {
  width: 100%;
}

.defaultFormField.reducedSize {
  max-width: 360px;
}

.defaultFormField .mat-select {
  height: 1.5rem;
}

.defaultFormField .mat-select .mat-select-trigger {
  height: 1.3rem;
  padding-top: 0.2rem;
  font-size: 1rem;
  line-height: 150%;
}

.defaultFormField .mat-select .mat-select-arrow-wrapper {
  border-left: none;
  border-right: none;
  background-color: #9598a1;
  mask-image: url(/shared-assets/icons/basic/arrow_down_first_single.svg);
  mask-size: 70%;
  mask-repeat: no-repeat;
  mask-position: center;
  transform: translateY(-25%);
}

.defaultFormField input.mat-input-element {
  margin-top: 0 !important;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-underline:before,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  height: 0;
  background-color: #0074de !important;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-label-wrapper label.mat-form-field-label mat-label,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-label-wrapper label.mat-form-field-label .mat-placeholder-required.mat-form-field-required-marker {
  color: #0074de !important;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-flex {
  border: 2px solid #0074de !important;
  padding: 0 0.5rem 0 1rem !important;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused.mat-primary .mat-select-arrow {
  color: #0074de !important;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1rem) scale(0.73);
  width: 140%;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label mat-label,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label .mat-placeholder-required.mat-form-field-required-marker {
  color: #9598a1 !important;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-flex {
  background: #ffffff !important;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: #ffffff !important;
  cursor: not-allowed;
  opacity: 0.3;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex .mat-form-field-infix input.mat-input-element,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex .mat-form-field-infix textarea.mat-input-element {
  cursor: not-allowed;
  color: #00233e;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label mat-label,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label .mat-placeholder-required.mat-form-field-required-marker {
  color: #9598a1 !important;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline:before,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline .mat-form-field-ripple {
  background-color: #c8cbd4;
}

.defaultFormField .mat-form-field-flex {
  background: #f9fafc !important;
  border-radius: 0.25rem !important;
  border: 1px solid #c8cbd4;
  padding: 1px 0.5rem 1px 1rem !important;
}

.defaultFormField.forceWhite .mat-form-field-wrapper .mat-form-field-flex {
  background: #ffffff !important;
  background-color: #ffffff !important;
  border: 1px solid transparent !important;
}

.defaultFormField .mat-form-field-underline {
  display: none;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid .mat-form-field-flex, .defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-flex {
  background-color: #ffffff;
  background: #ffffff;
  border: 2px solid #ff256f !important;
  padding: 0 0.5rem 0 1rem !important;
}

.defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid .mat-form-field-flex .mat-form-field-infix input.mat-input-element,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid .mat-form-field-flex .mat-form-field-infix textarea.mat-input-element,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid .mat-form-field-flex .mat-form-field-infix .mat-select-value-text,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label mat-label,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label .mat-placeholder-required.mat-form-field-required-marker, .defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-flex .mat-form-field-infix input.mat-input-element,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-flex .mat-form-field-infix textarea.mat-input-element,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-flex .mat-form-field-infix .mat-select-value-text,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label mat-label,
.defaultFormField.mat-form-field-appearance-fill.mat-form-field-invalid.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label .mat-placeholder-required.mat-form-field-required-marker {
  color: #ff256f !important;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-flex {
  padding: 0;
  background: #ffffff;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  width: auto;
  min-height: 4rem;
  border-top: 0.25rem solid transparent;
  padding: 1.25rem 0 1rem 0;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix input.mat-input-element,
.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select-value-text,
.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select,
.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-date-range-input-inner {
  font-size: 1rem;
  line-height: 150%;
  color: #00233e;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix textarea.mat-input-element {
  min-height: 7.5rem;
  font-size: 1rem;
  line-height: 150%;
  color: #00233e;
  width: calc(100% - 1rem);
  margin: -1rem 0.5rem -0.25rem 0.5rem;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
  top: 0;
  padding-top: 0;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label {
  top: 1.3rem;
  margin-top: 0;
  overflow: visible;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label .mat-placeholder-required.mat-form-field-required-marker {
  color: #9598a1;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label mat-label {
  font-size: 1rem;
  line-height: 100%;
  color: #9598a1;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  padding-bottom: 0.25rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label mat-label::-webkit-scrollbar,
.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label mat-label ::-webkit-scrollbar {
  display: none;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  color: #9598a1;
  font-size: 1rem;
  line-height: 130%;
  align-self: center;
  padding: 0.5rem 0 0 0.5rem;
  display: flex;
  align-items: center;
}

.defaultFormField .mat-form-field-wrapper .mat-form-field-underline:before,
.defaultFormField .mat-form-field-wrapper .mat-form-field-underline .mat-form-field-ripple {
  background-color: rgba(0, 116, 222, 0.5);
}

.defaultFormField .mat-select-arrow {
  color: rgba(0, 116, 222, 0.66);
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
  margin: 0 6px;
}

/* INPUT FIELDS - Special Option */
/* Only used for target market selection fly in (currently inactive!) */
.specialFormField {
  width: 100%;
}

.specialFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-underline:before,
.specialFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  height: 0;
  background-color: #0074de !important;
}

.specialFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-label-wrapper label.mat-form-field-label mat-label,
.specialFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-label-wrapper label.mat-form-field-label .mat-placeholder-required.mat-form-field-required-marker {
  color: #0074de !important;
}

.specialFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused .mat-form-field-flex {
  border: 2px solid #0074de !important;
  padding: 0 0.75rem 0 3.75rem !important;
}

.specialFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float.mat-focused.mat-primary .mat-select-arrow {
  color: #0074de !important;
}

.specialFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.5rem) scale(0.74);
  width: 140%;
}

.specialFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label mat-label,
.specialFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label .mat-placeholder-required.mat-form-field-required-marker {
  color: #9598a1 !important;
}

.specialFormField.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-flex {
  background: #ffffff !important;
}

.specialFormField .mat-form-field-flex {
  background: #f9fafc !important;
  border-radius: 0.25rem !important;
  border: 1px solid #c8cbd4;
  padding: 1px 0.75rem 1px 3.75rem !important;
}

.specialFormField .mat-form-field-underline {
  display: none;
}

.specialFormField.mat-focused .mat-form-field-underline:before,
.specialFormField.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  height: 2px;
  background-color: #0074de !important;
}

.specialFormField.mat-focused .mat-form-field-label-wrapper label.mat-form-field-label mat-label,
.specialFormField.mat-focused .mat-form-field-label-wrapper label.mat-form-field-label .mat-placeholder-required.mat-form-field-required-marker {
  color: #0074de !important;
}

.specialFormField.mat-focused.mat-primary .mat-select-arrow {
  color: #0074de !important;
}

.specialFormField .mat-form-field-wrapper .mat-form-field-flex {
  padding: 0;
  background: rgba(0, 35, 62, 0.06);
}

.specialFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  width: auto;
  min-height: 4rem;
  border-top: 0.25rem solid transparent;
  padding: 1.9rem 2rem 1.6rem 0;
}

.specialFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix input.mat-input-element {
  font-size: 1.125rem;
  line-height: 1.33rem;
  color: #00233e;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.specialFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
  top: -0.15rem;
  padding-top: 0;
}

.specialFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label {
  top: 1.87rem;
  margin-top: 0;
  overflow: visible;
}

.specialFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper label.mat-form-field-label mat-label {
  font-size: 1.125rem;
  line-height: 1.33rem;
  color: #9598a1;
}

.specialFormField .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  color: #007fb2;
  font-size: 1.125rem;
  line-height: 1.33rem;
  align-self: center;
  padding: 0.25rem 0 0 0.5rem;
  display: flex;
  align-items: center;
}

.specialFormField .mat-form-field-wrapper .mat-form-field-underline:before,
.specialFormField .mat-form-field-wrapper .mat-form-field-underline .mat-form-field-ripple {
  background-color: rgba(0, 127, 178, 0.5);
}

/* AUTO COMPLETE FORM FIELD */
.mat-autocomplete-panel.mat-autocomplete-visible.specialAutoComplete {
  top: 0;
  max-height: 45vh;
}

.mat-autocomplete-panel.mat-autocomplete-visible.specialAutoComplete .mat-option {
  color: #9598a1;
  height: auto;
  line-height: 1.25rem;
  font-size: 1.125rem;
  padding: 0.5rem 0.75rem;
  border-bottom: 1px solid rgba(149, 152, 161, 0.3);
}

.mat-autocomplete-panel.mat-autocomplete-visible.specialAutoComplete .mat-option .additionalInfo {
  display: block;
  font-size: 0.75rem;
}

/* CHECKBOXES */
.doehlerCheckbox.movedToTop .mat-checkbox-layout {
  margin-top: -0.75rem;
}

.doehlerCheckbox .mat-ripple-element {
  background-color: rgba(0, 122, 231, 0.25);
}

.doehlerCheckbox:hover .mat-checkbox-frame, .doehlerCheckbox.hoverable .mat-checkbox-frame {
  border-color: #0068c6;
  background-color: #ffffff;
}

.doehlerCheckbox:hover .mat-checkbox-layout .mat-checkbox-label, .doehlerCheckbox.hoverable .mat-checkbox-layout .mat-checkbox-label {
  color: #0068c6;
}

.doehlerCheckbox.mat-checkbox-disabled {
  cursor: not-allowed;
}

.doehlerCheckbox.mat-checkbox-disabled .mat-checkbox-frame {
  border-color: #c8cbd4 !important;
  background-color: #ffffff;
}

.doehlerCheckbox.mat-checkbox-disabled:hover .mat-checkbox-frame, .doehlerCheckbox.mat-checkbox-disabled.hoverable .mat-checkbox-frame {
  border-color: #c8cbd4;
  background-color: #ffffff;
}

.doehlerCheckbox.mat-checkbox-disabled .mat-checkbox-frame {
  border-color: #c8cbd4;
  background-color: #ffffff;
}

.doehlerCheckbox.mat-checkbox-disabled .mat-checkbox-layout .mat-checkbox-label {
  color: #c8cbd4;
  -webkit-text-fill-color: #c8cbd4;
  opacity: 1;
}

.doehlerCheckbox.mat-checkbox-checked.mat-accent .mat-checkbox-inner-container .mat-checkbox-background {
  background-color: #0074de;
}

.doehlerCheckbox .mat-checkbox-layout {
  white-space: normal;
  align-items: center;
}

.doehlerCheckbox .mat-checkbox-inner-container {
  height: 1.75rem;
  width: 1.75rem;
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom: 0;
}

.doehlerCheckbox .mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple {
  opacity: 0 !important;
}

.doehlerCheckbox .mat-checkbox-label-before .mat-checkbox-inner-container {
  margin-left: 1rem;
  margin-right: auto;
}

.doehlerCheckbox .mat-checkbox-frame {
  border-width: 1px;
  border-radius: 0.25rem;
  border-color: #9598a1;
  background-color: #ffffff;
}

.doehlerCheckbox .mat-checkbox-background {
  border-radius: 0.25rem;
}

.doehlerCheckbox .mat-checkbox-layout .mat-checkbox-label {
  line-height: 125%;
  font-size: 1rem;
  color: #9598a1;
}

/* CHECKBOX GROUP */
.checkbox-group-container {
  display: flex;
  flex-direction: row;
}

.checkbox-group-container .doehlerCheckbox {
  margin: 0.5rem 2rem 0 0;
}

.checkbox-group-container .doehlerCheckbox.visionBoard {
  margin: 0;
  width: 100%;
}

.checkbox-group-container .doehlerCheckbox.visionBoard .mat-checkbox-layout {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
}

.checkbox-group-container .doehlerCheckbox.visionBoard .mat-checkbox-layout .mat-checkbox-label {
  font-family: "TheSansBold", Arial, sans-serif;
  color: #9598a1;
  margin-right: 1.5rem;
}

.checkbox-group-container .doehlerCheckbox.visionBoard .mat-checkbox-layout .mat-checkbox-inner-container {
  margin-right: 0;
}

.checkbox-group-container.vertical {
  flex-direction: column;
}

.checkbox-group-container.vertical .doehlerCheckbox {
  margin: 0.5rem 0;
  padding: 0;
}

.checkbox-group-container.vertical .doehlerCheckbox:first-child {
  margin-top: 0;
}

.checkbox-group-container.vertical .doehlerCheckbox:last-child {
  margin-bottom: 0;
}

.checkbox-group-container.vertical.semiCompact .doehlerCheckbox {
  margin: 0.5rem 0;
  padding: 0;
}

/* RADIO BUTTONS */
.doehlerRadioButtonGroup {
  display: block;
}

.doehlerRadioButtonGroup .withYSpacing {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.doehlerRadioButtonGroup .mat-radio-button:hover .mat-radio-outer-circle,
.doehlerRadioButtonGroup .mat-radio-button.hoverable .mat-radio-outer-circle {
  border-color: #007ae7;
}

.doehlerRadioButtonGroup .mat-radio-button:hover .mat-radio-label-content,
.doehlerRadioButtonGroup .mat-radio-button.hoverable .mat-radio-label-content {
  color: #007ae7;
}

.doehlerRadioButtonGroup .cdk-focused .mat-radio-ripple {
  opacity: 0 !important;
}

.doehlerRadioButtonGroup .mat-radio-button {
  display: inline-block;
  margin: 0.5rem 2rem 0.5rem 0;
}

.doehlerRadioButtonGroup.vertical {
  margin-top: 0;
}

.doehlerRadioButtonGroup.vertical .mat-radio-button {
  margin: 1rem 0;
  padding-right: 0;
  display: block;
}

.doehlerRadioButtonGroup.vertical .mat-radio-button:first-child {
  margin-top: 0;
}

.doehlerRadioButtonGroup.vertical .mat-radio-button:last-child {
  margin-bottom: 0;
}

.doehlerRadioButtonGroup .mat-radio-disabled .mat-radio-label {
  cursor: not-allowed;
}

.doehlerRadioButtonGroup .mat-radio-disabled .mat-radio-outer-circle {
  border-color: #c8cbd4 !important;
}

.doehlerRadioButtonGroup .mat-radio-disabled:hover .mat-radio-outer-circle, .doehlerRadioButtonGroup .mat-radio-disabled.hoverable .mat-radio-outer-circle {
  border-color: #c8cbd4;
}

.doehlerRadioButtonGroup .mat-radio-disabled:hover .mat-radio-label-content, .doehlerRadioButtonGroup .mat-radio-disabled.hoverable .mat-radio-label-content {
  color: #c8cbd4 !important;
  -webkit-text-fill-color: #c8cbd4;
  opacity: 1;
}

.doehlerRadioButtonGroup .mat-radio-disabled .mat-radio-outer-circle {
  border-color: #c8cbd4;
}

.doehlerRadioButtonGroup .mat-radio-disabled .mat-radio-label-content {
  color: #c8cbd4 !important;
  -webkit-text-fill-color: #c8cbd4;
  opacity: 1;
}

.doehlerRadioButtonGroup .mat-radio-container,
.doehlerRadioButtonGroup .mat-radio-outer-circle,
.doehlerRadioButtonGroup .mat-radio-inner-circle {
  width: 1.875rem;
  height: 1.875rem;
}

.doehlerRadioButtonGroup .mat-radio-container,
.doehlerRadioButtonGroup .mat-radio-outer-circle {
  border-width: 1px;
}

.doehlerRadioButtonGroup .mat-radio-outer-circle {
  background: #ffffff;
}

.doehlerRadioButtonGroup .mat-radio-inner-circle {
  transform: scale(0);
  background: #ffffff;
}

.doehlerRadioButtonGroup .mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.66);
}

.doehlerRadioButtonGroup .mat-radio-button .mat-radio-ripple {
  left: calc(50% - 21px);
  top: calc(50% - 21px);
  height: 42px;
  width: 42px;
}

.doehlerRadioButtonGroup .mat-radio-button .mat-radio-ripple .mat-ripple-element {
  background-color: rgba(0, 122, 231, 0.2);
}

.doehlerRadioButtonGroup .mat-radio-label-content {
  padding-left: 1rem;
  font-size: 1rem;
  line-height: 110%;
  color: #9598a1;
}

.doehlerRadioButtonGroup .mat-radio-container:hover .mat-radio-persistent-ripple {
  opacity: 0 !important;
}

/* SLIDER TOGGLE */
.doehlerToggle.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff;
}

.doehlerToggle.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #0074de;
}

.doehlerToggle.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(26px, 0, 0);
}

.doehlerToggle.mat-slide-toggle.mat-checked .mat-slide-toggle-content {
  color: #0074de;
}

.doehlerToggle .mat-slide-toggle-content {
  line-height: 2rem;
  font-size: 1rem;
  color: #9598a1;
}

.doehlerToggle .mat-slide-toggle-bar {
  margin-right: 1rem;
  height: 2rem;
  width: 3.75rem;
  border-radius: 1rem;
  background-color: #c8cbd4;
}

.doehlerToggle .mat-slide-toggle-thumb-container {
  width: 1.25rem;
  height: 1.25rem;
  top: 6px;
  left: 6px;
}

.doehlerToggle .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  width: 1.25rem;
  height: 1.25rem;
  box-shadow: none;
}

.doehlerToggle.mat-disabled {
  opacity: 1 !important;
}

.doehlerToggle.mat-disabled .mat-slide-toggle-bar {
  opacity: 0.37;
}

.doehlerToggle.mat-disabled .mat-slide-toggle-content {
  color: #c8cbd4;
  -webkit-text-fill-color: #c8cbd4;
  opacity: 1;
}

.doehlerToggle.mat-disabled .mat-slide-toggle-label {
  cursor: not-allowed !important;
}

.defaultFormField .mat-form-field-subscript-wrapper {
  margin: 0;
  padding: 0.25rem !important;
}

.defaultFormField .mat-date-range-input .mat-date-range-input-container {
  align-items: unset;
  padding-right: 2rem;
}

.defaultFormField .mat-date-range-input .mat-date-range-input-container .mat-date-range-input-start-wrapper {
  flex: 1;
  min-width: calc(50% - 15px);
}

.defaultFormField .mat-date-range-input .mat-date-range-input-container .mat-date-range-input-separator {
  flex: 1;
  min-width: 30px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.defaultFormField .mat-date-range-input .mat-date-range-input-container .mat-date-range-input-end-wrapper {
  flex: 1;
  min-width: calc(50% - 15px);
  text-align: end;
}

.defaultFormField.mat-form-field-disabled .mat-date-range-input-inner,
.defaultFormField.mat-form-field-disabled .mat-button-disabled {
  cursor: not-allowed;
}

.mat-datepicker-content-touch .mat-datepicker-content-container.mat-datepicker-content-container-with-actions {
  max-width: 565px;
  max-height: 690px;
}

.mat-datepicker-content .mat-datepicker-content-container {
  min-width: 320px !important;
}

@media (min-width: 48rem) {
  .mat-datepicker-content .mat-datepicker-content-container {
    min-width: 360px !important;
  }
}
.mat-datepicker-content .mat-datepicker-content-container .mat-calendar {
  width: 100%;
  height: auto;
}

.mat-datepicker-content .mat-datepicker-content-container .mat-calendar .mat-calendar-content button.mat-calendar-body-cell {
  padding: 0;
  font-size: 0.875rem;
}

.mat-datepicker-content .mat-datepicker-content-container .mat-calendar .mat-calendar-content button.mat-calendar-body-cell:hover {
  background: inherit;
}

.mat-datepicker-content .mat-datepicker-content-container .mat-calendar .mat-calendar-content button.mat-calendar-body-cell .mat-calendar-body-today {
  border: none;
  background: transparent;
  color: #0074de;
}

.mat-datepicker-content .mat-datepicker-content-container .mat-calendar .mat-calendar-content button.mat-calendar-body-cell .mat-calendar-body-selected {
  border-color: #0074de;
  background: #0074de;
  color: #ffffff;
}

.mat-datepicker-content .mat-datepicker-content-container .mat-calendar .mat-calendar-content button.mat-calendar-body-cell.mat-calendar-body-in-range:before {
  background: rgba(0, 122, 231, 0.08);
}

.mat-datepicker-content .mat-datepicker-content-container .mat-calendar .mat-calendar-content button.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #0074de;
  background: transparent;
}

.mat-datepicker-content .mat-datepicker-content-container .mat-datepicker-actions {
  padding: 0 1rem;
  background: #ffffff;
  border-radius: 0 0 0.25rem 0.25rem;
}

@media (min-width: 48rem) {
  .mat-datepicker-content .mat-datepicker-content-container .mat-datepicker-actions {
    padding-top: 0.5rem;
    padding-bottom: 1.25rem;
  }
}
.mat-select-panel {
  box-shadow: 0px 20px 75px rgba(0, 0, 0, 0.1) !important;
  max-height: 275px !important;
}

@media (min-width: 20rem) {
  .mat-select-panel {
    max-height: 330px !important;
  }
}
@media (min-width: 20rem) and (min-width: 48rem) {
  .mat-select-panel {
    max-height: 420px !important;
  }
}
.mat-select-panel .mat-option {
  color: #00233e !important;
}

.mat-select-panel .mat-option:hover:not(.mat-option-disabled), .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple), .mat-select-panel .mat-option.mat-active {
  background: rgba(0, 35, 62, 0.06) !important;
}

.mat-select-panel .mat-option .mat-option-ripple {
  opacity: 0.1 !important;
}

.mat-error {
  color: #ff256f;
  line-height: 1.2;
}

.filter-chip-list-wrapper,
.mat-chip-list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: -4px;
}

/* Filter Chips, Pills, etc. */
.mat-chip-list .filterChip,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip,
.filter-chip-list .filterChip,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip,
* .filterChip,
* .mat-chip-list-wrapper > mat-chip.mat-chip,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip {
  position: relative;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 0.25rem;
  margin: 0.25rem;
  padding: 0.375rem 0.75rem;
  font-family: "TheSansBold", Arial, sans-serif;
  font-size: 0.75rem;
  line-height: 130%;
  color: #4a4c50;
  text-align: center;
  border: 2px solid #e3e5eb;
  transition: all 250ms ease-in-out;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 275px;
}

.mat-chip-list .filterChip .mat-chip-ripple,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip .mat-chip-ripple,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip .mat-chip-ripple,
.filter-chip-list .filterChip .mat-chip-ripple,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip .mat-chip-ripple,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip .mat-chip-ripple,
* .filterChip .mat-chip-ripple,
* .mat-chip-list-wrapper > mat-chip.mat-chip .mat-chip-ripple,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip .mat-chip-ripple {
  display: none;
}

.mat-chip-list .filterChip:focus:after,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip:focus:after,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip:focus:after,
.filter-chip-list .filterChip:focus:after,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip:focus:after,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip:focus:after,
* .filterChip:focus:after,
* .mat-chip-list-wrapper > mat-chip.mat-chip:focus:after,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip:focus:after {
  opacity: 0;
}

.mat-chip-list .filterChip.no-hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.no-hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.no-hover,
.filter-chip-list .filterChip.no-hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.no-hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.no-hover,
* .filterChip.no-hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.no-hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.no-hover {
  pointer-events: none;
}

.mat-chip-list .filterChip:hover, .mat-chip-list .filterChip.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.hovered,
.filter-chip-list .filterChip:hover,
.filter-chip-list .filterChip.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.hovered,
* .filterChip:hover,
* .filterChip.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.hovered {
  cursor: pointer;
  background-color: #ffffff;
  border-color: #007ae7;
  color: #007ae7;
}

.mat-chip-list .filterChip:hover:after, .mat-chip-list .filterChip.hovered:after,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip:hover:after,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.hovered:after,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip:hover:after,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.hovered:after,
.filter-chip-list .filterChip:hover:after,
.filter-chip-list .filterChip.hovered:after,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip:hover:after,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.hovered:after,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip:hover:after,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.hovered:after,
* .filterChip:hover:after,
* .filterChip.hovered:after,
* .mat-chip-list-wrapper > mat-chip.mat-chip:hover:after,
* .mat-chip-list-wrapper > mat-chip.mat-chip.hovered:after,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip:hover:after,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.hovered:after {
  opacity: 0;
}

.mat-chip-list .filterChip.active, .mat-chip-list .filterChip.selected, .mat-chip-list .filterChip.active.filter, .mat-chip-list .filterChip.selected.filter,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter,
.filter-chip-list .filterChip.active,
.filter-chip-list .filterChip.selected,
.filter-chip-list .filterChip.active.filter,
.filter-chip-list .filterChip.selected.filter,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter,
* .filterChip.active,
* .filterChip.selected,
* .filterChip.active.filter,
* .filterChip.selected.filter,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter {
  color: #4a4c50;
  border-color: #e3e5eb;
  background-color: #e3e5eb;
}

.mat-chip-list .filterChip.active.mat-chip-disabled, .mat-chip-list .filterChip.active.mat-chip-disabled:hover, .mat-chip-list .filterChip.active.mat-chip-disabled.hovered, .mat-chip-list .filterChip.selected.mat-chip-disabled, .mat-chip-list .filterChip.selected.mat-chip-disabled:hover, .mat-chip-list .filterChip.selected.mat-chip-disabled.hovered, .mat-chip-list .filterChip.active.filter.mat-chip-disabled, .mat-chip-list .filterChip.active.filter.mat-chip-disabled:hover, .mat-chip-list .filterChip.active.filter.mat-chip-disabled.hovered, .mat-chip-list .filterChip.selected.filter.mat-chip-disabled, .mat-chip-list .filterChip.selected.filter.mat-chip-disabled:hover, .mat-chip-list .filterChip.selected.filter.mat-chip-disabled.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.mat-chip-disabled,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.mat-chip-disabled:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.mat-chip-disabled.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.mat-chip-disabled,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.mat-chip-disabled:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.mat-chip-disabled.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.mat-chip-disabled,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.mat-chip-disabled:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.mat-chip-disabled.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.mat-chip-disabled,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.mat-chip-disabled:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.mat-chip-disabled.hovered,
.filter-chip-list .filterChip.active.mat-chip-disabled,
.filter-chip-list .filterChip.active.mat-chip-disabled:hover,
.filter-chip-list .filterChip.active.mat-chip-disabled.hovered,
.filter-chip-list .filterChip.selected.mat-chip-disabled,
.filter-chip-list .filterChip.selected.mat-chip-disabled:hover,
.filter-chip-list .filterChip.selected.mat-chip-disabled.hovered,
.filter-chip-list .filterChip.active.filter.mat-chip-disabled,
.filter-chip-list .filterChip.active.filter.mat-chip-disabled:hover,
.filter-chip-list .filterChip.active.filter.mat-chip-disabled.hovered,
.filter-chip-list .filterChip.selected.filter.mat-chip-disabled,
.filter-chip-list .filterChip.selected.filter.mat-chip-disabled:hover,
.filter-chip-list .filterChip.selected.filter.mat-chip-disabled.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.mat-chip-disabled,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.mat-chip-disabled:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.mat-chip-disabled.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.mat-chip-disabled,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.mat-chip-disabled:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.mat-chip-disabled.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.mat-chip-disabled,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.mat-chip-disabled:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.mat-chip-disabled.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.mat-chip-disabled,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.mat-chip-disabled:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.mat-chip-disabled.hovered,
* .filterChip.active.mat-chip-disabled,
* .filterChip.active.mat-chip-disabled:hover,
* .filterChip.active.mat-chip-disabled.hovered,
* .filterChip.selected.mat-chip-disabled,
* .filterChip.selected.mat-chip-disabled:hover,
* .filterChip.selected.mat-chip-disabled.hovered,
* .filterChip.active.filter.mat-chip-disabled,
* .filterChip.active.filter.mat-chip-disabled:hover,
* .filterChip.active.filter.mat-chip-disabled.hovered,
* .filterChip.selected.filter.mat-chip-disabled,
* .filterChip.selected.filter.mat-chip-disabled:hover,
* .filterChip.selected.filter.mat-chip-disabled.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.mat-chip-disabled,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.mat-chip-disabled:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.mat-chip-disabled.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.mat-chip-disabled,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.mat-chip-disabled:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.mat-chip-disabled.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.mat-chip-disabled,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.mat-chip-disabled:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.mat-chip-disabled.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.mat-chip-disabled,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.mat-chip-disabled:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.mat-chip-disabled.hovered {
  cursor: not-allowed;
  opacity: 1 !important;
  color: #c8cbd4;
  border-color: #f9fafc;
  background-color: #f9fafc;
}

.mat-chip-list .filterChip.active:hover, .mat-chip-list .filterChip.active.hovered, .mat-chip-list .filterChip.selected:hover, .mat-chip-list .filterChip.selected.hovered, .mat-chip-list .filterChip.active.filter:hover, .mat-chip-list .filterChip.active.filter.hovered, .mat-chip-list .filterChip.selected.filter:hover, .mat-chip-list .filterChip.selected.filter.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.hovered,
.filter-chip-list .filterChip.active:hover,
.filter-chip-list .filterChip.active.hovered,
.filter-chip-list .filterChip.selected:hover,
.filter-chip-list .filterChip.selected.hovered,
.filter-chip-list .filterChip.active.filter:hover,
.filter-chip-list .filterChip.active.filter.hovered,
.filter-chip-list .filterChip.selected.filter:hover,
.filter-chip-list .filterChip.selected.filter.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.hovered,
* .filterChip.active:hover,
* .filterChip.active.hovered,
* .filterChip.selected:hover,
* .filterChip.selected.hovered,
* .filterChip.active.filter:hover,
* .filterChip.active.filter.hovered,
* .filterChip.selected.filter:hover,
* .filterChip.selected.filter.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.hovered {
  cursor: pointer;
  color: #4a4c50;
  background-color: #c8cbd4;
  border-color: #c8cbd4;
}

.mat-chip-list .filterChip.filter,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.filter,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.filter,
.filter-chip-list .filterChip.filter,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.filter,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.filter,
* .filterChip.filter,
* .mat-chip-list-wrapper > mat-chip.mat-chip.filter,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.filter {
  border-color: #c8cbd4;
  background-color: #c8cbd4;
}

.mat-chip-list .filterChip.filter:hover, .mat-chip-list .filterChip.filter.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.filter:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.filter.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.filter:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.filter.hovered,
.filter-chip-list .filterChip.filter:hover,
.filter-chip-list .filterChip.filter.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.filter:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.filter.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.filter:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.filter.hovered,
* .filterChip.filter:hover,
* .filterChip.filter.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip.filter:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.filter.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.filter:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.filter.hovered {
  cursor: pointer;
  color: #4a4c50;
  background-color: #f9fafc;
  border-color: #f9fafc;
}

.mat-chip-list .filterChip.mat-chip-disabled, .mat-chip-list .filterChip.mat-chip-disabled:hover, .mat-chip-list .filterChip.mat-chip-disabled.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled.hovered,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.mat-chip-disabled,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.mat-chip-disabled:hover,
.mat-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.mat-chip-disabled.hovered,
.filter-chip-list .filterChip.mat-chip-disabled,
.filter-chip-list .filterChip.mat-chip-disabled:hover,
.filter-chip-list .filterChip.mat-chip-disabled.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled.hovered,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.mat-chip-disabled,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.mat-chip-disabled:hover,
.filter-chip-list .mat-chip-list-wrapper > mat-chip.mat-standard-chip.mat-chip-disabled.hovered,
* .filterChip.mat-chip-disabled,
* .filterChip.mat-chip-disabled:hover,
* .filterChip.mat-chip-disabled.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled,
* .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:hover,
* .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled.hovered,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.mat-chip-disabled,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.mat-chip-disabled:hover,
* .mat-chip-list-wrapper > mat-chip.mat-standard-chip.mat-chip-disabled.hovered {
  cursor: not-allowed;
  opacity: 1 !important;
  color: #c8cbd4;
  border-color: #e3e5eb;
}

.mat-chip-list.withIcon .filterChip,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip,
.filter-chip-list.withIcon .filterChip,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip,
*.withIcon .filterChip,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip {
  padding: 0.375rem 2rem 0.375rem 0.75rem;
}

.mat-chip-list.withIcon .filterChip:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:after,
.filter-chip-list.withIcon .filterChip:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:after,
*.withIcon .filterChip:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:after {
  content: "";
  position: absolute;
  top: 0 !important;
  left: unset !important;
  right: 0.5rem !important;
  bottom: unset !important;
  width: 1rem;
  height: 100%;
  background-color: #4a4c50;
  mask-image: url(/shared-assets/icons/basic/add_plus_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  opacity: 1 !important;
  transition: all 250ms ease-in-out;
}

.mat-chip-list.withIcon .filterChip.active.mat-chip-disabled, .mat-chip-list.withIcon .filterChip.active.mat-chip-disabled:hover, .mat-chip-list.withIcon .filterChip.active.mat-chip-disabled.hovered, .mat-chip-list.withIcon .filterChip.selected.mat-chip-disabled, .mat-chip-list.withIcon .filterChip.selected.mat-chip-disabled:hover, .mat-chip-list.withIcon .filterChip.selected.mat-chip-disabled.hovered, .mat-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled, .mat-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled:hover, .mat-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled.hovered, .mat-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled, .mat-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled:hover, .mat-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled.hovered,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:hover,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled.hovered,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:hover,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled.hovered,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:hover,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled.hovered,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:hover,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled.hovered,
.filter-chip-list.withIcon .filterChip.active.mat-chip-disabled,
.filter-chip-list.withIcon .filterChip.active.mat-chip-disabled:hover,
.filter-chip-list.withIcon .filterChip.active.mat-chip-disabled.hovered,
.filter-chip-list.withIcon .filterChip.selected.mat-chip-disabled,
.filter-chip-list.withIcon .filterChip.selected.mat-chip-disabled:hover,
.filter-chip-list.withIcon .filterChip.selected.mat-chip-disabled.hovered,
.filter-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled,
.filter-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled:hover,
.filter-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled.hovered,
.filter-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled,
.filter-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled:hover,
.filter-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled.hovered,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:hover,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled.hovered,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:hover,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled.hovered,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:hover,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled.hovered,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:hover,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled.hovered,
*.withIcon .filterChip.active.mat-chip-disabled,
*.withIcon .filterChip.active.mat-chip-disabled:hover,
*.withIcon .filterChip.active.mat-chip-disabled.hovered,
*.withIcon .filterChip.selected.mat-chip-disabled,
*.withIcon .filterChip.selected.mat-chip-disabled:hover,
*.withIcon .filterChip.selected.mat-chip-disabled.hovered,
*.withIcon .filterChip.active.filter.mat-chip-disabled,
*.withIcon .filterChip.active.filter.mat-chip-disabled:hover,
*.withIcon .filterChip.active.filter.mat-chip-disabled.hovered,
*.withIcon .filterChip.selected.filter.mat-chip-disabled,
*.withIcon .filterChip.selected.filter.mat-chip-disabled:hover,
*.withIcon .filterChip.selected.filter.mat-chip-disabled.hovered,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:hover,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled.hovered,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:hover,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled.hovered,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:hover,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled.hovered,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:hover,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled.hovered {
  cursor: not-allowed;
  opacity: 1 !important;
  color: #c8cbd4;
  border-color: #f9fafc;
  background-color: #f9fafc;
}

.mat-chip-list.withIcon .filterChip.active.mat-chip-disabled:after, .mat-chip-list.withIcon .filterChip.active.mat-chip-disabled:hover:after, .mat-chip-list.withIcon .filterChip.active.mat-chip-disabled.hovered:after, .mat-chip-list.withIcon .filterChip.selected.mat-chip-disabled:after, .mat-chip-list.withIcon .filterChip.selected.mat-chip-disabled:hover:after, .mat-chip-list.withIcon .filterChip.selected.mat-chip-disabled.hovered:after, .mat-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled:after, .mat-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled:hover:after, .mat-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled.hovered:after, .mat-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled:after, .mat-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled:hover:after, .mat-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled.hovered:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:hover:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled.hovered:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:hover:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled.hovered:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:hover:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled.hovered:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:hover:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled.hovered:after,
.filter-chip-list.withIcon .filterChip.active.mat-chip-disabled:after,
.filter-chip-list.withIcon .filterChip.active.mat-chip-disabled:hover:after,
.filter-chip-list.withIcon .filterChip.active.mat-chip-disabled.hovered:after,
.filter-chip-list.withIcon .filterChip.selected.mat-chip-disabled:after,
.filter-chip-list.withIcon .filterChip.selected.mat-chip-disabled:hover:after,
.filter-chip-list.withIcon .filterChip.selected.mat-chip-disabled.hovered:after,
.filter-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled:after,
.filter-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled:hover:after,
.filter-chip-list.withIcon .filterChip.active.filter.mat-chip-disabled.hovered:after,
.filter-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled:after,
.filter-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled:hover:after,
.filter-chip-list.withIcon .filterChip.selected.filter.mat-chip-disabled.hovered:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:hover:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled.hovered:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:hover:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled.hovered:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:hover:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled.hovered:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:hover:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled.hovered:after,
*.withIcon .filterChip.active.mat-chip-disabled:after,
*.withIcon .filterChip.active.mat-chip-disabled:hover:after,
*.withIcon .filterChip.active.mat-chip-disabled.hovered:after,
*.withIcon .filterChip.selected.mat-chip-disabled:after,
*.withIcon .filterChip.selected.mat-chip-disabled:hover:after,
*.withIcon .filterChip.selected.mat-chip-disabled.hovered:after,
*.withIcon .filterChip.active.filter.mat-chip-disabled:after,
*.withIcon .filterChip.active.filter.mat-chip-disabled:hover:after,
*.withIcon .filterChip.active.filter.mat-chip-disabled.hovered:after,
*.withIcon .filterChip.selected.filter.mat-chip-disabled:after,
*.withIcon .filterChip.selected.filter.mat-chip-disabled:hover:after,
*.withIcon .filterChip.selected.filter.mat-chip-disabled.hovered:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled:hover:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.mat-chip-disabled.hovered:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled:hover:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.mat-chip-disabled.hovered:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled:hover:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.mat-chip-disabled.hovered:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled:hover:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.mat-chip-disabled.hovered:after {
  opacity: 1;
  background-color: #c8cbd4;
}

.mat-chip-list.withIcon .filterChip.active:after, .mat-chip-list.withIcon .filterChip.selected:after, .mat-chip-list.withIcon .filterChip.active.filter:after, .mat-chip-list.withIcon .filterChip.selected.filter:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter:after,
.filter-chip-list.withIcon .filterChip.active:after,
.filter-chip-list.withIcon .filterChip.selected:after,
.filter-chip-list.withIcon .filterChip.active.filter:after,
.filter-chip-list.withIcon .filterChip.selected.filter:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter:after,
*.withIcon .filterChip.active:after,
*.withIcon .filterChip.selected:after,
*.withIcon .filterChip.active.filter:after,
*.withIcon .filterChip.selected.filter:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter:after {
  content: "";
  position: absolute;
  top: 0 !important;
  left: unset !important;
  right: 0.5rem !important;
  bottom: unset !important;
  width: 1rem;
  height: 100%;
  background-color: #4a4c50;
  mask-image: url(/shared-assets/icons/basic/close_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  opacity: 1 !important;
  transition: all 250ms ease-in-out;
}

.mat-chip-list.withIcon .filterChip:hover, .mat-chip-list.withIcon .filterChip.hovered,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered,
.filter-chip-list.withIcon .filterChip:hover,
.filter-chip-list.withIcon .filterChip.hovered,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered,
*.withIcon .filterChip:hover,
*.withIcon .filterChip.hovered,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered {
  padding: 0.375rem 2rem 0.375rem 0.75rem;
}

.mat-chip-list.withIcon .filterChip:hover:after, .mat-chip-list.withIcon .filterChip.hovered:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered:after,
.filter-chip-list.withIcon .filterChip:hover:after,
.filter-chip-list.withIcon .filterChip.hovered:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered:after,
*.withIcon .filterChip:hover:after,
*.withIcon .filterChip.hovered:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered:after {
  opacity: 1;
  background-color: #007ae7;
}

.mat-chip-list.withIcon .filterChip:hover.active:after, .mat-chip-list.withIcon .filterChip:hover.selected:after, .mat-chip-list.withIcon .filterChip:hover.active.filter:after, .mat-chip-list.withIcon .filterChip:hover.selected.filter:after, .mat-chip-list.withIcon .filterChip.hovered.active:after, .mat-chip-list.withIcon .filterChip.hovered.selected:after, .mat-chip-list.withIcon .filterChip.hovered.active.filter:after, .mat-chip-list.withIcon .filterChip.hovered.selected.filter:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.active:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.selected:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.active.filter:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.selected.filter:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.active:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.selected:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.active.filter:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.selected.filter:after,
.filter-chip-list.withIcon .filterChip:hover.active:after,
.filter-chip-list.withIcon .filterChip:hover.selected:after,
.filter-chip-list.withIcon .filterChip:hover.active.filter:after,
.filter-chip-list.withIcon .filterChip:hover.selected.filter:after,
.filter-chip-list.withIcon .filterChip.hovered.active:after,
.filter-chip-list.withIcon .filterChip.hovered.selected:after,
.filter-chip-list.withIcon .filterChip.hovered.active.filter:after,
.filter-chip-list.withIcon .filterChip.hovered.selected.filter:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.active:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.selected:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.active.filter:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.selected.filter:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.active:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.selected:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.active.filter:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.selected.filter:after,
*.withIcon .filterChip:hover.active:after,
*.withIcon .filterChip:hover.selected:after,
*.withIcon .filterChip:hover.active.filter:after,
*.withIcon .filterChip:hover.selected.filter:after,
*.withIcon .filterChip.hovered.active:after,
*.withIcon .filterChip.hovered.selected:after,
*.withIcon .filterChip.hovered.active.filter:after,
*.withIcon .filterChip.hovered.selected.filter:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.active:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.selected:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.active.filter:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.selected.filter:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.active:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.selected:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.active.filter:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.selected.filter:after {
  opacity: 1;
  background-color: #4a4c50 !important;
}

.mat-chip-list.withIcon .filterChip:hover.filter:after, .mat-chip-list.withIcon .filterChip.hovered.filter:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.filter:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.filter:after,
.filter-chip-list.withIcon .filterChip:hover.filter:after,
.filter-chip-list.withIcon .filterChip.hovered.filter:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.filter:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.filter:after,
*.withIcon .filterChip:hover.filter:after,
*.withIcon .filterChip.hovered.filter:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip:hover.filter:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.hovered.filter:after {
  opacity: 1;
  background-color: #4a4c50;
}

.mat-chip-list.withIcon .filterChip.mat-chip-disabled, .mat-chip-list.withIcon .filterChip.mat-chip-disabled:hover, .mat-chip-list.withIcon .filterChip.mat-chip-disabled.hovered,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:hover,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled.hovered,
.filter-chip-list.withIcon .filterChip.mat-chip-disabled,
.filter-chip-list.withIcon .filterChip.mat-chip-disabled:hover,
.filter-chip-list.withIcon .filterChip.mat-chip-disabled.hovered,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:hover,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled.hovered,
*.withIcon .filterChip.mat-chip-disabled,
*.withIcon .filterChip.mat-chip-disabled:hover,
*.withIcon .filterChip.mat-chip-disabled.hovered,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:hover,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled.hovered {
  cursor: not-allowed;
  opacity: 1 !important;
  color: #c8cbd4;
  border-color: #e3e5eb;
}

.mat-chip-list.withIcon .filterChip.mat-chip-disabled:after, .mat-chip-list.withIcon .filterChip.mat-chip-disabled:hover:after, .mat-chip-list.withIcon .filterChip.mat-chip-disabled.hovered:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:hover:after,
.mat-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled.hovered:after,
.filter-chip-list.withIcon .filterChip.mat-chip-disabled:after,
.filter-chip-list.withIcon .filterChip.mat-chip-disabled:hover:after,
.filter-chip-list.withIcon .filterChip.mat-chip-disabled.hovered:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:hover:after,
.filter-chip-list.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled.hovered:after,
*.withIcon .filterChip.mat-chip-disabled:after,
*.withIcon .filterChip.mat-chip-disabled:hover:after,
*.withIcon .filterChip.mat-chip-disabled.hovered:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled:hover:after,
*.withIcon .mat-chip-list-wrapper > mat-chip.mat-chip.mat-chip-disabled.hovered:after {
  opacity: 1;
  background-color: #c8cbd4 !important;
}

.mat-chip-list.vertical .filterChip,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip,
.filter-chip-list.vertical .filterChip,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip,
*.vertical .filterChip,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip {
  /* margin: 0.5rem 0.25rem; */
}

.mat-chip-list.vertical .filterChip div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon,
.filter-chip-list.vertical .filterChip div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon,
*.vertical .filterChip div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon {
  float: left;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  transition: all 250ms ease-in-out;
  background-color: #1d1d1f;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
}

.mat-chip-list.vertical .filterChip div.icon.basic,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.basic,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.basic,
.filter-chip-list.vertical .filterChip div.icon.basic,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.basic,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.basic,
*.vertical .filterChip div.icon.basic,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.basic,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.basic {
  -webkit-mask-image: url("/shared-assets/icons/basic/lock_closed.svg");
  mask-image: url("/shared-assets/icons/basic/lock_closed.svg");
}

.mat-chip-list.vertical .filterChip div.icon.quotes,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.quotes,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.quotes,
.filter-chip-list.vertical .filterChip div.icon.quotes,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.quotes,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.quotes,
*.vertical .filterChip div.icon.quotes,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.quotes,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.quotes {
  -webkit-mask-image: url("/shared-assets/icons/new/offers.svg");
  mask-image: url("/shared-assets/icons/new/offers.svg");
}

.mat-chip-list.vertical .filterChip div.icon.contracts,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.contracts,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.contracts,
.filter-chip-list.vertical .filterChip div.icon.contracts,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.contracts,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.contracts,
*.vertical .filterChip div.icon.contracts,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.contracts,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.contracts {
  -webkit-mask-image: url("/shared-assets/icons/new/contracts.svg");
  mask-image: url("/shared-assets/icons/new/contracts.svg");
}

.mat-chip-list.vertical .filterChip div.icon.orders,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.orders,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.orders,
.filter-chip-list.vertical .filterChip div.icon.orders,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.orders,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.orders,
*.vertical .filterChip div.icon.orders,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.orders,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.orders {
  -webkit-mask-image: url("/shared-assets/icons/new/orders.svg");
  mask-image: url("/shared-assets/icons/new/orders.svg");
}

.mat-chip-list.vertical .filterChip div.icon.deliveries,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.deliveries,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.deliveries,
.filter-chip-list.vertical .filterChip div.icon.deliveries,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.deliveries,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.deliveries,
*.vertical .filterChip div.icon.deliveries,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.deliveries,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.deliveries {
  -webkit-mask-image: url("/shared-assets/icons/new/deliveries.svg");
  mask-image: url("/shared-assets/icons/new/deliveries.svg");
}

.mat-chip-list.vertical .filterChip div.icon.invoices,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.invoices,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.invoices,
.filter-chip-list.vertical .filterChip div.icon.invoices,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.invoices,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.invoices,
*.vertical .filterChip div.icon.invoices,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.invoices,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.invoices {
  -webkit-mask-image: url("/shared-assets/icons/new/invoices.svg");
  mask-image: url("/shared-assets/icons/new/invoices.svg");
}

.mat-chip-list.vertical .filterChip div.icon.complaints,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.complaints,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.complaints,
.filter-chip-list.vertical .filterChip div.icon.complaints,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.complaints,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.complaints,
*.vertical .filterChip div.icon.complaints,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.complaints,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.complaints {
  -webkit-mask-image: url("/shared-assets/icons/basic/speech_bubble.svg.svg");
  mask-image: url("/shared-assets/icons/basic/speech_bubble.svg");
}

.mat-chip-list.vertical .filterChip div.icon.empties,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.empties,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.empties,
.filter-chip-list.vertical .filterChip div.icon.empties,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.empties,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.empties,
*.vertical .filterChip div.icon.empties,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip div.icon.empties,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip div.icon.empties {
  -webkit-mask-image: url("/shared-assets/icons/new/empties.svg");
  mask-image: url("/shared-assets/icons/new/empties.svg");
}

.mat-chip-list.vertical .filterChip:hover div.icon, .mat-chip-list.vertical .filterChip.hovered div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip:hover div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.hovered div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip:hover div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.hovered div.icon,
.filter-chip-list.vertical .filterChip:hover div.icon,
.filter-chip-list.vertical .filterChip.hovered div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip:hover div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.hovered div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip:hover div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.hovered div.icon,
*.vertical .filterChip:hover div.icon,
*.vertical .filterChip.hovered div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip:hover div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.hovered div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip:hover div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.hovered div.icon {
  background-color: #007ae7;
}

.mat-chip-list.vertical .filterChip.active:hover div.icon, .mat-chip-list.vertical .filterChip.active.hovered div.icon, .mat-chip-list.vertical .filterChip.selected:hover div.icon, .mat-chip-list.vertical .filterChip.selected.hovered div.icon, .mat-chip-list.vertical .filterChip.active.filter:hover div.icon, .mat-chip-list.vertical .filterChip.active.filter.hovered div.icon, .mat-chip-list.vertical .filterChip.selected.filter:hover div.icon, .mat-chip-list.vertical .filterChip.selected.filter.hovered div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active:hover div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active.hovered div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected:hover div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected.hovered div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter:hover div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.hovered div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter:hover div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.hovered div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active:hover div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.hovered div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected:hover div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.hovered div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter:hover div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.hovered div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter:hover div.icon,
.mat-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.hovered div.icon,
.filter-chip-list.vertical .filterChip.active:hover div.icon,
.filter-chip-list.vertical .filterChip.active.hovered div.icon,
.filter-chip-list.vertical .filterChip.selected:hover div.icon,
.filter-chip-list.vertical .filterChip.selected.hovered div.icon,
.filter-chip-list.vertical .filterChip.active.filter:hover div.icon,
.filter-chip-list.vertical .filterChip.active.filter.hovered div.icon,
.filter-chip-list.vertical .filterChip.selected.filter:hover div.icon,
.filter-chip-list.vertical .filterChip.selected.filter.hovered div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active:hover div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active.hovered div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected:hover div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected.hovered div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter:hover div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.hovered div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter:hover div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.hovered div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active:hover div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.hovered div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected:hover div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.hovered div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter:hover div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.hovered div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter:hover div.icon,
.filter-chip-list.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.hovered div.icon,
*.vertical .filterChip.active:hover div.icon,
*.vertical .filterChip.active.hovered div.icon,
*.vertical .filterChip.selected:hover div.icon,
*.vertical .filterChip.selected.hovered div.icon,
*.vertical .filterChip.active.filter:hover div.icon,
*.vertical .filterChip.active.filter.hovered div.icon,
*.vertical .filterChip.selected.filter:hover div.icon,
*.vertical .filterChip.selected.filter.hovered div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active:hover div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active.hovered div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected:hover div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected.hovered div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter:hover div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.active.filter.hovered div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter:hover div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-chip.selected.filter.hovered div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active:hover div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.hovered div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected:hover div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.hovered div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter:hover div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.active.filter.hovered div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter:hover div.icon,
*.vertical .mat-chip-list-wrapper > mat-chip.mat-standard-chip.selected.filter.hovered div.icon {
  background-color: #1d1d1f;
}

/* MASTER GRID AS A MIXIN */
.mat-autocomplete-panel {
  position: absolute;
  top: -19px;
}

.mat-autocomplete-panel.search-dropdown {
  background: transparent !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  box-shadow: 0px 5px 4px -1px rgba(255, 255, 255, 0.14), -1px 5px 5px 0px rgba(255, 255, 255, 0.1), 0px 10px 10px 0px rgba(255, 255, 255, 0.08) !important;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
}

.mat-autocomplete-panel.search-dropdown.flat-corner {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 0;
  box-shadow: 0px 5px 4px -1px rgba(255, 255, 255, 0.14), -1px 5px 5px 0px rgba(255, 255, 255, 0.1), 0px 5px 10px 0px rgba(255, 255, 255, 0.08) !important;
}

.mat-autocomplete-panel.search-dropdown.hide {
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.mat-autocomplete-panel .spacing {
  height: 20px;
  background-color: transparent;
}

.mat-autocomplete-panel .matOptionsWrapper {
  max-height: 220px;
  overflow-y: auto;
  background: #00233e;
}

.mat-autocomplete-panel .matOptionsWrapper::-webkit-scrollbar-track,
.mat-autocomplete-panel .matOptionsWrapper ::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.mat-autocomplete-panel .matOptionsWrapper::-webkit-scrollbar,
.mat-autocomplete-panel .matOptionsWrapper ::-webkit-scrollbar {
  width: 10px;
  background-color: #ffffff;
}

.mat-autocomplete-panel .matOptionsWrapper::-webkit-scrollbar-thumb,
.mat-autocomplete-panel .matOptionsWrapper ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c8cbd4;
  border: 2px solid #ffffff;
}

.mat-autocomplete-panel .matOptionsWrapper .mat-option {
  background-color: #00233e;
  color: #ffffff;
  animation: fadeIn 0.5s ease;
}

.mat-autocomplete-panel .matOptionsWrapper .mat-option:hover:not(.mat-option-disabled),
.mat-autocomplete-panel .matOptionsWrapper .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 122, 231, 0.08);
}

header .firstHeaderRow .search-ui,
header .mobileNavigation .search-ui {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

header .firstHeaderRow .search-ui .search-container,
header .mobileNavigation .search-ui .search-container {
  width: 100%;
  margin: 0 1rem;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (min-width: 48rem) {
  header .firstHeaderRow .search-ui .search-container,
header .mobileNavigation .search-ui .search-container {
    width: 84%;
  }
}
header .firstHeaderRow .search-ui .search-container mat-form-field.mat-form-field,
header .mobileNavigation .search-ui .search-container mat-form-field.mat-form-field {
  width: 100%;
  border: 1px solid #ffffff;
  border-radius: 30px;
  height: 40px;
}

header .firstHeaderRow .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper,
header .mobileNavigation .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

header .firstHeaderRow .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-flex,
header .mobileNavigation .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-flex {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

header .firstHeaderRow .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix,
header .mobileNavigation .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

header .firstHeaderRow .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix input.mat-input-element,
header .mobileNavigation .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix input.mat-input-element {
  color: #ffffff !important;
  caret-color: #ffffff !important;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

header .firstHeaderRow .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper,
header .mobileNavigation .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
  top: 0;
  padding: 0;
}

header .firstHeaderRow .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-subscript-wrapper,
header .firstHeaderRow .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-underline,
header .mobileNavigation .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-subscript-wrapper,
header .mobileNavigation .search-ui .search-container mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}

header .firstHeaderRow .search-ui .search-container .mat-form-field-prefix,
header .mobileNavigation .search-ui .search-container .mat-form-field-prefix {
  margin-left: 0.5rem;
  margin-right: 0.25rem;
}

header .firstHeaderRow .search-ui .search-container .mat-form-field-prefix #submitIcon,
header .mobileNavigation .search-ui .search-container .mat-form-field-prefix #submitIcon {
  position: relative;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  background-color: #ffffff;
  mask-image: url(/shared-assets/icons/basic/search.svg);
  mask-size: 80%;
  mask-repeat: no-repeat;
  mask-position: center;
}

header .firstHeaderRow .search-ui .search-container .mat-form-field-suffix,
header .mobileNavigation .search-ui .search-container .mat-form-field-suffix {
  margin-right: 0.5rem;
}

header .firstHeaderRow .search-ui .search-container .mat-form-field-suffix #clearIcon,
header .mobileNavigation .search-ui .search-container .mat-form-field-suffix #clearIcon {
  position: relative;
  cursor: pointer;
  width: 2rem;
  height: 30px;
  background-color: #ffffff;
  mask-image: url(/shared-assets/icons/basic/close_single.svg);
  mask-size: 80%;
  mask-repeat: no-repeat;
  mask-position: center;
}

header .firstHeaderRow .search-ui .search-container .layer,
header .mobileNavigation .search-ui .search-container .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #666666;
  border-radius: 22px;
  align-items: center;
  justify-content: center;
  display: none;
  font-family: "TheSans", Arial, sans-serif;
  cursor: not-allowed;
}

header .firstHeaderRow .search-ui .search-container ::-webkit-input-placeholder,
header .mobileNavigation .search-ui .search-container ::-webkit-input-placeholder {
  opacity: 0.75;
  color: #ffffff;
}

header .firstHeaderRow .search-ui .search-container :-ms-input-placeholder,
header .mobileNavigation .search-ui .search-container :-ms-input-placeholder {
  opacity: 0.75;
  color: #ffffff;
}

header .firstHeaderRow .search-ui .search-container ::placeholder,
header .mobileNavigation .search-ui .search-container ::placeholder {
  opacity: 0.75;
  color: #ffffff;
}

@font-face {
  font-family: TheSans;
  src: url("/shared-assets/fonts/TheSansPlain.eot") format("eot"), url("/shared-assets/fonts/TheSansPlain.woff") format("woff"), url("/shared-assets/fonts/TheSansPlain.woff2") format("woff2"), url("/shared-assets/fonts/TheSansPlain.ttf") format("truetype");
}
@font-face {
  font-family: TheSansBold;
  src: url("/shared-assets/fonts/TheSansBold.eot") format("eot"), url("/shared-assets/fonts/TheSansBold.woff") format("woff"), url("/shared-assets/fonts/TheSansBold.woff2") format("woff2"), url("/shared-assets/fonts/TheSansBold.ttf") format("truetype");
}
@font-face {
  font-family: TheSansLight;
  src: url("/shared-assets/fonts/TheSansLight.eot") format("eot"), url("/shared-assets/fonts/TheSansLight.woff") format("woff"), url("/shared-assets/fonts/TheSansLight.woff2") format("woff2"), url("/shared-assets/fonts/TheSansLight.ttf") format("truetype");
}
/* NEW FONT SIZES */
/* BASIC DISTANCES / SPACINGS */
/* MAIN BRAND COLOURS */
/* before instant change:  
$brand-pale-grey: #f5f5f5;
*/
/* SECONDARY COLOURS*/
/* INTERACTION COLOURS - light background */
/* INTERACTION SECONDARY COLOURS - light background */
/* INTERACTION COLOURS - dark background */
/* ICON COLORS */
/* SIGNAL COLOURS | SYSTEM UI */
/* ASSIGNMENT OF CHART COLOURS */
/* ------------------------------------------------------------------- */
/* SPECIAL COLOURS - not part of the palette */
/* SPECTRUM COLOURS - usable for gradients */
/* BACKGROUND COLOURS */
/* BOX OVERLAYS */
/* BOX SHADOWS */
/* BORDER RADIUS */
.dynamicAccordionWrapper h3 {
  padding-bottom: 0.75rem;
}

/* Applicable only for FAQ Accordion - at the moment */
.faqAccordion .mat-expansion-panel,
.customAccordion .mat-expansion-panel {
  box-shadow: none !important;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid #c8cbd4;
}

.faqAccordion .mat-expansion-panel.mat-expanded,
.customAccordion .mat-expansion-panel.mat-expanded {
  margin: 0;
  height: auto;
}

.faqAccordion .mat-expansion-panel .mat-expansion-panel-header,
.customAccordion .mat-expansion-panel .mat-expansion-panel-header {
  padding-left: 0.25rem !important;
  padding-right: 0 !important;
}

.faqAccordion .mat-expansion-panel .itemHeader,
.customAccordion .mat-expansion-panel .itemHeader {
  box-shadow: none;
  padding: 0.75rem 0.25rem;
  height: auto;
}

.faqAccordion .mat-expansion-panel .itemHeader.open,
.customAccordion .mat-expansion-panel .itemHeader.open {
  border-bottom: none;
}

.faqAccordion .mat-expansion-panel .itemHeader.open .mat-expansion-panel-header-title .title p,
.customAccordion .mat-expansion-panel .itemHeader.open .mat-expansion-panel-header-title .title p {
  font-family: "TheSansBold", Arial, sans-serif !important;
}

.faqAccordion .mat-expansion-panel .itemHeader:hover,
.customAccordion .mat-expansion-panel .itemHeader:hover {
  background-color: transparent !important;
}

.faqAccordion .mat-expansion-panel .itemHeader:hover .mat-expansion-panel-header-title .title p,
.customAccordion .mat-expansion-panel .itemHeader:hover .mat-expansion-panel-header-title .title p {
  color: #007ae7;
}

.faqAccordion .mat-expansion-panel .itemHeader:hover .toggleIcon,
.customAccordion .mat-expansion-panel .itemHeader:hover .toggleIcon {
  background-color: #007ae7;
}

.faqAccordion .mat-expansion-panel .itemHeader .mat-expansion-panel-header-title,
.customAccordion .mat-expansion-panel .itemHeader .mat-expansion-panel-header-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (min-width: 48rem) {
  .faqAccordion .mat-expansion-panel .itemHeader .mat-expansion-panel-header-title .title,
.customAccordion .mat-expansion-panel .itemHeader .mat-expansion-panel-header-title .title {
    padding-right: 1rem;
  }
}
.faqAccordion .mat-expansion-panel .itemHeader .mat-expansion-panel-header-title .title p,
.customAccordion .mat-expansion-panel .itemHeader .mat-expansion-panel-header-title .title p {
  padding: 0;
  margin: 0;
}

.faqAccordion .mat-expansion-panel .itemHeader .toggleIcon,
.customAccordion .mat-expansion-panel .itemHeader .toggleIcon {
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/arrow_down_first_single.svg);
  mask-size: 80%;
  mask-repeat: no-repeat;
  mask-position: center;
  transition: transform 0.4s ease-in-out;
}

.faqAccordion .mat-expansion-panel .itemHeader .toggleIcon.rotate,
.customAccordion .mat-expansion-panel .itemHeader .toggleIcon.rotate {
  transform: rotate(180deg);
}

.faqAccordion .mat-expansion-panel .mat-expansion-panel-body,
.customAccordion .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0.25rem 0.25rem 1.5rem 0.25rem !important;
}

.faqAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea,
.customAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea {
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 48rem) {
  .faqAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea,
.customAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea {
    flex-wrap: nowrap;
  }
}
.faqAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea .imageArea,
.customAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea .imageArea {
  flex-basis: 100%;
  width: 100%;
  margin: 0;
}

@media (min-width: 48rem) {
  .faqAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea .imageArea,
.customAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea .imageArea {
    flex-basis: 340px;
    width: 340px;
    margin-right: 2rem;
  }
}
.faqAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea .imageArea img,
.customAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea .imageArea img {
  display: block;
  width: 100%;
  height: auto;
  max-width: 340px;
  min-width: 264px;
  margin: 0.5rem auto;
}

.faqAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea .textArea,
.customAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea .textArea {
  width: 100%;
  min-width: 264px;
}

.faqAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea .textArea .button-container,
.customAccordion .mat-expansion-panel .mat-expansion-panel-body .detailsArea .textArea .button-container {
  padding-top: 1rem;
}

.customAccordion .mat-expansion-panel .itemHeader {
  padding: 1rem 0.25rem;
}

@media (min-width: 48rem) {
  .customAccordion .mat-expansion-panel .itemHeader {
    padding: 1.25rem 0.25rem;
  }
}
.customAccordion .mat-expansion-panel:first-child {
  border-top: 1px solid #c8cbd4;
}

/* BUTTONS */
/* Default button is also PRIMARY button! */
button,
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: calc(0.75rem - 2px) calc(2.5rem - 2px);
  font-family: "TheSansBold", Arial, sans-serif;
  font-size: 1rem;
  line-height: 150%;
  text-decoration: none;
  border-radius: 0.5rem;
  border: 2px solid transparent;
  background: #0074de;
  color: #ffffff;
  transition: all ease-in-out 0.3s;
  outline: none;
}

button.rounded,
.button.rounded {
  border-radius: 99rem;
}

button.solid:not(.custom-paginator-pagebutton), button.primary:not(.custom-paginator-pagebutton),
.button.solid:not(.custom-paginator-pagebutton),
.button.primary:not(.custom-paginator-pagebutton) {
  min-width: 130px;
}

button.smallPrimary,
.button.smallPrimary {
  min-width: 32px !important;
  padding: calc(0.75rem - 2px);
  background: #f9fafc;
  color: #4a4c50;
}

button.smallPrimary.withIcon,
.button.smallPrimary.withIcon {
  color: #4a4c50;
  position: relative;
  display: flex;
  justify-content: space-between;
}

button.smallPrimary.withIcon:before,
.button.smallPrimary.withIcon:before {
  transition: all ease-in-out 0.3s;
  content: "";
  position: relative;
  background-color: #4a4c50;
  width: 1.5rem;
  height: 1.5rem;
}

button.smallPrimary.withIcon.plus:before,
.button.smallPrimary.withIcon.plus:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/add_plus_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.smallPrimary.withIcon.minus:before,
.button.smallPrimary.withIcon.minus:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/add_minus_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.smallPrimary.withIcon.leftArrow:before,
.button.smallPrimary.withIcon.leftArrow:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/chevron_left_2.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.smallPrimary.withIcon.rightArrow:before,
.button.smallPrimary.withIcon.rightArrow:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/chevron_right_2.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.smallPrimary:hover, button.smallPrimary.hovered,
.button.smallPrimary:hover,
.button.smallPrimary.hovered {
  background: #e3e5eb;
  color: #1d1d1f;
}

button.smallPrimary.disabled, button.smallPrimary:disabled,
.button.smallPrimary.disabled,
.button.smallPrimary:disabled {
  background: #f9fafc;
  opacity: 0.8;
}

button.smallPrimary.disabled.withIcon:before, button.smallPrimary:disabled.withIcon:before,
.button.smallPrimary.disabled.withIcon:before,
.button.smallPrimary:disabled.withIcon:before {
  background-color: #9598a1;
}

button:hover, button.hovered,
.button:hover,
.button.hovered {
  background: #007ae7;
}

button.disabled, button:disabled,
.button.disabled,
.button:disabled {
  background: #f5f5f5;
  color: #c8cbd4;
  cursor: not-allowed;
}

button.outline, button.secondary,
.button.outline,
.button.secondary {
  min-width: 130px;
  background: transparent;
  color: #0068c6;
  border-color: #0068c6;
}

button.outline:hover, button.outline.hovered, button.secondary:hover, button.secondary.hovered,
.button.outline:hover,
.button.outline.hovered,
.button.secondary:hover,
.button.secondary.hovered {
  color: #ffffff;
  border-color: #007ae7;
  background: #007ae7;
}

button.outline:hover.iconLeft, button.outline.hovered.iconLeft, button.secondary:hover.iconLeft, button.secondary.hovered.iconLeft,
.button.outline:hover.iconLeft,
.button.outline.hovered.iconLeft,
.button.secondary:hover.iconLeft,
.button.secondary.hovered.iconLeft {
  color: #ffffff;
}

button.outline:hover.iconLeft:before, button.outline.hovered.iconLeft:before, button.secondary:hover.iconLeft:before, button.secondary.hovered.iconLeft:before,
.button.outline:hover.iconLeft:before,
.button.outline.hovered.iconLeft:before,
.button.secondary:hover.iconLeft:before,
.button.secondary.hovered.iconLeft:before {
  background-color: #ffffff !important;
}

button.outline:hover.iconRight, button.outline.hovered.iconRight, button.secondary:hover.iconRight, button.secondary.hovered.iconRight,
.button.outline:hover.iconRight,
.button.outline.hovered.iconRight,
.button.secondary:hover.iconRight,
.button.secondary.hovered.iconRight {
  color: #ffffff;
}

button.outline:hover.iconRight:after, button.outline.hovered.iconRight:after, button.secondary:hover.iconRight:after, button.secondary.hovered.iconRight:after,
.button.outline:hover.iconRight:after,
.button.outline.hovered.iconRight:after,
.button.secondary:hover.iconRight:after,
.button.secondary.hovered.iconRight:after {
  background-color: #ffffff !important;
}

button.outline.disabled, button.outline:disabled, button.secondary.disabled, button.secondary:disabled,
.button.outline.disabled,
.button.outline:disabled,
.button.secondary.disabled,
.button.secondary:disabled {
  background: transparent;
  border-color: #f5f5f5;
  color: #c8cbd4;
}

button.outline.disabled:hover.iconLeft:before, button.outline.disabled.hovered.iconLeft:before, button.outline:disabled:hover.iconLeft:before, button.outline:disabled.hovered.iconLeft:before, button.secondary.disabled:hover.iconLeft:before, button.secondary.disabled.hovered.iconLeft:before, button.secondary:disabled:hover.iconLeft:before, button.secondary:disabled.hovered.iconLeft:before,
.button.outline.disabled:hover.iconLeft:before,
.button.outline.disabled.hovered.iconLeft:before,
.button.outline:disabled:hover.iconLeft:before,
.button.outline:disabled.hovered.iconLeft:before,
.button.secondary.disabled:hover.iconLeft:before,
.button.secondary.disabled.hovered.iconLeft:before,
.button.secondary:disabled:hover.iconLeft:before,
.button.secondary:disabled.hovered.iconLeft:before {
  background-color: #c8cbd4 !important;
}

button.outline.disabled:hover.iconRight:after, button.outline.disabled.hovered.iconRight:after, button.outline:disabled:hover.iconRight:after, button.outline:disabled.hovered.iconRight:after, button.secondary.disabled:hover.iconRight:after, button.secondary.disabled.hovered.iconRight:after, button.secondary:disabled:hover.iconRight:after, button.secondary:disabled.hovered.iconRight:after,
.button.outline.disabled:hover.iconRight:after,
.button.outline.disabled.hovered.iconRight:after,
.button.outline:disabled:hover.iconRight:after,
.button.outline:disabled.hovered.iconRight:after,
.button.secondary.disabled:hover.iconRight:after,
.button.secondary.disabled.hovered.iconRight:after,
.button.secondary:disabled:hover.iconRight:after,
.button.secondary:disabled.hovered.iconRight:after {
  background-color: #c8cbd4 !important;
}

button.outline.iconLeft, button.secondary.iconLeft,
.button.outline.iconLeft,
.button.secondary.iconLeft {
  color: #0068c6;
}

button.outline.iconLeft:before, button.secondary.iconLeft:before,
.button.outline.iconLeft:before,
.button.secondary.iconLeft:before {
  background-color: #0068c6 !important;
}

button.outline.iconRight, button.secondary.iconRight,
.button.outline.iconRight,
.button.secondary.iconRight {
  color: #0068c6;
}

button.outline.iconRight:after, button.secondary.iconRight:after,
.button.outline.iconRight:after,
.button.secondary.iconRight:after {
  background-color: #0068c6 !important;
}

button.text, button.link, button.tertiary,
.button.text,
.button.link,
.button.tertiary {
  min-width: 130px;
  background: transparent;
  border-radius: 0;
  color: #0068c6;
}

button.text.noPadding, button.link.noPadding, button.tertiary.noPadding,
.button.text.noPadding,
.button.link.noPadding,
.button.tertiary.noPadding {
  min-width: 32px;
  padding: 0 !important;
}

button.text.noMinWidth, button.link.noMinWidth, button.tertiary.noMinWidth,
.button.text.noMinWidth,
.button.link.noMinWidth,
.button.tertiary.noMinWidth {
  min-width: 24px;
}

button.text.text, button.link.text, button.tertiary.text,
.button.text.text,
.button.link.text,
.button.tertiary.text {
  min-width: 24px;
  padding: 0.75rem 0.25rem;
}

button.text.text.addPadding, button.link.text.addPadding, button.tertiary.text.addPadding,
.button.text.text.addPadding,
.button.link.text.addPadding,
.button.tertiary.text.addPadding {
  padding: 0.75rem 1rem;
}

button.text:hover, button.text.hovered, button.link:hover, button.link.hovered, button.tertiary:hover, button.tertiary.hovered,
.button.text:hover,
.button.text.hovered,
.button.link:hover,
.button.link.hovered,
.button.tertiary:hover,
.button.tertiary.hovered {
  text-decoration: underline;
}

button.text.disabled, button.text:disabled, button.link.disabled, button.link:disabled, button.tertiary.disabled, button.tertiary:disabled,
.button.text.disabled,
.button.text:disabled,
.button.link.disabled,
.button.link:disabled,
.button.tertiary.disabled,
.button.tertiary:disabled {
  color: #c8cbd4;
}

button.text.disabled:hover, button.text.disabled.hovered, button.text:disabled:hover, button.text:disabled.hovered, button.link.disabled:hover, button.link.disabled.hovered, button.link:disabled:hover, button.link:disabled.hovered, button.tertiary.disabled:hover, button.tertiary.disabled.hovered, button.tertiary:disabled:hover, button.tertiary:disabled.hovered,
.button.text.disabled:hover,
.button.text.disabled.hovered,
.button.text:disabled:hover,
.button.text:disabled.hovered,
.button.link.disabled:hover,
.button.link.disabled.hovered,
.button.link:disabled:hover,
.button.link:disabled.hovered,
.button.tertiary.disabled:hover,
.button.tertiary.disabled.hovered,
.button.tertiary:disabled:hover,
.button.tertiary:disabled.hovered {
  text-decoration: none;
}

button.text.disabled:hover.iconLeft:before, button.text.disabled.hovered.iconLeft:before, button.text:disabled:hover.iconLeft:before, button.text:disabled.hovered.iconLeft:before, button.link.disabled:hover.iconLeft:before, button.link.disabled.hovered.iconLeft:before, button.link:disabled:hover.iconLeft:before, button.link:disabled.hovered.iconLeft:before, button.tertiary.disabled:hover.iconLeft:before, button.tertiary.disabled.hovered.iconLeft:before, button.tertiary:disabled:hover.iconLeft:before, button.tertiary:disabled.hovered.iconLeft:before,
.button.text.disabled:hover.iconLeft:before,
.button.text.disabled.hovered.iconLeft:before,
.button.text:disabled:hover.iconLeft:before,
.button.text:disabled.hovered.iconLeft:before,
.button.link.disabled:hover.iconLeft:before,
.button.link.disabled.hovered.iconLeft:before,
.button.link:disabled:hover.iconLeft:before,
.button.link:disabled.hovered.iconLeft:before,
.button.tertiary.disabled:hover.iconLeft:before,
.button.tertiary.disabled.hovered.iconLeft:before,
.button.tertiary:disabled:hover.iconLeft:before,
.button.tertiary:disabled.hovered.iconLeft:before {
  background-color: #c8cbd4 !important;
}

button.text.disabled:hover.iconRight:after, button.text.disabled.hovered.iconRight:after, button.text:disabled:hover.iconRight:after, button.text:disabled.hovered.iconRight:after, button.link.disabled:hover.iconRight:after, button.link.disabled.hovered.iconRight:after, button.link:disabled:hover.iconRight:after, button.link:disabled.hovered.iconRight:after, button.tertiary.disabled:hover.iconRight:after, button.tertiary.disabled.hovered.iconRight:after, button.tertiary:disabled:hover.iconRight:after, button.tertiary:disabled.hovered.iconRight:after,
.button.text.disabled:hover.iconRight:after,
.button.text.disabled.hovered.iconRight:after,
.button.text:disabled:hover.iconRight:after,
.button.text:disabled.hovered.iconRight:after,
.button.link.disabled:hover.iconRight:after,
.button.link.disabled.hovered.iconRight:after,
.button.link:disabled:hover.iconRight:after,
.button.link:disabled.hovered.iconRight:after,
.button.tertiary.disabled:hover.iconRight:after,
.button.tertiary.disabled.hovered.iconRight:after,
.button.tertiary:disabled:hover.iconRight:after,
.button.tertiary:disabled.hovered.iconRight:after {
  background-color: #c8cbd4 !important;
}

button.text.iconLeft, button.link.iconLeft, button.tertiary.iconLeft,
.button.text.iconLeft,
.button.link.iconLeft,
.button.tertiary.iconLeft {
  color: #0068c6;
  padding: calc(0.75rem - 2px) calc(1.5rem - 2px);
}

button.text.iconLeft:before, button.link.iconLeft:before, button.tertiary.iconLeft:before,
.button.text.iconLeft:before,
.button.link.iconLeft:before,
.button.tertiary.iconLeft:before {
  background-color: #0068c6 !important;
}

button.text.iconRight, button.link.iconRight, button.tertiary.iconRight,
.button.text.iconRight,
.button.link.iconRight,
.button.tertiary.iconRight {
  color: #0068c6;
  padding: calc(0.75rem - 2px) calc(1.5rem - 2px);
}

button.text.iconRight:after, button.link.iconRight:after, button.tertiary.iconRight:after,
.button.text.iconRight:after,
.button.link.iconRight:after,
.button.tertiary.iconRight:after {
  background-color: #0068c6 !important;
}

button.iconLeft,
.button.iconLeft {
  color: #ffffff;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: calc(0.75rem - 2px) calc(1.5rem - 2px) calc(0.75rem - 2px) calc(0.75rem - 2px);
}

button.iconLeft:before,
.button.iconLeft:before {
  transition: all ease-in-out 0.3s;
  content: "";
  position: relative;
  background-color: #ffffff !important;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin-right: 0.5rem;
}

button.iconLeft.disabled, button.iconLeft:disabled,
.button.iconLeft.disabled,
.button.iconLeft:disabled {
  color: #c8cbd4 !important;
}

button.iconLeft.disabled:before, button.iconLeft:disabled:before,
.button.iconLeft.disabled:before,
.button.iconLeft:disabled:before {
  background-color: #c8cbd4 !important;
}

button.iconLeft.contact:before,
.button.iconLeft.contact:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/message.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.edit:before,
.button.iconLeft.edit:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/update.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.close-ticket:before,
.button.iconLeft.close-ticket:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/sign_out.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.open-ticket:before,
.button.iconLeft.open-ticket:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/update.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.new-messages:before,
.button.iconLeft.new-messages:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/new_messages.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.share:before,
.button.iconLeft.share:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/share.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.history:before,
.button.iconLeft.history:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/checklist.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.external:before,
.button.iconLeft.external:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/external_link.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.delete:before,
.button.iconLeft.delete:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/delete.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.duplicate:before,
.button.iconLeft.duplicate:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/duplicate.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.arrow:before,
.button.iconLeft.arrow:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/chevron_left_2.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.arrowDown:before,
.button.iconLeft.arrowDown:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/chevron_down_2.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 85% !important;
}

button.iconLeft.arrowUp:before,
.button.iconLeft.arrowUp:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/chevron_up_2.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 85% !important;
}

button.iconLeft.profile:before,
.button.iconLeft.profile:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/profile.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.phone:before,
.button.iconLeft.phone:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/phone.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.save:before,
.button.iconLeft.save:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/save.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.reload:before,
.button.iconLeft.reload:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/helper/sync.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 75% !important;
}

button.iconLeft.reset:before,
.button.iconLeft.reset:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/helper/undo.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 75% !important;
}

button.iconLeft.remove:before,
.button.iconLeft.remove:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/close_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.download:before,
.button.iconLeft.download:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/download.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.add:before,
.button.iconLeft.add:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/add_plus_circle.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.add2:before,
.button.iconLeft.add2:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/add_plus_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.comment:before,
.button.iconLeft.comment:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/comment.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.createFolder:before,
.button.iconLeft.createFolder:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/create_folder.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.upload:before,
.button.iconLeft.upload:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/upload.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.print:before,
.button.iconLeft.print:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/print.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.show:before,
.button.iconLeft.show:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/search.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconLeft.bookmark:before,
.button.iconLeft.bookmark:before {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/bookmark.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconRight,
.button.iconRight {
  color: #ffffff;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: calc(0.75rem - 2px) calc(0.75rem - 2px) calc(0.75rem - 2px) calc(1.5rem - 2px);
}

button.iconRight:after,
.button.iconRight:after {
  transition: all ease-in-out 0.3s;
  content: "";
  position: relative;
  background-color: #ffffff !important;
  mask-size: 125% !important;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  margin-left: 0.5rem;
}

button.iconRight.disabled,
.button.iconRight.disabled {
  color: #c8cbd4 !important;
}

button.iconRight.disabled:after,
.button.iconRight.disabled:after {
  background-color: #c8cbd4 !important;
}

button.iconRight.arrow:after,
.button.iconRight.arrow:after {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/chevron_right_2.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconRight.download:after,
.button.iconRight.download:after {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/download.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 85% !important;
}

button.iconRight.filter:after,
.button.iconRight.filter:after {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/controls.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.iconRight.load:after,
.button.iconRight.load:after {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/helper/sync.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

button.inline,
.button.inline {
  padding: 0;
  display: inline;
  font-family: "TheSans", Arial, sans-serif;
  font-size: inherit;
  text-decoration: underline;
}

button.inline:hover, button.inline.hovered,
.button.inline:hover,
.button.inline.hovered {
  text-decoration: none;
}

button.fullWidth,
.button.fullWidth {
  width: 100% !important;
}

.mat-datepicker-toggle button.mat-icon-button:hover {
  transition: all 0.3s ease-in-out;
  background: rgba(0, 116, 222, 0.25);
}

.mat-datepicker-toggle button.mat-icon-button:hover img {
  opacity: 0.75;
}

/* Buttons on dark or image background = inverted mode */
.inverted button.outline, .inverted button.secondary,
.inverted .button.outline,
.inverted .button.secondary {
  background: transparent;
  color: #ffffff;
  border-color: #ffffff;
}

.inverted button.outline:hover, .inverted button.outline.hovered, .inverted button.secondary:hover, .inverted button.secondary.hovered,
.inverted .button.outline:hover,
.inverted .button.outline.hovered,
.inverted .button.secondary:hover,
.inverted .button.secondary.hovered {
  color: #ffffff;
  background: #007ae7;
  border-color: #007ae7;
}

.inverted button.outline.disabled, .inverted button.outline:disabled, .inverted button.secondary.disabled, .inverted button.secondary:disabled,
.inverted .button.outline.disabled,
.inverted .button.outline:disabled,
.inverted .button.secondary.disabled,
.inverted .button.secondary:disabled {
  color: #c8cbd4;
  border-color: #f5f5f5;
}

.inverted button.outline.disabled:hover, .inverted button.outline.disabled.hovered, .inverted button.outline:disabled:hover, .inverted button.outline:disabled.hovered, .inverted button.secondary.disabled:hover, .inverted button.secondary.disabled.hovered, .inverted button.secondary:disabled:hover, .inverted button.secondary:disabled.hovered,
.inverted .button.outline.disabled:hover,
.inverted .button.outline.disabled.hovered,
.inverted .button.outline:disabled:hover,
.inverted .button.outline:disabled.hovered,
.inverted .button.secondary.disabled:hover,
.inverted .button.secondary.disabled.hovered,
.inverted .button.secondary:disabled:hover,
.inverted .button.secondary:disabled.hovered {
  color: #c8cbd4;
  border-color: #f5f5f5;
  background-color: transparent;
}

.inverted button.outline.iconLeft, .inverted button.secondary.iconLeft,
.inverted .button.outline.iconLeft,
.inverted .button.secondary.iconLeft {
  color: #ffffff;
}

.inverted button.outline.iconLeft:before, .inverted button.secondary.iconLeft:before,
.inverted .button.outline.iconLeft:before,
.inverted .button.secondary.iconLeft:before {
  background-color: #ffffff !important;
}

.inverted button.outline.iconRight, .inverted button.secondary.iconRight,
.inverted .button.outline.iconRight,
.inverted .button.secondary.iconRight {
  color: #ffffff;
}

.inverted button.outline.iconRight:after, .inverted button.secondary.iconRight:after,
.inverted .button.outline.iconRight:after,
.inverted .button.secondary.iconRight:after {
  background-color: #ffffff !important;
}

.inverted button.text, .inverted button.link, .inverted button.tertiary,
.inverted .button.text,
.inverted .button.link,
.inverted .button.tertiary {
  color: #ffffff;
  background: transparent;
}

.inverted button.text:hover, .inverted button.text.hovered, .inverted button.link:hover, .inverted button.link.hovered, .inverted button.tertiary:hover, .inverted button.tertiary.hovered,
.inverted .button.text:hover,
.inverted .button.text.hovered,
.inverted .button.link:hover,
.inverted .button.link.hovered,
.inverted .button.tertiary:hover,
.inverted .button.tertiary.hovered {
  text-decoration: underline;
}

.inverted button.text.disabled, .inverted button.text:disabled, .inverted button.text.disabled:hover, .inverted button.text:disabled:hover, .inverted button.link.disabled, .inverted button.link:disabled, .inverted button.link.disabled:hover, .inverted button.link:disabled:hover, .inverted button.tertiary.disabled, .inverted button.tertiary:disabled, .inverted button.tertiary.disabled:hover, .inverted button.tertiary:disabled:hover,
.inverted .button.text.disabled,
.inverted .button.text:disabled,
.inverted .button.text.disabled:hover,
.inverted .button.text:disabled:hover,
.inverted .button.link.disabled,
.inverted .button.link:disabled,
.inverted .button.link.disabled:hover,
.inverted .button.link:disabled:hover,
.inverted .button.tertiary.disabled,
.inverted .button.tertiary:disabled,
.inverted .button.tertiary.disabled:hover,
.inverted .button.tertiary:disabled:hover {
  text-decoration: none;
  color: #c8cbd4;
}

.inverted button.text.disabled.iconLeft, .inverted button.text:disabled.iconLeft, .inverted button.text.disabled:hover.iconLeft, .inverted button.text:disabled:hover.iconLeft, .inverted button.link.disabled.iconLeft, .inverted button.link:disabled.iconLeft, .inverted button.link.disabled:hover.iconLeft, .inverted button.link:disabled:hover.iconLeft, .inverted button.tertiary.disabled.iconLeft, .inverted button.tertiary:disabled.iconLeft, .inverted button.tertiary.disabled:hover.iconLeft, .inverted button.tertiary:disabled:hover.iconLeft,
.inverted .button.text.disabled.iconLeft,
.inverted .button.text:disabled.iconLeft,
.inverted .button.text.disabled:hover.iconLeft,
.inverted .button.text:disabled:hover.iconLeft,
.inverted .button.link.disabled.iconLeft,
.inverted .button.link:disabled.iconLeft,
.inverted .button.link.disabled:hover.iconLeft,
.inverted .button.link:disabled:hover.iconLeft,
.inverted .button.tertiary.disabled.iconLeft,
.inverted .button.tertiary:disabled.iconLeft,
.inverted .button.tertiary.disabled:hover.iconLeft,
.inverted .button.tertiary:disabled:hover.iconLeft {
  color: #c8cbd4;
}

.inverted button.text.disabled.iconLeft:before, .inverted button.text:disabled.iconLeft:before, .inverted button.text.disabled:hover.iconLeft:before, .inverted button.text:disabled:hover.iconLeft:before, .inverted button.link.disabled.iconLeft:before, .inverted button.link:disabled.iconLeft:before, .inverted button.link.disabled:hover.iconLeft:before, .inverted button.link:disabled:hover.iconLeft:before, .inverted button.tertiary.disabled.iconLeft:before, .inverted button.tertiary:disabled.iconLeft:before, .inverted button.tertiary.disabled:hover.iconLeft:before, .inverted button.tertiary:disabled:hover.iconLeft:before,
.inverted .button.text.disabled.iconLeft:before,
.inverted .button.text:disabled.iconLeft:before,
.inverted .button.text.disabled:hover.iconLeft:before,
.inverted .button.text:disabled:hover.iconLeft:before,
.inverted .button.link.disabled.iconLeft:before,
.inverted .button.link:disabled.iconLeft:before,
.inverted .button.link.disabled:hover.iconLeft:before,
.inverted .button.link:disabled:hover.iconLeft:before,
.inverted .button.tertiary.disabled.iconLeft:before,
.inverted .button.tertiary:disabled.iconLeft:before,
.inverted .button.tertiary.disabled:hover.iconLeft:before,
.inverted .button.tertiary:disabled:hover.iconLeft:before {
  background-color: #c8cbd4 !important;
}

.inverted button.text.disabled.iconRight, .inverted button.text:disabled.iconRight, .inverted button.text.disabled:hover.iconRight, .inverted button.text:disabled:hover.iconRight, .inverted button.link.disabled.iconRight, .inverted button.link:disabled.iconRight, .inverted button.link.disabled:hover.iconRight, .inverted button.link:disabled:hover.iconRight, .inverted button.tertiary.disabled.iconRight, .inverted button.tertiary:disabled.iconRight, .inverted button.tertiary.disabled:hover.iconRight, .inverted button.tertiary:disabled:hover.iconRight,
.inverted .button.text.disabled.iconRight,
.inverted .button.text:disabled.iconRight,
.inverted .button.text.disabled:hover.iconRight,
.inverted .button.text:disabled:hover.iconRight,
.inverted .button.link.disabled.iconRight,
.inverted .button.link:disabled.iconRight,
.inverted .button.link.disabled:hover.iconRight,
.inverted .button.link:disabled:hover.iconRight,
.inverted .button.tertiary.disabled.iconRight,
.inverted .button.tertiary:disabled.iconRight,
.inverted .button.tertiary.disabled:hover.iconRight,
.inverted .button.tertiary:disabled:hover.iconRight {
  color: #ffffff;
}

.inverted button.text.disabled.iconRight:after, .inverted button.text:disabled.iconRight:after, .inverted button.text.disabled:hover.iconRight:after, .inverted button.text:disabled:hover.iconRight:after, .inverted button.link.disabled.iconRight:after, .inverted button.link:disabled.iconRight:after, .inverted button.link.disabled:hover.iconRight:after, .inverted button.link:disabled:hover.iconRight:after, .inverted button.tertiary.disabled.iconRight:after, .inverted button.tertiary:disabled.iconRight:after, .inverted button.tertiary.disabled:hover.iconRight:after, .inverted button.tertiary:disabled:hover.iconRight:after,
.inverted .button.text.disabled.iconRight:after,
.inverted .button.text:disabled.iconRight:after,
.inverted .button.text.disabled:hover.iconRight:after,
.inverted .button.text:disabled:hover.iconRight:after,
.inverted .button.link.disabled.iconRight:after,
.inverted .button.link:disabled.iconRight:after,
.inverted .button.link.disabled:hover.iconRight:after,
.inverted .button.link:disabled:hover.iconRight:after,
.inverted .button.tertiary.disabled.iconRight:after,
.inverted .button.tertiary:disabled.iconRight:after,
.inverted .button.tertiary.disabled:hover.iconRight:after,
.inverted .button.tertiary:disabled:hover.iconRight:after {
  background-color: #c8cbd4 !important;
}

.inverted button.text.iconLeft, .inverted button.link.iconLeft, .inverted button.tertiary.iconLeft,
.inverted .button.text.iconLeft,
.inverted .button.link.iconLeft,
.inverted .button.tertiary.iconLeft {
  color: #ffffff;
}

.inverted button.text.iconLeft:before, .inverted button.link.iconLeft:before, .inverted button.tertiary.iconLeft:before,
.inverted .button.text.iconLeft:before,
.inverted .button.link.iconLeft:before,
.inverted .button.tertiary.iconLeft:before {
  background-color: #ffffff !important;
}

.inverted button.text.iconRight, .inverted button.link.iconRight, .inverted button.tertiary.iconRight,
.inverted .button.text.iconRight,
.inverted .button.link.iconRight,
.inverted .button.tertiary.iconRight {
  color: #ffffff;
}

.inverted button.text.iconRight:after, .inverted button.link.iconRight:after, .inverted button.tertiary.iconRight:after,
.inverted .button.text.iconRight:after,
.inverted .button.link.iconRight:after,
.inverted .button.tertiary.iconRight:after {
  background-color: #ffffff !important;
}

/* Buttons placed in a button container [should be default] */
.button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  margin: -0.5rem;
  width: calc(100% + 1rem);
}

.button-container.withSpacing {
  margin: 0 -0.5rem;
}

.button-container.resetSpacing {
  margin: 0;
}

@media (min-width: 64rem) {
  .button-container {
    flex-wrap: nowrap;
  }
}
.button-container.startLeft {
  justify-content: flex-start;
}

.button-container button,
.button-container .button {
  margin: 0.5rem;
  width: 100%;
}

@media (min-width: 20rem) {
  .button-container button,
.button-container .button {
    width: auto;
  }
}
@media (min-width: 20rem) and (min-width: 48rem) {
  .button-container button:not(.doNotHide),
.button-container .button:not(.doNotHide) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
@media (min-width: 48rem) {
  .button-container button.goRight,
.button-container .button.goRight {
    margin-left: auto !important;
  }
}
@media (min-width: 48rem) {
  .button-container button.goLeft,
.button-container .button.goLeft {
    margin-right: auto !important;
  }
}
.button-container button.fullWidth,
.button-container .button.fullWidth {
  width: calc(100% - 1rem) !important;
}

.button-container button.inFlyInContainer,
.button-container .button.inFlyInContainer {
  width: auto;
}

.button-container.centered {
  justify-content: center;
  align-items: normal;
}

@media (min-width: 48rem) {
  .button-container.centered {
    align-items: center;
  }
}
.button-container.spread {
  justify-content: space-between;
}

.button-container.topLine {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid rgba(0, 35, 62, 0.06);
}

.button-container.addTopPadding {
  padding-top: 1.5rem;
}

button.mat-calendar-period-button:hover {
  background-color: transparent;
}

/* Special Buttons: Expandable Text > more Button / Link */
span.moreButton {
  cursor: pointer;
  color: #0074de;
  font-family: "TheSansBold", Arial, sans-serif;
  text-decoration: none;
}

span.moreButton:hover, span.moreButton:visited {
  text-decoration: none;
  color: #007ae7;
}

/* TOGGLE / FILTER SWITCH */
.toggleContainer {
  margin: -0.5rem;
}

.toggleContainer .toggle {
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  background: #d1e3f5;
  color: #00233e;
  margin: 0.5rem;
  width: auto;
  display: inline-block;
}

.toggleContainer .toggle:hover {
  background: #d1e3f5;
  cursor: pointer;
}

.toggleContainer .toggle.selected {
  background: #457ddb;
  color: #ffffff;
}

.toggleContainer .toggle.selected:hover {
  background: #a5c2e7;
  cursor: pointer;
}

.carousel {
  position: relative;
  z-index: 9998;
}

.carousel .carousel-dots {
  bottom: 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.carousel .carousel-dots .carousel-dot {
  background-color: #c8cbd4 !important;
  border: 2px solid transparent !important;
  height: 0.66rem !important;
  width: 0.66rem !important;
  margin: 0.5rem 0.15rem !important;
}

.carousel .carousel-dots .carousel-dot.carousel-dot-active {
  background: #1d1d1f !important;
}

.carousel .carousel-arrows {
  position: absolute;
  width: 100%;
  top: calc(50% + 8px);
}

@media (min-width: 64rem) {
  .carousel .carousel-arrows {
    top: 50%;
  }
}
.carousel .carousel-arrows .carousel-arrow {
  background-image: none !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
  cursor: pointer;
}

@media (min-width: 64rem) {
  .carousel .carousel-arrows .carousel-arrow {
    box-shadow: none !important;
    background-color: transparent !important;
    width: 60px !important;
    height: 60px !important;
  }

  .carousel .carousel-arrows .carousel-arrow:hover.carousel-arrow-prev::after, .carousel .carousel-arrows .carousel-arrow:hover.carousel-arrow-next::after {
    background-color: #4a4c50 !important;
  }
}
.carousel .carousel-arrows .carousel-arrow:hover {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.carousel .carousel-arrows .carousel-arrow:hover:after {
  background-color: #4a4c50 !important;
}

.carousel .carousel-arrows .carousel-arrow.carousel-arrow-prev {
  left: 12px !important;
}

.carousel .carousel-arrows .carousel-arrow.carousel-arrow-prev:after {
  position: absolute;
  content: "";
  top: 15%;
  right: 17%;
  width: 72%;
  height: 72%;
  background-color: #1d1d1f;
  mask-image: url(/shared-assets/icons/basic/arrow_left_first_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

@media (min-width: 64rem) {
  .carousel .carousel-arrows .carousel-arrow.carousel-arrow-prev {
    left: -60px !important;
  }

  .carousel .carousel-arrows .carousel-arrow.carousel-arrow-prev:after {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #1d1d1f;
    mask-image: url(/shared-assets/icons/basic/slider_arrow_left.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
  }
}
.carousel .carousel-arrows .carousel-arrow.carousel-arrow-next {
  right: 12px !important;
}

.carousel .carousel-arrows .carousel-arrow.carousel-arrow-next:after {
  position: absolute;
  content: "";
  top: 15%;
  left: 17%;
  width: 72%;
  height: 72%;
  background-color: #1d1d1f;
  mask-image: url(/shared-assets/icons/basic/arrow_right_first_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

@media (min-width: 64rem) {
  .carousel .carousel-arrows .carousel-arrow.carousel-arrow-next {
    right: -60px !important;
  }

  .carousel .carousel-arrows .carousel-arrow.carousel-arrow-next:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1d1d1f;
    mask-image: url(/shared-assets/icons/basic/slider_arrow_right.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
  }
}
.carousel .carousel-arrows .carousel-arrow.carousel-arrow-disabled {
  display: none;
  opacity: 0;
}

.keenCarousel {
  position: relative;
  border-radius: 1.25rem;
}

.keenCarousel.halfSized {
  max-width: 800px;
  margin: 0 auto;
}

.keenCarousel .keen-slider {
  z-index: 10;
}

.keenCarousel .keen-slider.small .imageItem > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.keenCarousel .imageItem {
  border-radius: 1.25rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.keenCarousel .imageItem > img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 1.25rem;
}

.keenCarousel .navArrow {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  z-index: 20;
  border-radius: 50%;
}

@media (min-width: 64rem) {
  .keenCarousel .navArrow {
    background-color: transparent;
    margin-top: -70px;
    border-radius: 0%;
  }
}
.keenCarousel .navArrow .arrowIcon {
  width: 30px;
  height: 30px;
}

@media (min-width: 64rem) {
  .keenCarousel .navArrow .arrowIcon {
    width: 60px;
    height: 60px;
  }
}
.keenCarousel .navArrow.left {
  left: 12px;
  right: auto;
  padding: 5px 8px 5px 2px;
}

@media (min-width: 64rem) {
  .keenCarousel .navArrow.left {
    left: 0px;
    margin-left: -65px;
    padding: 0px;
  }
}
.keenCarousel .navArrow.left .arrowIcon {
  background-color: #1d1d1f;
  mask-image: url(/shared-assets/icons/basic/arrow_left_first_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

@media (min-width: 64rem) {
  .keenCarousel .navArrow.left .arrowIcon {
    background-color: #4a4c50;
    mask-image: url(/shared-assets/icons/basic/slider_arrow_left.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
  }
}
.keenCarousel .navArrow.right {
  right: 12px;
  left: auto;
  padding: 5px 2px 5px 8px;
}

@media (min-width: 64rem) {
  .keenCarousel .navArrow.right {
    right: 0px;
    margin-right: -65px;
    padding: 0px;
  }
}
.keenCarousel .navArrow.right .arrowIcon {
  background-color: #1d1d1f;
  mask-image: url(/shared-assets/icons/basic/arrow_right_first_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

@media (min-width: 64rem) {
  .keenCarousel .navArrow.right .arrowIcon {
    background-color: #4a4c50;
    mask-image: url(/shared-assets/icons/basic/slider_arrow_right.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
  }
}
.keenCarousel .navArrow.disabled {
  display: none;
}

.keenCarousel .sliderNavigation.dots {
  display: flex;
  height: 50px;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.keenCarousel .sliderNavigation.dots .navDot {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  cursor: pointer;
  background-color: #9598a1;
}

.keenCarousel .sliderNavigation.dots .navDot.active {
  background-color: #4a4c50;
}

.keenCarousel.expandable .imageItem {
  cursor: pointer;
}

.keenCarousel.expandable .imageItem .imageOverlay {
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  position: absolute;
  z-index: 30;
  transition: all 500ms ease;
}

.keenCarousel.expandable .imageItem .imageOverlay .icon {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  height: 2rem;
  width: 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  z-index: 50;
}

.keenCarousel.expandable .imageItem .imageOverlay .icon.icon-enlarge {
  transition: all 200ms ease;
  background-color: #4a4c50;
  mask-image: url(/shared-assets/icons/basic/enlarge.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.keenCarousel.expandable .imageItem .imageOverlay .ctaButton {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease;
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  padding: 0.25rem 1rem;
  transform: translateX(-50%) translateY(25%);
  border: 2px solid #ffffff;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.keenCarousel.expandable .imageItem .imageOverlay .ctaButton p {
  font-family: "TheSansBold", Arial, sans-serif;
  color: #ffffff;
}

.keenCarousel.expandable .imageItem > img {
  transition: all 500ms ease;
}

.keenCarousel.expandable .innerWrap .imageItem {
  overflow: hidden;
}

@media (min-width: 48rem) {
  .keenCarousel.expandable .innerWrap:hover .imageItem .imageOverlay {
    background: rgba(29, 29, 31, 0.3);
  }

  .keenCarousel.expandable .innerWrap:hover .imageItem .imageOverlay .ctaButton {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(-50%);
  }

  .keenCarousel.expandable .innerWrap:hover .imageItem .imageOverlay .icon.icon-enlarge {
    background-color: #ffffff;
  }

  .keenCarousel.expandable .innerWrap:hover .imageItem > img {
    transform: scale(1.02);
  }
}
.myProjectsCarousel .carousel-cells {
  padding-left: 1rem !important;
}

.myProjectsCarousel .carousel-cell {
  overflow: visible;
  padding-right: 1rem;
  padding-bottom: 5rem;
}

.genericImageCarousel {
  min-height: min(65vw, 960px);
}

.genericImageCarousel .carousel-dots {
  bottom: -25px !important;
}

@media (min-width: 48rem) {
  .genericImageCarousel .carousel-dots {
    bottom: -30px !important;
  }
}
@media (min-width: 48rem) and (min-width: 64rem) {
  .genericImageCarousel .carousel-dots {
    bottom: -35px !important;
  }
}
@media (min-width: 48rem) and (min-width: 64rem) and (min-width: 90rem) {
  .genericImageCarousel .carousel-dots {
    bottom: -45px !important;
  }
}
.genericImageCarousel.halfSized {
  min-height: min(64vw, 535px);
}

.genericImageCarousel.halfSized .carousel-dots {
  bottom: -30px !important;
}

@media (min-width: 48rem) {
  .genericImageCarousel.halfSized .carousel-dots {
    bottom: -40px !important;
  }
}
@media (min-width: 64rem) {
  .genericImageCarousel.halfSized .carousel-arrows .carousel-arrow.carousel-arrow-prev {
    left: -60px !important;
    background-color: transparent !important;
  }
}
@media (min-width: 64rem) {
  .genericImageCarousel.halfSized .carousel-arrows .carousel-arrow.carousel-arrow-next {
    right: -60px !important;
    background-color: transparent !important;
  }
}
.customTeaserCarousel .carousel-arrow {
  top: calc(50% - 10px) !important;
}

.customTeaserCarousel.small {
  min-height: 285px;
}

@media (min-width: 48rem) {
  .customTeaserCarousel.small {
    min-height: 295px;
  }
}
.customTeaserCarousel.small .carousel-arrow {
  top: -65px !important;
}

.customTeaserCarousel.medium {
  min-height: 343px;
}

@media (min-width: 48rem) {
  .customTeaserCarousel.medium {
    min-height: 388px;
  }
}
.customTeaserCarousel.large {
  min-height: 381px;
}

@media (min-width: 48rem) {
  .customTeaserCarousel.large {
    min-height: 438px;
  }
}
.customTeaserCarousel .carousel-dots {
  bottom: -40px !important;
}

.conceptNavigationCarousel .carousel-container {
  overflow: visible !important;
  display: flex;
  justify-content: center;
}

.conceptNavigationCarousel .carousel-container .carousel-cells {
  padding: 0;
}

@media (min-width: 48rem) {
  .conceptNavigationCarousel .carousel-container .carousel-cells {
    padding: 0 25px;
  }
}
.conceptNavigationCarousel .carousel-arrows .carousel-arrow {
  top: unset !important;
  bottom: 0.875rem;
  opacity: 0.8;
  height: 100% !important;
  width: 50px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: #f9fafc !important;
  top: unset !important;
  bottom: 0.875rem;
}

.conceptNavigationCarousel .carousel-arrows .carousel-arrow:hover {
  opacity: 0.9;
}

.conceptNavigationCarousel .carousel-arrows .carousel-arrow:hover:after {
  background-color: #1d1d1f !important;
}

.conceptNavigationCarousel .carousel-arrows .carousel-arrow.carousel-arrow-prev {
  left: 0 !important;
  background-image: none !important;
}

.conceptNavigationCarousel .carousel-arrows .carousel-arrow.carousel-arrow-prev:after {
  position: absolute;
  content: "";
  top: 19%;
  right: 24%;
  width: 70%;
  height: 70%;
  background-color: #1d1d1f;
  mask-image: url(/shared-assets/icons/basic/slider_arrow_left.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.conceptNavigationCarousel .carousel-arrows .carousel-arrow.carousel-arrow-next {
  right: 0 !important;
  background-image: none !important;
}

.conceptNavigationCarousel .carousel-arrows .carousel-arrow.carousel-arrow-next:after {
  position: absolute;
  content: "";
  top: 19%;
  left: 23%;
  width: 70%;
  height: 70%;
  background-color: #1d1d1f;
  mask-image: url(/shared-assets/icons/basic/slider_arrow_right.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.conceptNavigationCarousel .carousel-arrows .carousel-arrow.carousel-arrow-disabled {
  display: none;
}

.contentNavigationCarousel .carousel-arrows {
  top: 90px !important;
}

.catalogueCarousel .carousel-arrows {
  top: 70px !important;
}

.mat-datepicker-popup {
  height: auto;
}

.mat-tab-header-pagination-chevron {
  color: #0074de;
}

.mat-content {
  align-items: center;
}

.mat-menu {
  background-color: #ffffff;
}

.mat-menu-content {
  background-color: #ffffff;
}

.mat-menu-content a {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 2rem 0.5rem 1rem;
  cursor: pointer;
  text-decoration: none;
}

.mat-menu-content a .copy1 {
  color: #0074de;
  font-family: "TheSansBold", Arial, sans-serif;
}

.mat-menu-content a .icon {
  margin-right: 0.75rem;
}

.mat-menu-content a:hover {
  background-color: rgba(0, 35, 62, 0.06);
}

.mat-menu-content a:hover .copy1 {
  color: #0059ab;
}

.mat-menu-content a:hover .icon {
  background-color: #0059ab;
}

#video-player-modal .videoWrapper {
  padding: 3rem 0 0 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 48rem) {
  #video-player-modal .videoWrapper {
    padding: 2rem;
  }
}
#video-player-modal .videoWrapper iframe {
  width: 100%;
  height: 100%;
  max-height: 360px;
  box-shadow: 0px 4px 12px rgba(29, 29, 31, 0.1);
}

@media (min-width: 48rem) {
  #video-player-modal .videoWrapper iframe {
    height: 45vw;
    max-height: 600px;
  }
}
.dynamicAccordionWrapper h2 {
  padding-bottom: 0.5rem;
}

/** MAT DIALOG */
.doehlerMatDialog {
  width: 100%;
  height: 100%;
  max-width: 100vw !important;
  /** Track */
  /** Handle on hover */
}

@media (min-width: 48rem) {
  .doehlerMatDialog {
    height: auto;
    max-width: 48rem !important;
  }
}
@media (min-width: 48rem) {
  .doehlerMatDialog.small {
    max-width: 560px !important;
  }
}
@media (min-width: 48rem) {
  .doehlerMatDialog.medium {
    max-width: 64rem !important;
  }
}
@media (min-width: 48rem) {
  .doehlerMatDialog.large {
    max-width: 64rem !important;
  }
}
@media (min-width: 48rem) and (min-width: 90rem) {
  .doehlerMatDialog.large {
    max-width: 90rem !important;
  }
}
.doehlerMatDialog.full {
  height: 100%;
  max-width: 100% !important;
  background-color: #00233e;
  padding-top: const(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.doehlerMatDialog.full .mat-dialog-container {
  background-color: #4a4c50;
  border-radius: 1.25rem 1.25rem 0 0;
  padding: 0;
  max-height: unset;
}

.doehlerMatDialog.fullScreen {
  height: 100%;
  max-width: 100% !important;
}

.doehlerMatDialog.fullScreen .mat-dialog-container {
  max-height: 100%;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  padding-right: 0;
  border-radius: 0;
}

.doehlerMatDialog.fullScreen .close-button {
  position: unset;
  top: unset;
  left: unset;
}

.doehlerMatDialog.fullScreen .pageTeaser .teaserInner .headlineArea {
  margin-left: 2.5rem !important;
}

@media (min-width: 48rem) {
  .doehlerMatDialog.wide {
    max-width: min(90rem, 100vw - 2rem) !important;
  }
}
.doehlerMatDialog .close-button {
  width: 2rem;
  height: 2rem;
  padding: 0;
  border-radius: 50%;
  background: #1d1d1f;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
}

.doehlerMatDialog .close-button:hover {
  background: #4a4c50;
}

.doehlerMatDialog .close-button .icon.cross {
  width: 1.5rem;
  height: 1.5rem;
  top: 0;
  left: 0;
  background-color: #ffffff;
  mask-image: url(/shared-assets/icons/basic/close_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.doehlerMatDialog .mat-dialog-container {
  width: 100vw;
  background-color: #ffffff;
  overflow-y: unset;
  position: relative;
  padding: 1.5rem 1rem 1.25rem 1rem;
  padding-top: calc(24px + var(--safe-area-inset-top));
  padding-bottom: calc(20px + var(--safe-area-inset-bottom));
  border-radius: 0;
  max-height: 100vh;
  height: 100%;
  /** Special behaviour when button container is placed within a jw modal */
}

.doehlerMatDialog .mat-dialog-container .isLoading {
  max-height: calc(100vh - 3rem);
  overflow: hidden;
}

@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container .isLoading {
    max-height: calc(90vh - 5rem);
  }
}
@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container > * {
    width: 100%;
  }
}
.doehlerMatDialog .mat-dialog-container > *:first-child > div:first-child {
  height: 100%;
}

@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container > *:first-child > div:first-child {
    height: auto;
  }
}
.doehlerMatDialog .mat-dialog-container > *:nth-last-child(2) {
  margin-bottom: auto;
}

.doehlerMatDialog .mat-dialog-container > * > * {
  position: relative;
}

@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container {
    max-height: 90vh;
    border-radius: 0.875rem;
    padding: 2.5rem 2.5rem 2rem 2.5rem;
    padding-top: calc(40px + var(--safe-area-inset-top));
    padding-bottom: calc(32px + var(--safe-area-inset-bottom));
  }
}
.doehlerMatDialog .mat-dialog-container .form-content {
  height: 100%;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container .form-content {
    height: auto;
  }
}
.doehlerMatDialog .mat-dialog-container .form-content > *:not(.form-header):not(.modal-header):not(app-loading-content):not(.close-button) {
  height: 100%;
}

@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container .form-content > *:not(.form-header):not(.modal-header):not(app-loading-content):not(.close-button) {
    height: auto;
  }
}
.doehlerMatDialog .mat-dialog-container .form-content .form-header,
.doehlerMatDialog .mat-dialog-container .form-content .modal-header {
  position: relative;
}

.doehlerMatDialog .mat-dialog-container .form-content .form-header h1,
.doehlerMatDialog .mat-dialog-container .form-content .form-header h2,
.doehlerMatDialog .mat-dialog-container .form-content .modal-header h1,
.doehlerMatDialog .mat-dialog-container .form-content .modal-header h2 {
  padding-bottom: 1rem;
  padding-right: 1.75rem;
}

.doehlerMatDialog .mat-dialog-container .form-content .form-header h1.doublePadding,
.doehlerMatDialog .mat-dialog-container .form-content .form-header h2.doublePadding,
.doehlerMatDialog .mat-dialog-container .form-content .modal-header h1.doublePadding,
.doehlerMatDialog .mat-dialog-container .form-content .modal-header h2.doublePadding {
  padding-bottom: 2rem;
}

.doehlerMatDialog .mat-dialog-container .form-content .form-header p,
.doehlerMatDialog .mat-dialog-container .form-content .modal-header p {
  padding-bottom: 0.5rem;
}

.doehlerMatDialog .mat-dialog-container .form-content .form-header p.copy1,
.doehlerMatDialog .mat-dialog-container .form-content .modal-header p.copy1 {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.doehlerMatDialog .mat-dialog-container .specificationModalInner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.doehlerMatDialog .mat-dialog-container .specificationModalInner .modalContent {
  flex-grow: 1;
  width: 100%;
}

.doehlerMatDialog .mat-dialog-container .specificationModalInner .modalContent .topPadding {
  padding-top: 1rem;
}

.doehlerMatDialog .mat-dialog-container .specificationModalInner .modalContent h1,
.doehlerMatDialog .mat-dialog-container .specificationModalInner .modalContent h2 {
  padding-bottom: 1rem;
  padding-right: 2rem;
}

.doehlerMatDialog .mat-dialog-container .specificationModalInner .modalContent h1.doublePadding,
.doehlerMatDialog .mat-dialog-container .specificationModalInner .modalContent h2.doublePadding {
  padding-bottom: 2rem;
}

.doehlerMatDialog .mat-dialog-container .specificationModalInner .modalContent h3 {
  padding-bottom: 0.75rem;
}

.doehlerMatDialog .mat-dialog-container .reload-page-modal-inner h1 {
  padding-bottom: 1.5rem;
}

.doehlerMatDialog .mat-dialog-container .button-container:not(.noMobileStretch) {
  flex-wrap: nowrap;
  flex-direction: column-reverse;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
}

.doehlerMatDialog .mat-dialog-container .button-container:not(.noMobileStretch) button {
  width: calc(100% - 1rem);
  min-height: 3rem;
}

@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container .button-container:not(.noMobileStretch) button {
    width: auto;
  }
}
@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container .button-container:not(.noMobileStretch) {
    border: none;
    flex-direction: row;
    margin: -0.5rem;
    padding: 0.5rem 0 0 0;
    width: calc(100% + 1rem) !important;
  }
}
@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container .button-container:not(.noMobileStretch).addTopPadding {
    padding: 2rem 0 0 0;
  }
}
@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container .button-container:not(.noMobileStretch).morePadding {
    padding: 3.5rem 0 0 0;
  }
}
.doehlerMatDialog .mat-dialog-container .button-container:not(.noMobileStretch).noPadding {
  padding: 0;
  width: calc(100% + 1rem);
}

.doehlerMatDialog .mat-dialog-container .button-container:not(.noMobileStretch).withSpacing {
  padding-top: 0.5rem;
  margin-top: auto;
}

@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container .button-container:not(.noMobileStretch).withSpacing {
    flex-direction: row;
    margin: 0 -0.5rem;
    padding: 0;
    width: calc(100% + 1rem) !important;
  }
}
@media (min-width: 48rem) {
  .doehlerMatDialog .mat-dialog-container .button-container:not(.centered) > button:nth-last-child(2) {
    margin-left: auto;
  }
}
.doehlerMatDialog.disablePadding .close-button {
  position: fixed;
  top: 1.5rem;
  right: 1rem;
}

.doehlerMatDialog.disablePadding .mat-dialog-container {
  padding: 0;
}

.doehlerMatDialog.disablePadding .button-container {
  height: 5.25rem;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0.3rem 1rem 0 1rem;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  background-color: #ffffff;
  box-shadow: 0px 20px 75px rgba(0, 0, 0, 0.1);
}

.doehlerMatDialog.disablePadding .button-container button {
  margin: 0 1.25rem 0 0;
  white-space: nowrap;
}

.doehlerMatDialog.disablePadding .button-container button.text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.doehlerMatDialog.disablePadding .button-container button:last-child {
  margin: 0;
}

.doehlerMatDialog.dynamicIframeModal .mat-dialog-container .ng-star-inserted {
  width: 100%;
  height: 100%;
  display: block;
}

.doehlerMatDialog.foodCertificatesFilter {
  background-color: #ffffff;
}

.doehlerMatDialog::-webkit-scrollbar-track,
.doehlerMatDialog ::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.doehlerMatDialog::-webkit-scrollbar,
.doehlerMatDialog ::-webkit-scrollbar {
  width: 10px;
  background-color: #ffffff;
}

.doehlerMatDialog::-webkit-scrollbar-thumb,
.doehlerMatDialog ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c8cbd4;
  border: 2px solid #ffffff;
}

.doehlerMatDialog::-webkit-scrollbar-thumb:hover,
.doehlerMatDialog ::-webkit-scrollbar-thumb:hover {
  background: #c8cbd4;
}

.doehlerMatDialog.user-admin-confirmation-dialog {
  height: auto;
  align-self: end;
}

@media (min-width: 64rem) {
  .doehlerMatDialog.user-admin-confirmation-dialog {
    align-self: unset;
  }
}
.doehlerMatDialog.user-admin-confirmation-dialog .mat-dialog-container {
  border-radius: 1.25rem 1.25rem 0 0;
  padding: 2rem;
}

@media (min-width: 64rem) {
  .doehlerMatDialog.user-admin-confirmation-dialog .mat-dialog-container {
    border-radius: 1.25rem;
  }
}
/** TOAST MESSAGES */
.toast-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.toast-wrapper .toast-message {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}

.toast-wrapper .toast-message .toast-icon {
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
}

@media (min-width: 48rem) {
  .toast-wrapper .toast-message .toast-icon {
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
  }
}
.toast-wrapper .toast-message .toast-icon.icon-info {
  background-color: #00233e;
  mask-image: url(/shared-assets/icons/basic/info_circle.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.toast-wrapper .toast-message .toast-icon.icon-success {
  background-color: #79e884;
  mask-image: url(/shared-assets/icons/basic/check_circle_plain.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.toast-wrapper .toast-message .toast-icon.icon-error {
  background-color: #ff256f;
  mask-image: url(/shared-assets/icons/basic/exclamation_mark_circle_plain.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.toast-wrapper .toast-message .toast-label {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  color: #00233e;
  padding: 0 0.75rem;
}

.toast-wrapper .toast-close {
  float: right;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  background-color: #c8cbd4;
  mask-image: url(/shared-assets/icons/basic/close_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.toast-wrapper .toast-close:hover {
  background-color: #0074de;
}

.mat-snack-bar-container {
  background-color: #ffffff;
  min-width: 550px;
  padding: 1rem;
  border-radius: 0.25rem;
}

@media (min-width: 48rem) {
  .mat-snack-bar-container {
    max-width: 650px;
    padding: 1.25rem 1rem;
  }
}
.cdk-overlay-container {
  z-index: 999999 !important;
}

.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing {
  background: rgba(29, 29, 31, 0.5);
}

/** Drawer, Sidenav, FlyIn */
.mat-drawer-container {
  background: transparent !important;
}

.mat-drawer-container,
.mat-sidenav-container {
  position: initial !important;
  height: auto;
}

.mat-drawer-container .mat-drawer-backdrop,
.mat-sidenav-container .mat-drawer-backdrop {
  z-index: 99999 !important;
}

.mat-drawer-container .mat-drawer,
.mat-drawer-container .mat-sidenav,
.mat-sidenav-container .mat-drawer,
.mat-sidenav-container .mat-sidenav {
  z-index: 100000 !important;
}

.mat-drawer-container .mat-drawer .drawerInnerContainer,
.mat-drawer-container .mat-sidenav .drawerInnerContainer,
.mat-sidenav-container .mat-drawer .drawerInnerContainer,
.mat-sidenav-container .mat-sidenav .drawerInnerContainer {
  height: 100%;
  overflow-x: hidden;
  min-width: 100vw;
}

@media (min-width: 48rem) {
  .mat-drawer-container .mat-drawer .drawerInnerContainer,
.mat-drawer-container .mat-sidenav .drawerInnerContainer,
.mat-sidenav-container .mat-drawer .drawerInnerContainer,
.mat-sidenav-container .mat-sidenav .drawerInnerContainer {
    min-width: 480px;
    max-width: min(66vw, 900px);
  }
}
@media (min-width: 48rem) and (min-width: 90rem) {
  .mat-drawer-container .mat-drawer .drawerInnerContainer,
.mat-drawer-container .mat-sidenav .drawerInnerContainer,
.mat-sidenav-container .mat-drawer .drawerInnerContainer,
.mat-sidenav-container .mat-sidenav .drawerInnerContainer {
    max-width: min(66vw, 1150px);
  }
}
.mat-drawer-container .mat-drawer .drawerInnerContainer .closeDrawerIcon,
.mat-drawer-container .mat-sidenav .drawerInnerContainer .closeDrawerIcon,
.mat-sidenav-container .mat-drawer .drawerInnerContainer .closeDrawerIcon,
.mat-sidenav-container .mat-sidenav .drawerInnerContainer .closeDrawerIcon {
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  top: calc(1rem + var(--safe-area-inset-top));
  right: 1.1rem;
  padding: 0;
  margin: 0;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  border: none;
  outline: none;
  z-index: 98;
  background-color: #f9fafc;
}

@media (min-width: 48rem) {
  .mat-drawer-container .mat-drawer .drawerInnerContainer .closeDrawerIcon,
.mat-drawer-container .mat-sidenav .drawerInnerContainer .closeDrawerIcon,
.mat-sidenav-container .mat-drawer .drawerInnerContainer .closeDrawerIcon,
.mat-sidenav-container .mat-sidenav .drawerInnerContainer .closeDrawerIcon {
    right: 1.5rem;
  }
}
.mat-drawer-container .mat-drawer .drawerInnerContainer .closeDrawerIcon:hover,
.mat-drawer-container .mat-sidenav .drawerInnerContainer .closeDrawerIcon:hover,
.mat-sidenav-container .mat-drawer .drawerInnerContainer .closeDrawerIcon:hover,
.mat-sidenav-container .mat-sidenav .drawerInnerContainer .closeDrawerIcon:hover {
  background-color: rgba(0, 35, 62, 0.06);
}

.mat-drawer-container .mat-drawer .drawerInnerContainer .closeDrawerIcon:hover .close-icon,
.mat-drawer-container .mat-sidenav .drawerInnerContainer .closeDrawerIcon:hover .close-icon,
.mat-sidenav-container .mat-drawer .drawerInnerContainer .closeDrawerIcon:hover .close-icon,
.mat-sidenav-container .mat-sidenav .drawerInnerContainer .closeDrawerIcon:hover .close-icon {
  background-color: #0074de;
}

.mat-drawer-container .mat-drawer .drawerInnerContainer .closeDrawerIcon .close-icon,
.mat-drawer-container .mat-sidenav .drawerInnerContainer .closeDrawerIcon .close-icon,
.mat-sidenav-container .mat-drawer .drawerInnerContainer .closeDrawerIcon .close-icon,
.mat-sidenav-container .mat-sidenav .drawerInnerContainer .closeDrawerIcon .close-icon {
  z-index: 99;
  position: absolute;
  top: 0.075rem;
  right: 0.075rem;
  cursor: pointer;
  width: 2.25rem;
  height: 2.25rem;
  background-color: #457ddb;
  mask-image: url(/shared-assets/icons/basic/close_single.svg);
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.user-detail-dialog {
  animation: 300ms ease-out 0s 1 slideInFromBottom;
}

/* TOOLTIPS */
.mat-tooltip-panel .mat-tooltip {
  background-color: #00233e;
  overflow: visible;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  line-height: 130%;
  text-align: center;
  white-space: nowrap;
  padding: 0.5rem 1rem;
}

.mat-tooltip-panel .mat-tooltip.inverted {
  background-color: #ffffff;
  color: #00233e;
}

.mat-tooltip-panel .mat-tooltip.small {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 130%;
  border-radius: 0.25rem;
}

.mat-tooltip-panel.mat-tooltip-panel-above .mat-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: #00233e transparent transparent transparent;
}

.mat-tooltip-panel.mat-tooltip-panel-above .mat-tooltip.inverted::after {
  border-color: #ffffff transparent transparent transparent;
}

.mat-tooltip-panel.mat-tooltip-panel-below .mat-tooltip::after {
  content: "";
  position: absolute;
  top: auto;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #00233e transparent;
}

.mat-tooltip-panel.mat-tooltip-panel-below .mat-tooltip.inverted::after {
  border-color: transparent transparent #ffffff transparent;
}

.mat-tooltip-panel.mat-tooltip-panel-left .mat-tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 100%;
  right: auto;
  margin-top: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #00233e;
}

.mat-tooltip-panel.mat-tooltip-panel-left .mat-tooltip.inverted::after {
  border-color: transparent transparent transparent #ffffff;
}

.mat-tooltip-panel.mat-tooltip-panel-right .mat-tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  bottom: auto;
  left: auto;
  right: 100%;
  margin-top: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent #00233e transparent transparent;
}

.mat-tooltip-panel.mat-tooltip-panel-right .mat-tooltip.inverted::after {
  border-color: transparent #ffffff transparent transparent;
}

.mat-tooltip-panel.mat-tooltip-panel-above .mat-tooltip.small::after, .mat-tooltip-panel.mat-tooltip-panel-below .mat-tooltip.small::after, .mat-tooltip-panel.mat-tooltip-panel-left .mat-tooltip.small::after, .mat-tooltip-panel.mat-tooltip-panel-right .mat-tooltip.small::after {
  content: "";
  position: static;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  margin: 0;
  border-width: 0px;
  border-style: none;
  border-color: transparent;
}

.switch-container {
  /* The switch - the box around the slider */
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}

.switch-container .switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

@media (min-width: 48rem) {
  .switch-container .switch {
    width: 50px;
    height: 30px;
  }
}
.switch-container .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-container .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c8cbd4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-container .slider:before {
  position: absolute;
  content: "";
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
}

@media (min-width: 48rem) {
  .switch-container .slider:before {
    height: 24px;
    width: 24px;
    left: 3px;
    bottom: 3px;
  }
}
.switch-container input:checked + .slider {
  background-color: #0074de;
}

.switch-container input:focus + .slider {
  box-shadow: 0 0 1px #0074de;
}

.switch-container input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

@media (min-width: 48rem) {
  .switch-container input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}
.switch-container .slider.round {
  border-radius: 34px;
}

.switch-container .slider.round:before {
  border-radius: 50%;
}

.dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container {
  justify-content: center;
}

.dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button {
  padding: 0;
  width: 1.3rem;
  height: 1.3rem;
  line-height: 1.3rem;
  text-align: center;
  vertical-align: middle;
  font-size: 0.75rem;
}

.dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-previous, .dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-next,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-previous,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-next {
  color: #4a4c50;
}

.dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-previous .mat-button-wrapper, .dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-next .mat-button-wrapper,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-previous .mat-button-wrapper,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-next .mat-button-wrapper {
  display: inline-block;
  height: 1.3rem;
  line-height: 1.3rem;
  text-align: center;
  vertical-align: middle;
}

.dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-previous .mat-button-wrapper .mat-paginator-icon, .dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-next .mat-button-wrapper .mat-paginator-icon,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-previous .mat-button-wrapper .mat-paginator-icon,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-next .mat-button-wrapper .mat-paginator-icon {
  display: block;
  height: 1.3rem;
  width: 1.3rem;
}

.dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-previous.mat-button-disabled, .dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-next.mat-button-disabled,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-previous.mat-button-disabled,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-next.mat-button-disabled {
  display: none;
}

.dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-previous:hover, .dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-next:hover,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-previous:hover,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.mat-paginator-navigation-next:hover {
  color: #4a4c50;
  background: transparent !important;
  background-color: transparent !important;
}

.dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.custom-paginator-pagebutton,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.custom-paginator-pagebutton {
  border-radius: 99rem;
  background-color: transparent;
  border-radius: 99rem;
  color: #00233e;
  margin-left: 4px;
  margin-right: 4px;
}

.dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.custom-paginator-pagebutton.current-page, .dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.custom-paginator-pagebutton.current-page.disabled,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.custom-paginator-pagebutton.current-page,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.custom-paginator-pagebutton.current-page.disabled {
  background-color: #0074de;
  color: #ffffff;
}

.dashboard-projects-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.custom-paginator-pagebutton:hover,
.food-certificate-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions button.custom-paginator-pagebutton:hover {
  color: #4a4c50;
}

.dashboard-projects-paginator {
  margin-top: 1rem;
}

.mat-tab-group .mat-tab-header-pagination {
  background: transparent;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.mat-tab-group .mat-tab-header-pagination:hover {
  background: transparent;
}

.mat-tab-group .mat-tab-header-pagination:hover .mat-tab-header-pagination-chevron {
  border-color: #4a4c50;
}

.mat-tab-group .mat-tab-header-pagination.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #e3e5eb;
}

.mat-tab-group .mat-tab-header-pagination .mat-tab-header-pagination-chevron {
  border-color: #9598a1;
}

@font-face {
  font-family: TheSans;
  src: url("/shared-assets/fonts/TheSansPlain.eot") format("eot"), url("/shared-assets/fonts/TheSansPlain.woff") format("woff"), url("/shared-assets/fonts/TheSansPlain.woff2") format("woff2"), url("/shared-assets/fonts/TheSansPlain.ttf") format("truetype");
}
@font-face {
  font-family: TheSansBold;
  src: url("/shared-assets/fonts/TheSansBold.eot") format("eot"), url("/shared-assets/fonts/TheSansBold.woff") format("woff"), url("/shared-assets/fonts/TheSansBold.woff2") format("woff2"), url("/shared-assets/fonts/TheSansBold.ttf") format("truetype");
}
@font-face {
  font-family: TheSansLight;
  src: url("/shared-assets/fonts/TheSansLight.eot") format("eot"), url("/shared-assets/fonts/TheSansLight.woff") format("woff"), url("/shared-assets/fonts/TheSansLight.woff2") format("woff2"), url("/shared-assets/fonts/TheSansLight.ttf") format("truetype");
}
/* NEW FONT SIZES */
/* BASIC DISTANCES / SPACINGS */
/* MAIN BRAND COLOURS */
/* before instant change:  
$brand-pale-grey: #f5f5f5;
*/
/* SECONDARY COLOURS*/
/* INTERACTION COLOURS - light background */
/* INTERACTION SECONDARY COLOURS - light background */
/* INTERACTION COLOURS - dark background */
/* ICON COLORS */
/* SIGNAL COLOURS | SYSTEM UI */
/* ASSIGNMENT OF CHART COLOURS */
/* ------------------------------------------------------------------- */
/* SPECIAL COLOURS - not part of the palette */
/* SPECTRUM COLOURS - usable for gradients */
/* BACKGROUND COLOURS */
/* BOX OVERLAYS */
/* BOX SHADOWS */
/* BORDER RADIUS */
/* INTEGRATION FROM SOCIAL MEDIA NEWS VIA FLOCKLER - CUSTOM STYLING */
.socialMediaNewsContainer .flockler-grid-item__profile__name {
  color: #00233e;
  font-size: 1rem;
}

.socialMediaNewsContainer .flockler-grid-item__profile__username {
  color: #4a4c50;
}

/* INTEGRATION OF USERCENTRICS / COOKIE WALL - SOME ADJUSTMENTS */
#uc-banner-centered .uc-banner-content .uc-banner-btn-list .uc-btn-accept-wrapper {
  margin: 0 !important;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}

.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}

.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}

.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}

.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}

.mat-list-base .mat-list-option {
  font-size: 16px;
}

.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}

.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}

.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}

.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}

[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}

.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "TheSans", Arial, sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px "TheSans", Arial, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px "TheSans", Arial, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
}

.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px "TheSans", Arial, sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px "TheSans", Arial, sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px "TheSans", Arial, sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-card {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}

.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: "TheSans", Arial, sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}

.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}

.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}

.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }

  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}

.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}

.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}

.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}

.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-select {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-list-option {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}

.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}

.mat-list-base .mat-list-option {
  font-size: 16px;
}

.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}

.mat-list-base .mat-subheader {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}

.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}

.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-list-base[dense] .mat-subheader {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px "TheSans", Arial, sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: "TheSans", Arial, sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: "TheSans", Arial, sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00233e;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0073cc;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff256f;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #00233e;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #0073cc;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff256f;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}

.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}

.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}

.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}

[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}

.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}

[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}

[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}

.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}

.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}

.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}

[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}

.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}

[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}

.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}

[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}

.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}

.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}

[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}

.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}

[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}

.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}

[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #00233e;
}

.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #0073cc;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff256f;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}

.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #00233e;
}

.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #0073cc;
}

.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #ff256f;
}

.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}

.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #00233e;
}

.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #0073cc;
}

.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff256f;
}

.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}

.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}

.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}

.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #00233e;
}

.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #0073cc;
}

.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #ff256f;
}

.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}

.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}

.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}

.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #00233e;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0073cc;
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff256f;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}

.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #00233e;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #0073cc;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff256f;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}

.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}

.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}

.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #00233e;
  color: white;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff256f;
  color: white;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #0073cc;
  color: white;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(0, 35, 62, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 35, 62, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 35, 62, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #00233e;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 35, 62, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 35, 62, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 35, 62, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 115, 204, 0.2);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 115, 204, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 115, 204, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #0073cc;
  color: white;
}

.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 115, 204, 0.4);
}

.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 115, 204, 0.3);
}

@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 115, 204, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 37, 111, 0.2);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 37, 111, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 37, 111, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff256f;
  color: white;
}

.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 37, 111, 0.4);
}

.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 37, 111, 0.3);
}

@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 37, 111, 0.3);
  }
}
.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #00233e;
}

.mat-datepicker-toggle-active.mat-accent {
  color: #0073cc;
}

.mat-datepicker-toggle-active.mat-warn {
  color: #ff256f;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}

.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}

.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #00233e;
}

.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #0073cc;
}

.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff256f;
}

.mat-focused .mat-form-field-required-marker {
  color: #0073cc;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #00233e;
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #0073cc;
}

.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff256f;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #00233e;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #0073cc;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff256f;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff256f;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff256f;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff256f;
}

.mat-error {
  color: #ff256f;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #00233e;
}

.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #0073cc;
}

.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff256f;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff256f;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #00233e;
}

.mat-icon.mat-accent {
  color: #0073cc;
}

.mat-icon.mat-warn {
  color: #ff256f;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #00233e;
}

.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #0073cc;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #ff256f;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff256f;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}

.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}

.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}

.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #bcc4cb;
}

.mat-progress-bar-buffer {
  background-color: #bcc4cb;
}

.mat-progress-bar-fill::after {
  background-color: #00233e;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bcd8ef;
}

.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bcd8ef;
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #0073cc;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbc5d7;
}

.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbc5d7;
}

.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff256f;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #00233e;
}

.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #0073cc;
}

.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #ff256f;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00233e;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #00233e;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0073cc;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #0073cc;
}

.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff256f;
}

.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff256f;
}

.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}

.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}

.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #00233e;
}

.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #0073cc;
}

.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff256f;
}

.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff256f;
}

.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer.mat-drawer-push {
  background-color: white;
}

.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0073cc;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 115, 204, 0.54);
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #0073cc;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #00233e;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 35, 62, 0.54);
}

.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #00233e;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff256f;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 37, 111, 0.54);
}

.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff256f;
}

.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #00233e;
}

.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 35, 62, 0.2);
}

.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #0073cc;
}

.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 115, 204, 0.2);
}

.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff256f;
}

.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}

.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 37, 111, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}

.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}

@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}

.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #00233e;
  color: white;
}

.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}

.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #0073cc;
  color: white;
}

.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}

.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff256f;
  color: white;
}

.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff256f;
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}

.mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff256f;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 189, 197, 0.3);
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00233e;
}

.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}

.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(77, 157, 219, 0.3);
}

.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #0073cc;
}

.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}

.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 190, 212, 0.3);
}

.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff256f;
}

.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}

.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 189, 197, 0.3);
}

.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #00233e;
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}

.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(77, 157, 219, 0.3);
}

.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #0073cc;
}

.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}

.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}

.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}

.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 190, 212, 0.3);
}

.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff256f;
}

.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}

.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}

.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}

.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}

.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  background: #00233e;
  color: white;
}

.mat-toolbar.mat-accent {
  background: #0073cc;
  color: white;
}

.mat-toolbar.mat-warn {
  background: #ff256f;
  color: white;
}

.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}

.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}

.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #0073cc;
}