///////////////////////////
// *** MEDIA QUERIES *** //
///////////////////////////

@use 'variables' as v;

// NEW MEDIA QUERIES (a reduced set!)
@mixin smallMobile {
  @media #{v.$smallMobileBreakpoint} {
    @content;
  }
}
@mixin mobile {
  @media #{v.$mobileBreakpoint} {
    @content;
  }
}
@mixin tablet {
  @media #{v.$tabletBreakpoint} {
    @content;
  }
}
@mixin desktop {
  @media #{v.$desktopBreakpoint} {
    @content;
  }
}
@mixin largeDesktop {
  @media #{v.$largeDesktopBreakpoint} {
    @content;
  }
}
@mixin contentBox {
  @media #{v.$contentBoxBreakpoint} {
    @content;
  }
}
@mixin largeContentBox {
  @media #{v.$largeContentBoxBreakpoint} {
    @content;
  }
}
@mixin veryLargeDesktop {
  @media #{v.$veryLargeDesktopBreakpoint} {
    @content;
  }
}
