////////////////////////////////////
// *** BUTTONS, TOGGLES, ETC. *** //
////////////////////////////////////

@use 'abstracts/variables' as v;
@use 'abstracts/mediaQueries' as mq;
@use 'abstracts/mixins' as m;

/* BUTTONS */
/* Default button is also PRIMARY button! */
button,
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: calc(0.75rem - 2px) calc(2.5rem - 2px);
  font-family: v.$bold-font;
  font-size: v.$font-size-xxs;
  line-height: v.$line-height;
  text-decoration: none;
  border-radius: v.$button-border-radius;
  border: 2px solid transparent;
  background: v.$interaction-default;
  color: v.$white;
  transition: all ease-in-out 0.3s;
  outline: none;
  &.rounded {
    border-radius: v.$button-border-radius-full;
  }
  &.solid:not(.custom-paginator-pagebutton),
  &.primary:not(.custom-paginator-pagebutton) {
    min-width: 130px;
  }

  &.smallPrimary {
    min-width: 32px !important;
    padding: calc(0.75rem - 2px);
    background: v.$brand-pale-grey;
    color: v.$brand-dark-grey;

    &.withIcon {
      color: v.$brand-dark-grey;
      position: relative;
      display: flex;
      justify-content: space-between;
      &:before {
        transition: all ease-in-out 0.3s;
        content: '';
        position: relative;
        background-color: v.$brand-dark-grey;
        width: 1.5rem;
        height: 1.5rem;
      }
      &.plus:before {
        @include m.colored-bg-svg($icon: 'basic/add_plus_single.svg');
      }
      &.minus:before {
        @include m.colored-bg-svg($icon: 'basic/add_minus_single.svg');
      }
      &.leftArrow:before {
        @include m.colored-bg-svg($icon: 'basic/chevron_left_2.svg');
      }
      &.rightArrow:before {
        @include m.colored-bg-svg($icon: 'basic/chevron_right_2.svg');
      }
    }

    &:hover,
    &.hovered {
      background: v.$brand-light-grey;
      color: v.$brand-black;
    }
    &.disabled,
    &:disabled {
      background: v.$brand-pale-grey;
      opacity: 0.8;
      &.withIcon {
        &:before {
          background-color: v.$brand-mid-grey;
        }
      }
    }
  }

  &:hover,
  &.hovered {
    background: v.$interaction-hover;
  }

  &.disabled,
  &:disabled {
    background: v.$interaction-disabled-bg;
    color: v.$interaction-disabled;
    cursor: not-allowed;
  }

  &.outline,
  &.secondary {
    min-width: 130px;
    background: transparent;
    color: v.$interaction-secondary-default;
    border-color: v.$interaction-secondary-default;

    &:hover,
    &.hovered {
      color: v.$white;
      border-color: v.$interaction-hover;
      background: v.$interaction-hover;

      &.iconLeft {
        color: v.$white;
        &:before {
          background-color: v.$white !important;
        }
      }
      &.iconRight {
        color: v.$white;
        &:after {
          background-color: v.$white !important;
        }
      }
    }

    &.disabled,
    &:disabled {
      background: transparent;
      border-color: v.$interaction-disabled-bg;
      color: v.$interaction-disabled;
      &:hover,
      &.hovered {
        &.iconLeft {
          &:before {
            background-color: v.$interaction-disabled !important;
          }
        }
        &.iconRight {
          &:after {
            background-color: v.$interaction-disabled !important;
          }
        }
      }
    }

    &.iconLeft {
      color: v.$interaction-secondary-default;
      &:before {
        background-color: v.$interaction-secondary-default !important;
      }
    }
    &.iconRight {
      color: v.$interaction-secondary-default;
      &:after {
        background-color: v.$interaction-secondary-default !important;
      }
    }
  }

  &.text,
  &.link,
  &.tertiary {
    min-width: 130px;
    background: transparent;
    border-radius: 0;
    color: v.$interaction-secondary-default;

    &.noPadding {
      min-width: 32px;
      padding: 0 !important;
    }

    &.noMinWidth {
      min-width: 24px;
    }
    &.text {
      min-width: 24px;
      padding: 0.75rem 0.25rem;

      &.addPadding {
        padding: 0.75rem 1rem;
      }
    }

    &:hover,
    &.hovered {
      text-decoration: underline;
    }

    &.disabled,
    &:disabled {
      color: v.$interaction-disabled;
      &:hover,
      &.hovered {
        text-decoration: none;
        &.iconLeft {
          &:before {
            background-color: v.$interaction-disabled !important;
          }
        }
        &.iconRight {
          &:after {
            background-color: v.$interaction-disabled !important;
          }
        }
      }
    }

    &.iconLeft {
      color: v.$interaction-secondary-default;
      padding: calc(0.75rem - 2px) calc(1.5rem - 2px);
      &:before {
        background-color: v.$interaction-secondary-default !important;
      }
    }
    &.iconRight {
      color: v.$interaction-secondary-default;
      padding: calc(0.75rem - 2px) calc(1.5rem - 2px);
      &:after {
        background-color: v.$interaction-secondary-default !important;
      }
    }
  }

  &.iconLeft {
    color: v.$white;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: calc(0.75rem - 2px) calc(1.5rem - 2px) calc(0.75rem - 2px) calc(0.75rem - 2px);

    &:before {
      transition: all ease-in-out 0.3s;
      content: '';
      position: relative;
      background-color: v.$white !important;
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      margin-right: 0.5rem;
    }

    &.disabled,
    &:disabled {
      color: v.$interaction-disabled !important;
      &:before {
        background-color: v.$interaction-disabled !important;
      }
    }

    &.contact:before {
      @include m.colored-bg-svg($icon: 'basic/message.svg');
    }
    &.edit:before {
      @include m.colored-bg-svg($icon: 'basic/update.svg');
    }
    &.close-ticket:before {
      @include m.colored-bg-svg($icon: 'basic/sign_out.svg');
    }
    &.open-ticket:before {
      @include m.colored-bg-svg($icon: 'basic/update.svg');
    }
    &.new-messages:before {
      @include m.colored-bg-svg($icon: 'basic/new_messages.svg');
    }
    &.share:before {
      @include m.colored-bg-svg($icon: 'basic/share.svg');
    }
    &.history:before {
      @include m.colored-bg-svg($icon: 'basic/checklist.svg');
    }
    &.external:before {
      @include m.colored-bg-svg($icon: 'basic/external_link.svg');
    }
    &.delete:before {
      @include m.colored-bg-svg($icon: 'basic/delete.svg');
    }
    &.duplicate:before {
      @include m.colored-bg-svg($icon: 'basic/duplicate.svg');
    }
    &.arrow:before {
      @include m.colored-bg-svg($icon: 'basic/chevron_left_2.svg');
    }
    &.arrowDown:before {
      @include m.colored-bg-svg($icon: 'basic/chevron_down_2.svg');
      mask-size: 85% !important;
    }
    &.arrowUp:before {
      @include m.colored-bg-svg($icon: 'basic/chevron_up_2.svg');
      mask-size: 85% !important;
    }
    &.profile:before {
      @include m.colored-bg-svg($icon: 'basic/profile.svg');
    }
    &.phone:before {
      @include m.colored-bg-svg($icon: 'basic/phone.svg');
    }
    &.save:before {
      @include m.colored-bg-svg($icon: 'basic/save.svg');
    }
    &.reload:before {
      @include m.colored-bg-svg($icon: 'helper/sync.svg');
      mask-size: 75% !important;
    }
    &.reset:before {
      @include m.colored-bg-svg($icon: 'helper/undo.svg');
      mask-size: 75% !important;
    }
    &.remove:before {
      @include m.colored-bg-svg($icon: 'basic/close_single.svg');
    }
    &.download:before {
      @include m.colored-bg-svg($icon: 'basic/download.svg');
    }
    &.add:before {
      @include m.colored-bg-svg($icon: 'basic/add_plus_circle.svg');
    }
    &.add2:before {
      @include m.colored-bg-svg($icon: 'basic/add_plus_single.svg');
    }
    &.comment:before {
      @include m.colored-bg-svg($icon: 'basic/comment.svg');
    }
    &.createFolder:before {
      @include m.colored-bg-svg($icon: 'basic/create_folder.svg');
    }
    &.upload:before {
      @include m.colored-bg-svg($icon: 'basic/upload.svg');
    }
    &.print:before {
      @include m.colored-bg-svg($icon: 'basic/print.svg');
    }
    &.show:before {
      @include m.colored-bg-svg($icon: 'basic/search.svg');
    }
    &.bookmark:before {
      @include m.colored-bg-svg($icon: 'basic/bookmark.svg');
    }
  }

  &.iconRight {
    color: v.$white;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: calc(0.75rem - 2px) calc(0.75rem - 2px) calc(0.75rem - 2px) calc(1.5rem - 2px);

    &:after {
      transition: all ease-in-out 0.3s;
      content: '';
      position: relative;
      background-color: v.$white !important;
      mask-size: 125% !important;
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
      min-height: 1.5rem;
      margin-left: 0.5rem;
    }

    &.disabled {
      color: v.$interaction-disabled !important;
      &:after {
        background-color: v.$interaction-disabled !important;
      }
    }

    &.arrow:after {
      @include m.colored-bg-svg($icon: 'basic/chevron_right_2.svg');
    }
    &.download:after {
      @include m.colored-bg-svg($icon: 'basic/download.svg');
      mask-size: 85% !important;
    }
    &.filter:after {
      @include m.colored-bg-svg($icon: 'basic/controls.svg');
    }
    &.load:after {
      @include m.colored-bg-svg($icon: 'helper/sync.svg');
    }
  }

  &.inline {
    padding: 0;
    display: inline;
    font-family: v.$plain-font;
    font-size: inherit;
    text-decoration: underline;

    &:hover,
    &.hovered {
      text-decoration: none;
    }
  }

  &.fullWidth {
    width: 100% !important;
  }
}

.mat-datepicker-toggle button.mat-icon-button:hover {
  transition: all 0.3s ease-in-out;
  background: rgba(v.$secondary-highlight-blue, 0.25);
  img {
    opacity: 0.75;
  }
}

/* Buttons on dark or image background = inverted mode */
.inverted button,
.inverted .button {
  &.outline,
  &.secondary {
    background: transparent;
    color: v.$white;
    border-color: v.$white;

    &:hover,
    &.hovered {
      color: v.$white;
      background: v.$interaction-dark-hover;
      border-color: v.$interaction-dark-hover;
    }

    &.disabled,
    &:disabled {
      color: v.$interaction-disabled;
      border-color: v.$interaction-disabled-bg;

      &:hover,
      &.hovered {
        color: v.$interaction-disabled;
        border-color: v.$interaction-disabled-bg;
        background-color: transparent;
      }
    }

    &.iconLeft {
      color: v.$white;
      &:before {
        background-color: v.$white !important;
      }
    }
    &.iconRight {
      color: v.$white;
      &:after {
        background-color: v.$white !important;
      }
    }
  }

  &.text,
  &.link,
  &.tertiary {
    color: v.$white;
    background: transparent;

    &:hover,
    &.hovered {
      text-decoration: underline;
    }

    &.disabled,
    &:disabled,
    &.disabled:hover,
    &:disabled:hover {
      text-decoration: none;
      color: v.$interaction-disabled;
      &.iconLeft {
        color: v.$interaction-disabled;
        &:before {
          background-color: v.$interaction-disabled !important;
        }
      }
      &.iconRight {
        color: v.$white;
        &:after {
          background-color: v.$interaction-disabled !important;
        }
      }
    }

    &.iconLeft {
      color: v.$white;
      &:before {
        background-color: v.$white !important;
      }
    }
    &.iconRight {
      color: v.$white;
      &:after {
        background-color: v.$white !important;
      }
    }
  }
}

/* Buttons placed in a button container [should be default] */
.button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  margin: -0.5rem;
  width: calc(100% + 1rem);

  &.withSpacing {
    margin: 0 -0.5rem;
  }

  &.resetSpacing {
    margin: 0;
  }

  @include mq.desktop {
    flex-wrap: nowrap;
  }

  &.startLeft {
    justify-content: flex-start;
  }

  button,
  .button {
    margin: 0.5rem;
    width: 100%;

    @include mq.smallMobile {
      width: auto;

      &:not(.doNotHide) {
        @include mq.tablet {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    &.goRight {
      @include mq.tablet {
        margin-left: auto !important;
      }
    }
    &.goLeft {
      @include mq.tablet {
        margin-right: auto !important;
      }
    }

    &.fullWidth {
      width: calc(100% - 1rem) !important;
    }
    &.inFlyInContainer {
      width: auto;
    }
  }

  &.centered {
    justify-content: center;
    align-items: normal;
    @include mq.tablet {
      align-items: center;
    }
  }

  &.spread {
    justify-content: space-between;
  }

  &.topLine {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid v.$special-pale-blue;
  }

  &.addTopPadding {
    padding-top: 1.5rem;
  }
}

button.mat-calendar-period-button:hover {
  background-color: transparent;
}

/* Special Buttons: Expandable Text > more Button / Link */
span.moreButton {
  cursor: pointer;
  color: v.$interaction-default;
  font-family: v.$bold-font;
  text-decoration: none;

  &:hover,
  &:visited {
    text-decoration: none;
    color: v.$interaction-hover;
  }
}

/* TOGGLE / FILTER SWITCH */
.toggleContainer {
  margin: -0.5rem;

  .toggle {
    padding: 0.5rem 0.75rem;
    border-radius: v.$small-border-radius;
    background: v.$pale-blue-light;
    color: v.$brand-standard-blue;
    margin: 0.5rem;
    width: auto;
    display: inline-block;

    &:hover {
      background: v.$pale-blue-light;
      cursor: pointer;
    }

    &.selected {
      background: v.$secondary-water-blue;
      color: v.$white;

      &:hover {
        background: v.$pale-blue;
        cursor: pointer;
      }
    }
  }
}
