///////////////////
// *** FORMS *** //
///////////////////

@use 'abstracts/variables' as v;
@use 'abstracts/mediaQueries' as mq;
@use 'abstracts/mixins' as m;

/* INPUT FIELDS - Default Option */
.defaultFormField {
  width: 100%;

  &.reducedSize {
    max-width: 360px;
  }

  .mat-select {
    height: 1.5rem;

    .mat-select-trigger {
      height: 1.3rem;
      padding-top: 0.2rem;
      font-size: v.$font-size-xxs;
      line-height: v.$line-height;
    }

    .mat-select-arrow-wrapper {
      border-left: none;
      border-right: none;
      @include m.colored-bg-svg($color: v.$brand-mid-grey, $icon: 'basic/arrow_down_first_single.svg', $size: 70%);
      transform: translateY(-25%);
    }
  }

  input.mat-input-element {
    margin-top: 0 !important;
  }

  &.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float {
    &.mat-focused {
      .mat-form-field-underline {
        &:before,
        .mat-form-field-ripple {
          height: 0;
          background-color: v.$secondary-highlight-blue !important;
        }
      }

      .mat-form-field-label-wrapper {
        label.mat-form-field-label {
          mat-label,
          .mat-placeholder-required.mat-form-field-required-marker {
            color: v.$interaction-active !important;
          }
        }
      }
      .mat-form-field-flex {
        border: 2px solid v.$secondary-highlight-blue !important;
        padding: 0 0.5rem 0 1rem !important;
      }
      &.mat-primary .mat-select-arrow {
        color: v.$secondary-highlight-blue !important;
      }
    }

    .mat-form-field-label {
      transform: translateY(-1rem) scale(0.73);
      width: 140%;

      mat-label,
      .mat-placeholder-required.mat-form-field-required-marker {
        color: v.$brand-mid-grey !important;
      }
    }
    .mat-form-field-flex {
      background: v.$white !important;
    }
  }

  &.mat-form-field-appearance-fill.mat-form-field-disabled {
    .mat-form-field-flex {
      background-color: v.$white !important;
      cursor: not-allowed;
      opacity: 0.3;

      .mat-form-field-infix {
        input.mat-input-element,
        textarea.mat-input-element {
          cursor: not-allowed;
          color: v.$brand-standard-blue;
        }

        .mat-form-field-label-wrapper {
          label.mat-form-field-label {
            mat-label,
            .mat-placeholder-required.mat-form-field-required-marker {
              color: v.$brand-mid-grey !important;
            }
          }
        }
      }
    }

    .mat-form-field-underline {
      &:before,
      .mat-form-field-ripple {
        background-color: v.$brand-bright-grey;
      }
    }
  }

  .mat-form-field-flex {
    background: v.$brand-pale-grey !important;
    border-radius: v.$small-border-radius !important;
    border: 1px solid v.$brand-bright-grey;
    padding: 1px 0.5rem 1px 1rem !important;
  }

  &.forceWhite {
    .mat-form-field-wrapper .mat-form-field-flex {
      background: v.$white !important;
      background-color: v.$white !important;
      border: 1px solid transparent !important;
    }
  }
  .mat-form-field-underline {
    display: none;
  }

  &.mat-form-field-appearance-fill.mat-form-field-invalid,
  &.mat-form-field-appearance-fill.mat-form-field-invalid.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-flex {
      // background-color: rgba(v.$signal-magenta, 10%) !important;
      // background: rgba(v.$signal-magenta, 10%) !important;
      background-color: v.$white;
      background: v.$white;
      border: 2px solid v.$signal-magenta !important;
      padding: 0 0.5rem 0 1rem !important;
      .mat-form-field-infix {
        input.mat-input-element,
        textarea.mat-input-element,
        .mat-select-value-text,
        .mat-form-field-label-wrapper label.mat-form-field-label mat-label,
        .mat-form-field-label-wrapper label.mat-form-field-label .mat-placeholder-required.mat-form-field-required-marker {
          color: v.$signal-magenta !important;
        }
      }
    }
  }

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      padding: 0;
      background: v.$white;

      .mat-form-field-infix {
        width: auto;
        min-height: 4rem;
        border-top: 0.25rem solid transparent;
        padding: 1.25rem 0 1rem 0;

        input.mat-input-element,
        .mat-select-value-text,
        .mat-select,
        .mat-date-range-input-inner {
          font-size: v.$font-size-xxs;
          line-height: v.$line-height;
          color: v.$brand-standard-blue;
        }

        textarea.mat-input-element {
          min-height: 7.5rem;
          font-size: v.$font-size-xxs;
          line-height: v.$line-height;
          color: v.$brand-standard-blue;
          width: calc(100% - 1rem);
          margin: -1rem 0.5rem -0.25rem 0.5rem;
        }

        .mat-form-field-label-wrapper {
          top: 0;
          padding-top: 0;

          label.mat-form-field-label {
            top: 1.3rem;
            margin-top: 0;
            overflow: visible;

            .mat-placeholder-required.mat-form-field-required-marker {
              color: v.$brand-mid-grey;
            }

            mat-label {
              font-size: v.$font-size-xxs;
              line-height: v.$line-height-special;
              color: v.$brand-mid-grey;
              // display: inline-block;
              overflow: hidden;
              max-width: 100%;
              text-overflow: ellipsis;
              padding-bottom: 0.25rem;

              -ms-overflow-style: none;
              scrollbar-width: none;
              &::-webkit-scrollbar,
              ::-webkit-scrollbar {
                display: none;
              }
            }
          }
        }
      }

      .mat-form-field-suffix {
        color: v.$brand-mid-grey;
        font-size: v.$font-size-xxs;
        line-height: v.$line-height-headlines;
        align-self: center;
        padding: 0.5rem 0 0 0.5rem;
        display: flex;
        align-items: center;
      }
    }

    .mat-form-field-underline {
      &:before,
      .mat-form-field-ripple {
        background-color: rgba(v.$interaction-active, 0.5);
      }
    }
  }

  .mat-select-arrow {
    color: rgba(v.$interaction-active, 0.66);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid;
    margin: 0 6px;
  }
}

/* INPUT FIELDS - Special Option */
/* Only used for target market selection fly in (currently inactive!) */
.specialFormField {
  width: 100%;

  &.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float {
    &.mat-focused {
      .mat-form-field-underline {
        &:before,
        .mat-form-field-ripple {
          height: 0;
          background-color: v.$secondary-highlight-blue !important;
        }
      }

      .mat-form-field-label-wrapper {
        label.mat-form-field-label {
          mat-label,
          .mat-placeholder-required.mat-form-field-required-marker {
            color: v.$interaction-active !important;
          }
        }
      }
      .mat-form-field-flex {
        border: 2px solid v.$secondary-highlight-blue !important;
        padding: 0 0.75rem 0 3.75rem !important;
      }
      &.mat-primary .mat-select-arrow {
        color: v.$secondary-highlight-blue !important;
      }
    }

    .mat-form-field-label {
      transform: translateY(-1.5rem) scale(0.74);
      width: 140%;

      mat-label,
      .mat-placeholder-required.mat-form-field-required-marker {
        color: v.$brand-mid-grey !important;
      }
    }
    .mat-form-field-flex {
      background: v.$white !important;
    }
  }

  .mat-form-field-flex {
    background: v.$brand-pale-grey !important;
    border-radius: v.$small-border-radius !important;
    border: 1px solid v.$brand-bright-grey;
    padding: 1px 0.75rem 1px 3.75rem !important;
  }

  .mat-form-field-underline {
    display: none;
  }

  &.mat-focused {
    .mat-form-field-underline {
      &:before,
      .mat-form-field-ripple {
        height: 2px;
        background-color: v.$secondary-highlight-blue !important;
      }
    }

    .mat-form-field-label-wrapper {
      label.mat-form-field-label {
        mat-label,
        .mat-placeholder-required.mat-form-field-required-marker {
          color: v.$secondary-highlight-blue !important;
        }
      }
    }
    &.mat-primary .mat-select-arrow {
      color: v.$secondary-highlight-blue !important;
    }
  }

  .mat-form-field-wrapper {
    .mat-form-field-flex {
      padding: 0;
      background: v.$special-pale-blue;

      .mat-form-field-infix {
        width: auto;
        min-height: 4rem;
        border-top: 0.25rem solid transparent;
        padding: 1.9rem 2rem 1.6rem 0;

        input.mat-input-element {
          font-size: v.$font-size-xs;
          line-height: 1.33rem;
          color: v.$brand-standard-blue;
          text-overflow: ellipsis;
          overflow-x: hidden;
        }

        .mat-form-field-label-wrapper {
          top: -0.15rem;
          padding-top: 0;

          label.mat-form-field-label {
            top: 1.87rem;
            margin-top: 0;
            overflow: visible;

            mat-label {
              font-size: v.$font-size-xs;
              line-height: 1.33rem;
              // color: v.$secondary-turquoise-blue;
              color: v.$brand-mid-grey;
            }
          }
        }
      }
      .mat-form-field-suffix {
        color: v.$secondary-turquoise-blue;
        font-size: v.$font-size-xs;
        line-height: 1.33rem;
        align-self: center;
        padding: 0.25rem 0 0 0.5rem;
        display: flex;
        align-items: center;
      }
    }

    .mat-form-field-underline {
      &:before,
      .mat-form-field-ripple {
        background-color: rgba(v.$secondary-turquoise-blue, 0.5);
      }
    }
  }
}
// =========================================================================================================================================

/* AUTO COMPLETE FORM FIELD */
.mat-autocomplete-panel.mat-autocomplete-visible.specialAutoComplete {
  top: 0;
  max-height: 45vh;

  .mat-option {
    // color: v.$interaction-active;
    color: v.$brand-mid-grey;
    height: auto;
    line-height: 1.25rem;
    font-size: 1.125rem;
    padding: 0.5rem 0.75rem;
    border-bottom: 1px solid rgba(v.$brand-mid-grey, 0.3);

    .additionalInfo {
      display: block;
      font-size: 0.75rem;
    }
  }
}

/* CHECKBOXES */
.doehlerCheckbox {
  &.movedToTop {
    .mat-checkbox-layout {
      margin-top: -0.75rem;
    }
  }

  .mat-ripple-element {
    background-color: rgba(v.$interaction-hover, 0.25);
  }

  &:hover,
  &.hoverable {
    .mat-checkbox-frame {
      border-color: v.$interaction-secondary-default;
      background-color: v.$white;
    }

    .mat-checkbox-layout .mat-checkbox-label {
      color: v.$interaction-secondary-default;
    }
  }

  &.mat-checkbox-disabled {
    cursor: not-allowed;

    .mat-checkbox-frame {
      border-color: v.$brand-bright-grey !important;
      background-color: v.$white;
    }

    &:hover,
    &.hoverable {
      .mat-checkbox-frame {
        border-color: v.$brand-bright-grey;
        background-color: v.$white;
      }
    }

    .mat-checkbox-frame {
      border-color: v.$brand-bright-grey;
      background-color: v.$white;
    }

    .mat-checkbox-layout .mat-checkbox-label {
      color: v.$brand-bright-grey;
      -webkit-text-fill-color: v.$brand-bright-grey;
      opacity: 1;
    }
  }

  &.mat-checkbox-checked.mat-accent .mat-checkbox-inner-container .mat-checkbox-background {
    background-color: v.$interaction-active;
  }

  .mat-checkbox-layout {
    white-space: normal;
    align-items: center;
  }

  .mat-checkbox-inner-container {
    height: 1.75rem;
    width: 1.75rem;
    margin-right: 1rem;
    margin-top: 0;
    margin-bottom: 0;

    &:hover {
      .mat-checkbox-persistent-ripple {
        opacity: 0 !important;
      }
    }
  }

  .mat-checkbox-label-before .mat-checkbox-inner-container {
    margin-left: 1rem;
    margin-right: auto;
  }

  .mat-checkbox-frame {
    border-width: 1px;
    border-radius: v.$small-border-radius;
    border-color: v.$brand-mid-grey;
    background-color: v.$white;
  }

  .mat-checkbox-background {
    border-radius: v.$small-border-radius;
  }

  .mat-checkbox-layout .mat-checkbox-label {
    line-height: 125%;
    font-size: v.$font-size-xxs;
    color: v.$brand-mid-grey;
  }
}

/* CHECKBOX GROUP */
.checkbox-group-container {
  display: flex;
  flex-direction: row;

  .doehlerCheckbox {
    margin: 0.5rem 2rem 0 0;

    &.visionBoard {
      margin: 0;
      width: 100%;

      .mat-checkbox-layout {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;

        .mat-checkbox-label {
          font-family: v.$bold-font;
          color: v.$brand-mid-grey;
          margin-right: 1.5rem;
        }

        .mat-checkbox-inner-container {
          margin-right: 0;
        }
      }
    }
  }

  &.vertical {
    flex-direction: column;

    .doehlerCheckbox {
      margin: 0.5rem 0;
      padding: 0;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.semiCompact {
      .doehlerCheckbox {
        margin: 0.5rem 0;
        padding: 0;
      }
    }
  }
}

/* RADIO BUTTONS */
.doehlerRadioButtonGroup {
  display: block;

  .withYSpacing {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .mat-radio-button:hover,
  .mat-radio-button.hoverable {
    .mat-radio-outer-circle {
      border-color: v.$interaction-hover;
    }

    .mat-radio-label-content {
      color: v.$interaction-hover;
    }
  }

  .cdk-focused .mat-radio-ripple {
    opacity: 0 !important;
  }

  .mat-radio-button {
    display: inline-block;
    margin: 0.5rem 2rem 0.5rem 0;
  }

  &.vertical {
    margin-top: 0;

    .mat-radio-button {
      margin: 1rem 0;
      padding-right: 0;
      display: block;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .mat-radio-disabled {
    .mat-radio-label {
      cursor: not-allowed;
    }

    .mat-radio-outer-circle {
      border-color: v.$brand-bright-grey !important;
    }

    &:hover,
    &.hoverable {
      .mat-radio-outer-circle {
        border-color: v.$brand-bright-grey;
      }

      .mat-radio-label-content {
        color: v.$brand-bright-grey !important;
        -webkit-text-fill-color: v.$brand-bright-grey;
        opacity: 1;
      }
    }

    .mat-radio-outer-circle {
      border-color: v.$brand-bright-grey;
    }

    .mat-radio-label-content {
      color: v.$brand-bright-grey !important;
      -webkit-text-fill-color: v.$brand-bright-grey;
      opacity: 1;
    }
  }

  .mat-radio-container,
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    width: 1.875rem;
    height: 1.875rem;
  }

  .mat-radio-container,
  .mat-radio-outer-circle {
    border-width: 1px;
  }

  .mat-radio-outer-circle {
    background: v.$white;
  }

  .mat-radio-inner-circle {
    transform: scale(0);
    background: v.$white;
  }

  .mat-radio-checked .mat-radio-inner-circle {
    transform: scale(0.66);
  }

  .mat-radio-button .mat-radio-ripple {
    left: calc(50% - 21px);
    top: calc(50% - 21px);
    height: 42px;
    width: 42px;

    .mat-ripple-element {
      background-color: rgba(v.$interaction-hover, 0.2);
    }
  }

  .mat-radio-label-content {
    padding-left: 1rem;
    font-size: 1rem;
    line-height: 110%;
    color: v.$brand-mid-grey;
  }

  .mat-radio-container:hover .mat-radio-persistent-ripple {
    opacity: 0 !important;
  }
}

/* SLIDER TOGGLE */
.doehlerToggle {
  &.mat-slide-toggle.mat-checked {
    .mat-slide-toggle-thumb {
      background-color: v.$white;
    }

    .mat-slide-toggle-bar {
      background-color: v.$secondary-highlight-blue;
    }

    .mat-slide-toggle-thumb-container {
      transform: translate3d(26px, 0, 0);
    }

    .mat-slide-toggle-content {
      color: v.$interaction-active;
    }
  }

  .mat-slide-toggle-content {
    line-height: 2rem;
    font-size: v.$font-size-xxs;
    color: v.$brand-mid-grey;
  }

  .mat-slide-toggle-bar {
    margin-right: 1rem;
    height: 2rem;
    width: 3.75rem;
    border-radius: 1rem;
    background-color: v.$brand-bright-grey;
  }

  .mat-slide-toggle-thumb-container {
    width: 1.25rem;
    height: 1.25rem;
    top: 6px;
    left: 6px;

    .mat-slide-toggle-thumb {
      width: 1.25rem;
      height: 1.25rem;
      box-shadow: none;
    }
  }

  &.mat-disabled {
    opacity: 1 !important;

    .mat-slide-toggle-bar {
      opacity: 0.37;
    }

    .mat-slide-toggle-content {
      color: v.$brand-bright-grey;
      -webkit-text-fill-color: v.$brand-bright-grey;
      opacity: 1;
    }

    .mat-slide-toggle-label {
      cursor: not-allowed !important;
    }
  }
}

// DATE PICKER / DATE RANGE PICKER
.defaultFormField {
  .mat-form-field-subscript-wrapper {
    margin: 0;
    // margin-top: 0.3em;
    padding: 0.25rem !important;
  }

  .mat-date-range-input .mat-date-range-input-container {
    align-items: unset;
    padding-right: 2rem;

    .mat-date-range-input-start-wrapper {
      flex: 1;
      min-width: calc(50% - 15px);
    }
    .mat-date-range-input-separator {
      flex: 1;
      min-width: 30px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .mat-date-range-input-end-wrapper {
      flex: 1;
      min-width: calc(50% - 15px);
      text-align: end;
    }
  }

  &.mat-form-field-disabled {
    .mat-date-range-input-inner,
    .mat-button-disabled {
      cursor: not-allowed;
    }
  }
}
.mat-datepicker-content-touch .mat-datepicker-content-container.mat-datepicker-content-container-with-actions {
  max-width: 565px;
  max-height: 690px;
}

.mat-datepicker-content .mat-datepicker-content-container {
  min-width: 320px !important;
  @include mq.tablet {
    min-width: 360px !important;
  }

  .mat-calendar {
    width: 100%;
    height: auto;
    .mat-calendar-content button.mat-calendar-body-cell {
      padding: 0;
      font-size: v.$font-size-xxs2;

      &:hover {
        background: inherit;
      }

      .mat-calendar-body-today {
        border: none;
        background: transparent;
        color: v.$interaction-active;
      }
      .mat-calendar-body-selected {
        border-color: v.$interaction-active;
        background: v.$interaction-active;
        color: v.$white;
      }
      &.mat-calendar-body-in-range {
        &:before {
          background: rgba(v.$interaction-hover, 0.08);
        }
      }
      &:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        border-color: v.$interaction-default;
        background: transparent;
      }
    }
  }

  .mat-datepicker-actions {
    padding: 0 1rem;
    background: v.$white;
    border-radius: 0 0 v.$small-border-radius v.$small-border-radius;

    @include mq.tablet {
      padding-top: 0.5rem;
      padding-bottom: 1.25rem;
    }
  }
}

.mat-select-panel {
  box-shadow: 0px 20px 75px rgba(0, 0, 0, 0.1) !important;
  max-height: 275px !important;
  @include mq.smallMobile {
    max-height: 330px !important;
    @include mq.tablet {
      max-height: 420px !important;
    }
  }
  .mat-option {
    color: v.$brand-standard-blue !important;

    &:hover:not(.mat-option-disabled),
    &.mat-selected:not(.mat-option-multiple),
    &.mat-active {
      background: v.$special-pale-blue !important;
    }

    .mat-option-ripple {
      opacity: 0.1 !important;
    }
  }
}

.mat-error {
  color: v.$signal-magenta;
  line-height: 1.2;
}

.filter-chip-list-wrapper,
.mat-chip-list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: -4px;
}

/* Filter Chips, Pills, etc. */
.mat-chip-list,
.filter-chip-list,
* {
  .filterChip,
  .mat-chip-list-wrapper > mat-chip.mat-chip,
  .mat-chip-list-wrapper > mat-chip.mat-standard-chip {
    position: relative;
    white-space: nowrap;
    background-color: transparent;
    border-radius: v.$small-border-radius;
    margin: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-family: v.$bold-font;
    font-size: v.$font-size-xxs3;
    line-height: v.$line-height-headlines;
    color: v.$brand-dark-grey;
    text-align: center;
    border: 2px solid v.$brand-light-grey;
    transition: all 250ms ease-in-out;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 275px;

    .mat-chip-ripple {
      display: none;
    }

    &:focus {
      &:after {
        opacity: 0;
      }
    }

    &.no-hover {
      pointer-events: none;
    }

    &:hover,
    &.hovered {
      cursor: pointer;
      background-color: v.$white;
      border-color: v.$interaction-hover;
      color: v.$interaction-hover;
      &:after {
        opacity: 0;
      }
    }

    &.active,
    &.selected,
    &.active.filter,
    &.selected.filter {
      color: v.$brand-dark-grey;
      border-color: v.$brand-light-grey;
      background-color: v.$brand-light-grey;

      &.mat-chip-disabled,
      &.mat-chip-disabled:hover,
      &.mat-chip-disabled.hovered {
        cursor: not-allowed;
        opacity: 1 !important;
        color: v.$brand-bright-grey;
        border-color: v.$brand-pale-grey;
        background-color: v.$brand-pale-grey;
      }

      &:hover,
      &.hovered {
        cursor: pointer;
        color: v.$brand-dark-grey;
        background-color: v.$brand-bright-grey;
        border-color: v.$brand-bright-grey;
      }
    }

    &.filter {
      border-color: v.$brand-bright-grey;
      background-color: v.$brand-bright-grey;

      &:hover,
      &.hovered {
        cursor: pointer;
        color: v.$brand-dark-grey;
        background-color: v.$brand-pale-grey;
        border-color: v.$brand-pale-grey;
      }
    }

    &.mat-chip-disabled,
    &.mat-chip-disabled:hover,
    &.mat-chip-disabled.hovered {
      cursor: not-allowed;
      opacity: 1 !important;
      color: v.$brand-bright-grey;
      border-color: v.$brand-light-grey;
    }
  }

  &.withIcon {
    .filterChip,
    .mat-chip-list-wrapper > mat-chip.mat-chip {
      padding: 0.375rem 2rem 0.375rem 0.75rem;

      &:after {
        content: '';
        position: absolute;
        top: 0 !important;
        left: unset !important;
        right: 0.5rem !important;
        bottom: unset !important;
        width: 1rem;
        height: 100%;
        @include m.colored-bg-svg($color: v.$brand-dark-grey, $icon: 'basic/add_plus_single.svg');
        opacity: 1 !important;
        transition: all 250ms ease-in-out;
      }

      &.active,
      &.selected,
      &.active.filter,
      &.selected.filter {
        &.mat-chip-disabled,
        &.mat-chip-disabled:hover,
        &.mat-chip-disabled.hovered {
          cursor: not-allowed;
          opacity: 1 !important;
          color: v.$brand-bright-grey;
          border-color: v.$brand-pale-grey;
          background-color: v.$brand-pale-grey;

          &:after {
            opacity: 1;
            background-color: v.$brand-bright-grey;
          }
        }

        &:after {
          content: '';
          position: absolute;
          top: 0 !important;
          left: unset !important;
          right: 0.5rem !important;
          bottom: unset !important;
          width: 1rem;
          height: 100%;
          @include m.colored-bg-svg($color: v.$brand-dark-grey, $icon: 'basic/close_single.svg');
          opacity: 1 !important;
          transition: all 250ms ease-in-out;
        }
      }

      &:hover,
      &.hovered {
        padding: 0.375rem 2rem 0.375rem 0.75rem;
        &:after {
          opacity: 1;
          background-color: v.$interaction-hover;
        }
        &.active,
        &.selected,
        &.active.filter,
        &.selected.filter {
          &:after {
            opacity: 1;
            background-color: v.$brand-dark-grey !important;
          }
        }
        &.filter {
          &:after {
            opacity: 1;
            background-color: v.$brand-dark-grey;
          }
        }
      }

      &.mat-chip-disabled,
      &.mat-chip-disabled:hover,
      &.mat-chip-disabled.hovered {
        cursor: not-allowed;
        opacity: 1 !important;
        color: v.$brand-bright-grey;
        border-color: v.$brand-light-grey;

        &:after {
          opacity: 1;
          background-color: v.$brand-bright-grey !important;
        }
      }
    }
  }

  &.vertical {
    .filterChip,
    .mat-chip-list-wrapper > mat-chip.mat-chip,
    .mat-chip-list-wrapper > mat-chip.mat-standard-chip {
      div.icon {
        float: left;
        width: 1rem;
        height: 1rem;
        margin-right: 0.3rem;
        transition: all 250ms ease-in-out;
        background-color: v.$brand-black;
        mask-size: contain;
        -webkit-mask-size: contain;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-position: center;
        -webkit-mask-position: center;

        &.basic {
          -webkit-mask-image: url('/shared-assets/icons/basic/lock_closed.svg');
          mask-image: url('/shared-assets/icons/basic/lock_closed.svg');
        }
        &.quotes {
          -webkit-mask-image: url('/shared-assets/icons/new/offers.svg');
          mask-image: url('/shared-assets/icons/new/offers.svg');
        }
        &.contracts {
          -webkit-mask-image: url('/shared-assets/icons/new/contracts.svg');
          mask-image: url('/shared-assets/icons/new/contracts.svg');
        }
        &.orders {
          -webkit-mask-image: url('/shared-assets/icons/new/orders.svg');
          mask-image: url('/shared-assets/icons/new/orders.svg');
        }
        &.deliveries {
          -webkit-mask-image: url('/shared-assets/icons/new/deliveries.svg');
          mask-image: url('/shared-assets/icons/new/deliveries.svg');
        }
        &.invoices {
          -webkit-mask-image: url('/shared-assets/icons/new/invoices.svg');
          mask-image: url('/shared-assets/icons/new/invoices.svg');
        }
        &.complaints {
          -webkit-mask-image: url('/shared-assets/icons/basic/speech_bubble.svg.svg');
          mask-image: url('/shared-assets/icons/basic/speech_bubble.svg');
        }
        &.empties {
          -webkit-mask-image: url('/shared-assets/icons/new/empties.svg');
          mask-image: url('/shared-assets/icons/new/empties.svg');
        }
      }

      &:hover,
      &.hovered {
        div.icon {
          background-color: v.$interaction-hover;
        }
      }

      &.active,
      &.selected,
      &.active.filter,
      &.selected.filter {
        &:hover,
        &.hovered {
          div.icon {
            background-color: v.$brand-black;
          }
        }
      }

      /* margin: 0.5rem 0.25rem; */
    }
  }
}
